function sendEvent(event_name, data) {
  if (typeof TDAPP == 'undefined') {
    return
  }
  TDAPP.onEvent(event_name, '', data)
}

function placeOrder(orderId, amount, currencyType) {
  if (typeof TDAPP == 'undefined') {
    return
  }
  const obj = {
    orderId: orderId,
    amount: amount,
    currencyType: currencyType
  }
  TDAPP.onPlaceOrder(obj)
}

const TalkingDataUtils = {
  sendEvent: sendEvent,
  placeOrder: placeOrder
}

export default TalkingDataUtils
