import { createAction } from '@reduxjs/toolkit'

export const updateArecCount = createAction<number>('arec/updateArecCount')
export const updateTxHash = createAction<string | undefined>('arec/updateTxHash')
export const updateQuickArecId = createAction<{ chainId: number; owner: string; quickArecId: number | undefined }>(
  'arec/updateQuickArecId'
)
export const updateLastQuickTime = createAction<{ chainId: number; owner: string; lastQuickTime: string | undefined }>(
  'arec/updateLastQuickTime'
)
export const updateMintARECHash = createAction<{ chainId: number; owner: string; hashTx: string | undefined }>(
  'arec/updateMintARECHash'
)
export const updateQuickApproval = createAction<{ chainId: number; owner: string; approval: string | undefined }>(
  'arec/updateQuickApproval'
)
export const updateARECInfo = createAction<{ chainId: number; owner: string; infoARECNFT: string | undefined }>(
  'arec/updateARECInfo'
)
export const updateARECID = createAction<{ chainId: number; owner: string; cidString: string | undefined }>(
  'arec/updateARECID'
)
