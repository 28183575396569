import React, { useContext, useState } from 'react'
import { Fraction, JSBI } from '@arec/sdk'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { darken } from 'polished'
import { RowBetween, RowFixed } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { Text } from 'rebass'
import { ArrowRight, MinusCircle, PlusCircle, AlertCircle } from 'react-feather'
import { AutoColumn, ColumnCenter } from '../Column'
import { Link as RouterLink } from 'react-router-dom'

import { InputPanel, Container } from '../CurrencyInputPanel'
import QuestionHelper from '../QuestionHelper'
import { SeparatorBlack } from '../SearchModal/styleds'
import AddressInputPanel, { MessageInputPanel } from '../AddressInputPanel'
import { ResizingTextArea } from '../TextInput'
import { ZERO_ADDRESS } from '../../constants'
import { ButtonEmpty } from '../Button'
import { DateTime } from 'luxon'
import { RECData, REC_STATUS } from '../../state/issuance/hooks'
//import { useCurrency } from '../../hooks/Tokens'
//import { tryParseAmount } from '../../state/swap/hooks'
import { shortenAddress, shortenCID, shortenContent } from '../../utils'
//import { arkreenTokenAddress } from '../../hooks/useContract'
import { useMedia } from 'react-use'
import { BigNumber } from 'ethers'
import { useFontSize } from 'hooks/useWindowSize'
import CopyTool from 'components/AccountDetails/Copy'
import { MouseoverTooltip, MouseoverTooltipNew } from 'components/Tooltip'
import { assetARECListAtom } from 'state/arec/updater'
import { useAtomValue } from 'jotai'

export const ARECDate = styled.input.attrs({
  type: 'date'
})<{ fontSize: number }>`
  border: solid 1px;
  border-color: ${({ theme }) => theme.primaryText1};
  border-radius: 6px;
  font-size:  ${({ fontSize }) => fontSize.toString() + 'px'};
  padding: 0.2rem 0.8rem 0.2rem 0.8rem; 
  margin: 0.5rem 0.4rem 0.5rem 0.4rem;  
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  &:disabled {
    border-color: ${({ theme }) => theme.bg4};
  }  
  :hover:not([disabled]) {
      border: solid 2px;
      border-color: ${({ theme }) => theme.primaryText1};
    }
  }
`

interface DateInputInterface {
  onChangeDate?: (date: string) => void
  min?: string
  max?: string
  value?: string
  disabled?: boolean
}

export function DateInput({ onChangeDate, min, max, value, disabled = false }: DateInputInterface) {
  const { font20 } = useFontSize()
  return (
    <ARECDate
      disabled={disabled}
      min={min}
      max={max}
      value={value}
      fontSize={font20}
      onChange={event => onChangeDate && onChangeDate(event.target.value)}
    />
  )
}

export const M = styled.b`
  color: ${({ theme }) => theme.primary};
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const DateRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  justify-content: space-between;
`

export const StyledPageHeader = styled.div`
  padding: 8px 1rem 10px 1rem;
  margin-bottom: 0px;
  width: 100%;
  height: 50px;
  font-size: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.text2};
`

export const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  column-gap: 0rem;
  align-items: start-first;
  justify-content: space-between;
`

export const HeadRouter = styled.div<{ fontSize: number }>`
    position: relative;
    max-width: 480px;
    width: 100%;
    height:  ${({ fontSize }) => (2 * fontSize).toString() + 'px'};
    align-content: center;
    padding 5px 12px;
    margin-bottom: 20px;
    font-size:  ${({ fontSize }) => (1.2 * fontSize).toString() + 'px'};
    background-color: transparent;
    user-select: none;
  `

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 480px;
  width: 100%;
  background-color: transparent;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  padding: 0rem;
`

export const HeadRow = styled.div<{ fontSize: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  height: ${({ fontSize }) => (2 * fontSize).toString() + 'px'};
  align-items: stretch;
  justify-content: space-between;
`

export const HeadLink = styled(RouterLink)<{ active: boolean }>`
  border-radius: 20px;
  text-decoration: none;
  align-items: center;
  box-shadow: inset 0px 0px 1px 2px ${({ theme }) => theme.primary};
  background-color: ${({ theme, active }) => (active ? theme.primary : 'transparent')};
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ theme }) => theme.text4};
    background-color: ${({ theme }) => theme.primary1};
    box-shadow: 2px 2px 3px 1px rgba(0, 192, 239, 0.6);
  }
`

export const TitleOption = styled.div<{ active?: boolean }>`
  font-weight: ${({ active }) => (active ? 600 : 400)};
  display: flex;
  justify-content: center;
  color: ${({ theme, active }) => (active ? 'white' : theme.primaryText1)};
  :hover {
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

/*
<select multiple size={5} onChange = {onARECSelect} style={{ fontSize:'1.0rem', fontWeight:500, width:'100%', 
borderColor: offsetSelected ? theme.text1 :theme.primary1,
borderWidth: offsetSelected ? "1px" : "2px",
borderRadius: '0px 0px 0px 0px',
appearance: 'none',
padding: '0.2rem 0.6rem 0rem 0.6rem', 
fontFamily: 'Tahoma'}} >
*/

export const ARECSelectMulti = styled.select<{ itemSelected: boolean }>`
font-size:      1.0rem;
fontWeight:     600;
border-radius:  0px 0px 0px 0px;
width:          100%; 
color:            ${({ theme }) => theme.text1};
background-color: ${({ theme }) => theme.bg1};  
border-color:     ${({ itemSelected, theme }) => (itemSelected ? theme.text1 : theme.primary1)};
border-width:     ${({ itemSelected }) => (itemSelected ? '1px' : '2px')};
appearance:       'none',
padding:          '0.2rem 0.6rem 0rem 0.6rem';
fontFamily:       'Tahoma';
`

export const ARECSelect = styled.select<{ itemSelected: boolean; fontSize: number }>`
  font-size: ${({ fontSize }) => (fontSize <= 12 ? fontSize - 1 + 'px' : fontSize + 'px')};
  font-weight: 600;
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
  border-color: ${({ itemSelected, theme }) => (itemSelected ? theme.primary1 : theme.primary1)};
  border-width: ${({ itemSelected }) => (itemSelected ? '1px' : '2px')};
  padding: ${({ fontSize }) => (fontSize <= 12 ? '0.4rem 0.2rem 0.4rem 0.2rem' : '0.4rem 0.6rem 0.4rem 0.6rem')};
  font-family: 'Tahoma';
`
//font-family: 'Lucida Console';
//font-family: 'Inter var', sans-serif;
//Consolas

export const ARECOption = styled.option`
  :active {
    color: black;
    background-color: ${({ theme }) => theme.primary2};
  }
`

export const ButtonRow = styled.div`
  display: grid;
  width: 100%;
  height: 20px;
  grid-template-columns: 20px 1fr 20px;
  column-gap: 6px;
  align-items: start;
  justify-content: space-between;
`

export function OverviewHeadButton({ path }: { path: string }) {
  //  const theme = useContext(ThemeContext)
  const { font20 } = useFontSize()
  const isOverview = path === 'Overview'
  const isProfile = path === 'Profile'
  return (
    <HeadRouter fontSize={font20}>
      <HeadRow fontSize={font20}>
        <HeadLink to="/overview" draggable="false" active={isOverview}>
          <TitleOption style={{ paddingTop: '4px' }} active={isOverview}>
            Overview
          </TitleOption>
        </HeadLink>
        <HeadLink to="/Profile" draggable="false" active={isProfile}>
          <TitleOption style={{ paddingTop: '4px' }} active={isProfile}>
            My Profile
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter>
  )
}

export function OffsetSolidyButton({ path }: { path: string }) {
  const { font20 } = useFontSize()
  return (
    <HeadRouter fontSize={font20}>
      <HeadRow fontSize={font20}>
        <HeadLink to="/Offset" draggable="false" active={path === 'Offset'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'Offset'}>
            Offset
          </TitleOption>
        </HeadLink>
        <HeadLink to="/Solidify" draggable="false" active={path === 'Solidify'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'Solidify'}>
            Solidify
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter>
  )
}

export function RedeemHeadButton({ path }: { path: string }) {
  const { font20 } = useFontSize()
  return (
    <HeadRouter fontSize={font20}>
      <HeadRow fontSize={font20}>
        <HeadLink to="/Redeem" draggable="false" active={path === 'Redeem'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'Redeem'}>
            Redeem
          </TitleOption>
        </HeadLink>
        <HeadLink to="/Liquidize" draggable="false" active={path === 'Liquidize'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'Liquidize'}>
            Liquidize
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter>
  )
}

export function ExchangeHeadButton({ path }: { path: string }) {
  const { font20 } = useFontSize()
  return (
    <HeadRouter fontSize={font20}>
      <HeadRow fontSize={font20}>
        <HeadLink to="/swap" draggable="false" active={path === 'swap'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'swap'}>
            Swap
          </TitleOption>
        </HeadLink>
        <HeadLink to="/add" draggable="false" active={path === 'add'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'add'}>
            Add Liquidity
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter>
  )
}

export function BridgerHeadButton({ path }: { path: string }) {
  const { font20 } = useFontSize()
  return (
    <HeadRouter fontSize={font20}>
      <HeadRow fontSize={font20}>
        <HeadLink to="/bridge" draggable="false" active={path === 'bridge'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'bridge'}>
            Initiate
          </TitleOption>
        </HeadLink>
        <HeadLink to="/complete" draggable="false" active={path === 'complete'}>
          <TitleOption style={{ paddingTop: '4px' }} active={path === 'complete'}>
            Complete
          </TitleOption>
        </HeadLink>
      </HeadRow>
    </HeadRouter>
  )
}

interface ARECIssuanceDateProps {
  startDate: string
  endDate?: string
  minDate?: string
  maxDate?: string
  onChangeDate: (date: string) => void
  id: string
  children: React.ReactNode
  active: boolean
}

interface GetCertInfoProps {
  certOwner: string
  beneficiary: string
  nameBeneficiary: string
  memoCertificate: string
  setCertOwner: (owner: string) => void
  setBeneficiary: (beneficiary: string) => void
  setNameBeneficiary: (nameBeneficiary: string) => void
  setMemoCertificate: (memoCertificate: string) => void
}

export function ButtonUpOrDown({
  bShow,
  bCompact,
  setbShow
}: {
  bShow: boolean
  bCompact?: boolean
  setbShow: (bShow: boolean) => void
}) {
  return (
    <RowFixed>
      <ButtonEmpty
        style={{ padding: bCompact ? '2px 0px' : '4px' }}
        onClick={() => {
          setbShow(!bShow)
        }}
      >
        {bShow ? (
          <MinusCircle size="16px" style={{ margin: bCompact ? '0px 0px 0px 4px' : '0px 8px 0px 8px' }} />
        ) : (
          <PlusCircle size="16px" style={{ margin: bCompact ? '0px 0px 0px 4px' : '0px 8px 0px 8px' }} />
        )}
      </ButtonEmpty>
    </RowFixed>
  )
}

/*
function ButtonUpOrDownCompact({ bShow, setbShow }: { bShow: boolean; setbShow: (bShow: boolean) => void }) {
  return (
    <RowFixed>
      <ButtonEmpty
        style={{ padding: '3px 0px' }}
        onClick={() => {
          setbShow(!bShow)
        }}
      >
        {bShow ? (
          <MinusCircle size="16px" style={{ marginLeft: '4px' }} />
        ) : (
          <PlusCircle size="16px" style={{ marginLeft: '4px' }} />
        )}
      </ButtonEmpty>
    </RowFixed>
  )
}
*/

export function GetCertificateInfo({
  certOwner,
  beneficiary,
  nameBeneficiary,
  memoCertificate,
  setCertOwner,
  setBeneficiary,
  setNameBeneficiary,
  setMemoCertificate
}: GetCertInfoProps) {
  const theme = useContext(ThemeContext)

  const [showOwnerName, setShowOwnerName] = useState(false)
  const [showbeneficiary, setShowbeneficiary] = useState(false)
  const [showNameBeneficiary, setShowNameBeneficiary] = useState(false)
  const [showMemoCertificate, setShowMemoCertificate] = useState(true)
  const { font14, font16, font24 } = useFontSize()

  return (
    <Container>
      <RowBetween align="center" height="20px" style={{ padding: '1rem 1rem 0.75rem 1rem' }}>
        <RowFixed>
          <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16}>
            <strong>Climate Badge Info:</strong>
          </TYPE.Body>
          <QuestionHelper
            bkgOff={true}
            small={'m'}
            info={<>This is the additional information items that will be included in the minted climate badge.</>}
          />
        </RowFixed>
        <div />
      </RowBetween>
      <SeparatorBlack />
      <AutoColumn gap="4px" style={{ padding: '0.5rem' }}>
        <RowBetween height={font24 + 'px'}>
          <RowFixed style={{ padding: '0.3rem 0.5rem 0rem' }}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font14}>
              Badge Owner Name (Optional):
            </TYPE.Body>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the name of the owner of the climate badge that will be minted.</>}
            />
          </RowFixed>
          <ButtonUpOrDown bShow={showOwnerName} setbShow={setShowOwnerName} />
        </RowBetween>
        {showOwnerName && (
          <MessageInputPanel value={certOwner} onUserInput={setCertOwner} placeholder={`Badge Owner Name`} />
        )}

        <RowBetween height={font24 + 'px'}>
          <RowFixed style={{ padding: '0.3rem 0.5rem 0rem' }}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font14}>
              {font14 <= 12 ? `Beneficiary Account: (Optional):` : `Beneficiary Account Address: (Optional)`}
            </TYPE.Body>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the account address of the climate action beneficiary.</>}
            />
          </RowFixed>
          <ButtonUpOrDown bShow={showbeneficiary} setbShow={setShowbeneficiary} />
        </RowBetween>
        {showbeneficiary && (
          <AddressInputPanel
            id="recipient"
            simple={true}
            value={beneficiary !== ZERO_ADDRESS ? beneficiary : ''}
            onChange={setBeneficiary}
            placeholder={'Beneficiary Account Address'}
          />
        )}

        <RowBetween height={font24 + 'px'}>
          <RowFixed style={{ padding: '0.3rem 0.5rem 0rem' }}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font14}>
              Beneficiary Name (Optional):
            </TYPE.Body>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the name of the climate action beneficiary. </>}
            />
          </RowFixed>
          <ButtonUpOrDown bShow={showNameBeneficiary} setbShow={setShowNameBeneficiary} />
        </RowBetween>
        {showNameBeneficiary && (
          <MessageInputPanel
            value={nameBeneficiary}
            onUserInput={setNameBeneficiary}
            placeholder={`Climate Beneficiary Name`}
          />
        )}

        <RowBetween height={font24 + 'px'}>
          <RowFixed style={{ padding: '0.3rem 0.5rem 0rem' }}>
            <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font14}>
              Climate Memo: (Optional):
            </TYPE.Body>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the optional message that could be recorded in the climate badge. </>}
            />
          </RowFixed>
          <ButtonUpOrDown bShow={showMemoCertificate} setbShow={setShowMemoCertificate} />
        </RowBetween>
        {showMemoCertificate && (
          <ResizingTextArea
            value={memoCertificate}
            onUserInput={setMemoCertificate}
            placeholder={`Climate Badge Memo`}
            borderRadius={'6px'}
            small={true}
            fontSize="1rem"
          />
        )}
      </AutoColumn>
    </Container>
  )
}

export default function ARECIssuanceDate({
  startDate,
  endDate,
  minDate,
  maxDate,
  onChangeDate,
  id,
  children,
  active
}: ARECIssuanceDateProps) {
  const below540 = useMedia('(max-width: 540px)')
  const { font16 } = useFontSize()

  const theme = useContext(ThemeContext)
  return (
    <InputPanel id={id}>
      <Container>
        {below540 ? (
          <>
            <RowBetween style={{ alignItems: 'center', padding: '0.2rem 0.2rem 0rem 0.6rem' }}>
              <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16} width={'45%'}>
                <strong>Start AREC Date:</strong>
              </TYPE.Body>
              <DateInput value={startDate} disabled={true} />
            </RowBetween>
            <RowBetween style={{ alignItems: 'center', padding: '0rem 0.2rem 0.2rem 0.6rem' }}>
              <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16} width={'45%'}>
                <strong>End AREC Date:</strong>
              </TYPE.Body>
              <DateInput value={endDate} onChangeDate={onChangeDate} max={maxDate} min={minDate} disabled={!active} />
            </RowBetween>
          </>
        ) : (
          <>
            <LabelRow>
              <RowBetween style={{ alignItems: 'center' }}>
                <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16} width={'45%'}>
                  <strong>Start AREC Date:</strong>
                </TYPE.Body>
                <ArrowRight size={16} width={'10%'} />
                <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16} width={'45%'} textAlign={'right'}>
                  <strong>End AREC Date:</strong>
                </TYPE.Body>
              </RowBetween>
            </LabelRow>

            <DateRow style={{ padding: '0.2rem 0.2rem 0.2rem 0.2rem', height: '60px' }}>
              <DateInput value={startDate} disabled={true} />
              <div />
              <DateInput value={endDate} onChangeDate={onChangeDate} max={maxDate} min={minDate} disabled={!active} />
            </DateRow>
          </>
        )}

        <div style={{ padding: '0.2rem 0.6rem 0.2rem 0.6rem', marginBottom: '4px' }}>{children}</div>
      </Container>
    </InputPanel>
  )
}

export const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

export function getPowerString(power: BigNumber): string {
  if (power.lt(BigNumber.from(1000_000_000))) {
    return new Fraction(power.toString(), JSBI.BigInt(1000_000)).toFixed(3, { groupSeparator: ',' }).concat(' KWH')
  } else {
    return new Fraction(power.toString(), JSBI.BigInt(1000_000_000)).toFixed(3, { groupSeparator: ',' }).concat(' MWH')
  }
}

export function DetailedARECInfo({ recData }: { recData: RECData }) {
  const theme = useContext(ThemeContext)
  //  const arkreenToken = useCurrency(arkreenTokenAddress)
  const { font14, font24 } = useFontSize()
  const font24Str = font24.toString() + 'px'

  const assetARECList = useAtomValue(assetARECListAtom)

  if (!recData) return null

  const startDate = DateTime.fromSeconds(recData.startTime, { zone: 'UTC' }) ?? ''
  const endDate = DateTime.fromSeconds(recData.endTime, { zone: 'UTC' }) ?? ''

  //  const powerAmount = arkreenToken ? tryParseAmount(recData.amountREC.toString(), arkreenToken) : undefined
  //  const powerAmount = new Fraction(recData.amountREC.toString(), JSBI.BigInt(1000000))
  //  const powerAmountString = (powerAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

  const powerAmountString = getPowerString(recData.amountREC)

  const recStatus =
    recData?.status === REC_STATUS.Pending
      ? 'Pending'
      : recData?.status === REC_STATUS.Certified
      ? 'Certified'
      : recData?.status === REC_STATUS.Cancelled
      ? 'Cancelled'
      : recData?.status === REC_STATUS.Rejected
      ? 'Rejected'
      : ' '

  return (
    <ARECContainer>
      {Object.keys(assetARECList).length !== 0 && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              AREC Type:
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={
                <>
                  This is AREC asset type, which may be native Arkreen AREC, or AREC issused on the basis of redeemed
                  REC asset of certification organization.
                </>
              }
            />
          </RowFixed>
          <Text
            lineHeight={font24Str}
            fontWeight={700}
            fontSize={font14}
            color={recData?.status === REC_STATUS.Certified ? theme.text1 : theme.text1}
          >
            {recData.idAsset === 0 ? 'Arkreen AREC' : assetARECList[recData.idAsset].idAsset}
          </Text>
        </RowBetween>
      )}

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            AREC Status:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                This is the status of the selected AREC NFT, which may be <M>Pending, Certified, Rejected</M> and so on.
                Only <M>Certified</M> AREC can be redeemed or liquidized.
              </>
            }
          />
        </RowFixed>
        <Text
          lineHeight={font24Str}
          fontWeight={700}
          fontSize={font14}
          color={recData?.status === REC_STATUS.Certified ? theme.text1 : theme.primaryText1}
        >
          {recStatus}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            AREC Issuer:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the account address of the entity certifying the AREC NFT issuance.</>}
          />
        </RowFixed>
        <CopyTool toCopy={recData.issuer} fontSize={font14} right={true}>
          <MouseoverTooltip info={<>{recData.issuer}</>}>
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
              {shortenAddress(recData.issuer, 6)}
            </Text>
          </MouseoverTooltip>
        </CopyTool>
      </RowBetween>

      {recData.status === REC_STATUS.Certified && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              AREC Serial No:
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the unique serial number of the AREC NFT certified by the issuer.</>}
            />
          </RowFixed>
          <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
            {recData.serialNumber}
          </Text>
        </RowBetween>
      )}

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `Earliest AREC Date:` : `REC Starting Date:`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the earliest date when the renewable energy of the selected AREC is generated.`
                  : `This is the starting date of the period of the REC asset redeemed to bridge and issue AREC.`}
              </>
            }
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {startDate?.toFormat('yyyy-LLL-dd')}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `Latest AREC Date:` : `REC Ending Date:`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the last date when the renewable energy of the selected AREC is generated.`
                  : `This is the ending date of the period of the REC asset redeemed to bridge and issue AREC.`}
              </>
            }
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {endDate?.toFormat('yyyy-LLL-dd')}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Total RE Amount:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the amount of the total renewable energy recorded in the selected AREC NFT.</>}
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {powerAmountString}
        </Text>
      </RowBetween>

      {recData?.idAsset !== 0 && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              {recData?.idAsset === 0 ? `AREC Region` : `REC Region`}
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={
                <>
                  {recData?.idAsset === 0
                    ? `This is the region of the miners, of which the generated renewable energy is used to mint the selected
                  AREC NFT.`
                    : `This is the region of the redeemded REC asset of which the selected AREC NFT is minted on the basis.`}
                </>
              }
            />
          </RowFixed>

          <CopyTool toCopy={recData.region} fontSize={font14} right={true}>
            <MouseoverTooltip info={<>{recData.region}</>}>
              <Text fontWeight={700} fontSize={font14} color={theme.text1} alignItems={'center'}>
                {shortenContent(recData.region, 20)}
              </Text>
            </MouseoverTooltip>
          </CopyTool>
        </RowBetween>
      )}
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `AREC cID` : `REC cID`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the cID of the renewable energy data in ipfs, with which the selected
                AREC RE amount can be verified.`
                  : `This is the cID of the REC redemption proof file in ipfs, with which
                the selected AREC RE amount can be verified.`}
              </>
            }
          />
        </RowFixed>
        <CopyTool toCopy={recData.cID} fontSize={font14} right={true}>
          <Text fontWeight={700} fontSize={font14} color={theme.text1}>
            {shortenCID(recData.cID)}
          </Text>
        </CopyTool>
      </RowBetween>

      {recData.url && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              REC Redemption URL:
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={
                <>
                  {recData?.idAsset === 0
                    ? `This is the URL to access the renewable energy generation data of the select AREC NFT.`
                    : `This is the URL to access the redemption proof file of the REC asset bridged and minted as AREC NFT.`}
                </>
              }
            />
          </RowFixed>
          <CopyTool toCopy={recData.url} fontSize={font14} right={true}>
            <MouseoverTooltip info={<>{recData.url}</>}>
              <Text fontWeight={700} fontSize={font14} color={theme.text1} alignItems={'center'}>
                {shortenContent(recData.url, 20)}
              </Text>
            </MouseoverTooltip>
          </CopyTool>
        </RowBetween>
      )}

      {recData.memo !== '' && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              Memo in AREC (Optional):
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the optional message that is recorded in the selected AREC NFT.</>}
            />
          </RowFixed>
          <CopyTool toCopy={recData.memo} fontSize={font14} right={true}>
            <MouseoverTooltipNew text={recData.memo}>
              <Text fontWeight={700} fontSize={font14} color={theme.text1} alignItems={'center'}>
                {shortenContent(recData.memo, 20)}
              </Text>
            </MouseoverTooltipNew>
          </CopyTool>
        </RowBetween>
      )}
    </ARECContainer>
  )
}

interface arecInfoUpdateProps {
  recData: RECData
  newStartDate: string
  newEndDate: string
  cID: string
  region: string
  url: string
  memo: string
  setNewStartDate: (newStartDate: string) => void
  setNewEndDate: (newEndDate: string) => void
  setcID: (cID: string) => void
  setRegion: (region: string) => void
  setUrl: (url: string) => void
  setMemo: (memo: string) => void
}

export function DetailedARECInfoUpdate({
  recData,
  newStartDate,
  newEndDate,
  cID,
  region,
  url,
  memo,
  setNewStartDate,
  setNewEndDate,
  setcID,
  setRegion,
  setUrl,
  setMemo
}: arecInfoUpdateProps) {
  const theme = useContext(ThemeContext)
  //  const arkreenToken = useCurrency(arkreenTokenAddress)
  const assetARECList = useAtomValue(assetARECListAtom)

  const startDate = DateTime.fromSeconds(recData.startTime, { zone: 'UTC' }) ?? ''
  const endDate = DateTime.fromSeconds(recData.endTime, { zone: 'UTC' }) ?? ''

  //  const powerAmount = arkreenToken ? tryParseAmount(recData.amountREC.toString(), arkreenToken) : undefined
  //  const powerAmount = new Fraction(recData.amountREC.toString(), JSBI.BigInt(1000000))
  //  const powerAmountString = (powerAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

  const powerAmountString = getPowerString(recData.amountREC)

  const { font14, font24 } = useFontSize()
  const font24Str = font24.toString() + 'px'
  const bChangeable = recData.idAsset !== 0 && recData?.status === REC_STATUS.Pending

  const recStatus =
    recData?.status === REC_STATUS.Pending
      ? 'Pending'
      : recData?.status === REC_STATUS.Certified
      ? 'Certified'
      : recData?.status === REC_STATUS.Cancelled
      ? 'Cancelled'
      : recData?.status === REC_STATUS.Rejected
      ? 'Rejected'
      : ' '

  const [showStartDate, setShowStartDate] = useState(false)
  const [showEndDate, setEndStartDate] = useState(false)
  const [showCID, setShowCID] = useState(false)
  const [showRegion, setShowRegion] = useState(false)
  const [showUrl, setShowUrl] = useState(false)
  const [showMemo, setShowMemo] = useState(false)

  const today = DateTime.now().toFormat('yyyy-MM-dd')

  return (
    <ARECContainer>
      {Object.keys(assetARECList).length !== 0 && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              AREC Type:
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={
                <>
                  This is AREC asset type, which may be native Arkreen AREC, or AREC issused on the basis of redeemed
                  REC asset of certification organization.
                </>
              }
            />
          </RowFixed>
          <Text
            lineHeight={font24Str}
            fontWeight={700}
            fontSize={font14}
            color={recData?.status === REC_STATUS.Certified ? theme.text1 : theme.text1}
          >
            {recData.idAsset === 0 ? 'Arkreen AREC' : assetARECList[recData.idAsset].idAsset}
          </Text>
        </RowBetween>
      )}

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            AREC Status:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                This is the status of the selected AREC NFT, which may be <M>Pending, Certified, Rejected</M> and so on.
                Only <M>Certified</M> AREC can be redeemed or liquidized.
              </>
            }
          />
        </RowFixed>
        <Text
          lineHeight={font24Str}
          fontWeight={700}
          fontSize={font14}
          color={recData?.status === REC_STATUS.Certified ? theme.text1 : theme.primaryText1}
        >
          {recStatus}
        </Text>
      </RowBetween>
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            AREC Issuer:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the account address of the entity certifying the AREC NFT issuance.</>}
          />
        </RowFixed>
        <CopyTool toCopy={recData.issuer} fontSize={font14} right={true}>
          <MouseoverTooltip info={<>{recData.issuer}</>}>
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
              {shortenAddress(recData.issuer, 6)}
            </Text>
          </MouseoverTooltip>
        </CopyTool>
      </RowBetween>
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Total RE Amount:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the amount of the total renewable energy recorded in the selected AREC NFT.</>}
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {powerAmountString}
        </Text>
      </RowBetween>
      {recData.status === REC_STATUS.Certified && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              AREC Serial No:
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the unique serial number of the AREC NFT certified by the issuer.</>}
            />
          </RowFixed>
          <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
            {recData.serialNumber}
          </Text>
        </RowBetween>
      )}
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `Earliest AREC Date:` : `REC Starting Date:`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the earliest date when the renewable energy of the selected AREC is generated.`
                  : `This is the starting date of the period of the REC asset redeemed to bridge and issue AREC.`}
              </>
            }
          />
        </RowFixed>
        <RowFixed>
          {recData.startTime !== 0 ? (
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
              {startDate?.toFormat('yyyy-LLL-dd')}
            </Text>
          ) : newStartDate ? (
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primary}>
              {newStartDate}
            </Text>
          ) : null}
          {bChangeable && (
            <ButtonUpOrDown
              bShow={showStartDate}
              bCompact={true}
              setbShow={bshow => {
                setShowStartDate(bshow)
                if (showEndDate) setEndStartDate(false)
              }}
            />
          )}
        </RowFixed>
      </RowBetween>
      {showStartDate && bChangeable && (
        <RowBetween>
          <div />
          <DateInput
            value={newStartDate}
            min={'1970-01-01'}
            max={today}
            onChangeDate={newStartDate => {
              setNewStartDate(newStartDate)
            }}
          />
        </RowBetween>
      )}
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `Latest AREC Date:` : `REC Ending Date:`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the last date when the renewable energy of the selected AREC is generated.`
                  : `This is the ending date of the period of the REC asset redeemed to bridge and issue AREC.`}
              </>
            }
          />
        </RowFixed>
        <RowFixed>
          {recData.endTime !== 0 ? (
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
              {endDate?.toFormat('yyyy-LLL-dd')}
            </Text>
          ) : newEndDate ? (
            <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primary}>
              {newEndDate}
            </Text>
          ) : null}
          {bChangeable && (
            <ButtonUpOrDown
              bShow={showEndDate}
              bCompact={true}
              setbShow={bshow => {
                setEndStartDate(bshow)
                if (showStartDate) setShowStartDate(false)
              }}
            />
          )}
        </RowFixed>
      </RowBetween>
      {showEndDate && bChangeable && (
        <RowBetween>
          <div />
          <DateInput
            value={newEndDate}
            min={'1970-01-01'}
            max={today}
            onChangeDate={newEndDate => {
              setNewEndDate(newEndDate)
            }}
          />
        </RowBetween>
      )}

      {recData?.idAsset !== 0 && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              {recData?.idAsset === 0 ? `AREC Region` : `REC Region`}
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={
                <>
                  {recData?.idAsset === 0
                    ? `This is the region of the miners, of which the generated renewable energy is used to mint the selected
                AREC NFT.`
                    : `This is the region of the redeemded REC asset of which the selected AREC NFT is minted on the basis.`}
                </>
              }
            />
          </RowFixed>
          <RowFixed>
            {region ? (
              <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primary}>
                {region}
              </Text>
            ) : recData.region !== '' ? (
              <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
                {recData.region}
              </Text>
            ) : null}
            {bChangeable && (
              <ButtonUpOrDown
                bShow={showRegion}
                bCompact={true}
                setbShow={bshow => {
                  setShowRegion(bshow)
                  if (showStartDate) setShowStartDate(false)
                  if (showEndDate) setEndStartDate(false)
                }}
              />
            )}
          </RowFixed>
        </RowBetween>
      )}

      {showRegion && bChangeable && (
        <MessageInputPanel
          value={region}
          onUserInput={setRegion}
          placeholder={`Region of the REC to issue`}
          margin={'0.25rem 0rem'}
        />
      )}
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {recData?.idAsset === 0 ? `AREC cID` : `REC cID`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the cID of the renewable energy data in ipfs, with which the selected
                AREC RE amount can be verified.`
                  : `This is the cID of the REC redemption proof file in ipfs, with which
                the selected AREC RE amount can be verified.`}
              </>
            }
          />
        </RowFixed>
        <RowFixed>
          {cID ? (
            <CopyTool toCopy={cID} fontSize={font14} right={true}>
              <MouseoverTooltip info={<>{cID}</>}>
                <Text fontWeight={700} fontSize={font14} color={theme.primary}>
                  {shortenCID(cID)}
                </Text>
              </MouseoverTooltip>
            </CopyTool>
          ) : recData.cID !== '' ? (
            <CopyTool toCopy={recData.cID} fontSize={font14} right={true}>
              <MouseoverTooltip info={<>{recData.cID}</>}>
                <Text fontWeight={700} fontSize={font14} color={theme.text1}>
                  {shortenCID(recData.cID)}
                </Text>
              </MouseoverTooltip>
            </CopyTool>
          ) : null}
          {bChangeable && (
            <ButtonUpOrDown
              bShow={showCID}
              bCompact={true}
              setbShow={bshow => {
                setShowCID(bshow)
                if (showStartDate) setShowStartDate(false)
                if (showEndDate) setEndStartDate(false)
              }}
            />
          )}
        </RowFixed>
      </RowBetween>
      {showCID && bChangeable && (
        <ResizingTextArea
          value={cID}
          onUserInput={setcID}
          placeholder={`CID of the redemption file in Filcoin`}
          borderRadius={'6px'}
          small={true}
          fontSize="1rem"
          margin={'0.25rem 0rem'}
        />
      )}
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            REC Redemption URL:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                {recData?.idAsset === 0
                  ? `This is the URL to access the renewable energy generation data of the select AREC NFT.`
                  : `This is the URL to access the redemption proof file of the REC asset bridged and minted as AREC NFT.`}
              </>
            }
          />
        </RowFixed>
        <RowFixed>
          {url ? (
            <CopyTool toCopy={url} fontSize={font14} right={true}>
              <MouseoverTooltip info={<>{url}</>}>
                <Text fontWeight={700} fontSize={font14} color={theme.primary} alignItems={'center'}>
                  {shortenContent(url, 20)}
                </Text>
              </MouseoverTooltip>
            </CopyTool>
          ) : recData.url !== '' ? (
            <CopyTool toCopy={recData.url} fontSize={font14} right={true}>
              <MouseoverTooltip info={<>{recData.url}</>}>
                <Text fontWeight={700} fontSize={font14} color={theme.text1} alignItems={'center'}>
                  {shortenContent(recData.url, 20)}
                </Text>
              </MouseoverTooltip>
            </CopyTool>
          ) : null}
          {bChangeable && (
            <ButtonUpOrDown
              bShow={showUrl}
              bCompact={true}
              setbShow={bshow => {
                setShowUrl(bshow)
                if (showStartDate) setShowStartDate(false)
                if (showEndDate) setEndStartDate(false)
              }}
            />
          )}
        </RowFixed>
      </RowBetween>
      {showUrl && bChangeable && (
        <ResizingTextArea
          value={url}
          onUserInput={setUrl}
          placeholder={`URL of the REC redemption document`}
          borderRadius={'6px'}
          small={true}
          fontSize="1rem"
          margin={'0.25rem 0rem'}
        />
      )}

      {(bChangeable || recData.memo !== '') && (
        <RowBetween align="center" height={font24Str}>
          <RowFixed>
            <Text fontWeight={500} fontSize={font14} color={theme.text2}>
              Memo in AREC (Optional):
            </Text>
            <QuestionHelper
              bkgOff={true}
              small={'s'}
              info={<>This is the optional message that is recorded in the selected AREC NFT. </>}
            />
          </RowFixed>
          <RowFixed>
            {memo ? (
              <CopyTool toCopy={memo} fontSize={font14} right={true}>
                <MouseoverTooltipNew text={memo}>
                  <Text fontWeight={700} fontSize={font14} color={theme.primary} alignItems={'center'}>
                    {shortenContent(memo, 20)}
                  </Text>
                </MouseoverTooltipNew>
              </CopyTool>
            ) : recData.memo !== '' ? (
              <CopyTool toCopy={recData.memo} fontSize={font14} right={true}>
                <MouseoverTooltipNew text={recData.memo}>
                  <Text fontWeight={700} fontSize={font14} color={theme.text1} alignItems={'center'}>
                    {shortenContent(recData.memo, 20)}
                  </Text>
                </MouseoverTooltipNew>
              </CopyTool>
            ) : null}
            {bChangeable && (
              <ButtonUpOrDown
                bShow={showMemo}
                bCompact={true}
                setbShow={bshow => {
                  setShowMemo(bshow)
                  if (showStartDate) setShowStartDate(false)
                  if (showEndDate) setEndStartDate(false)
                }}
              />
            )}
          </RowFixed>
        </RowBetween>
      )}
      {showMemo && bChangeable && (
        <ResizingTextArea
          value={memo}
          onUserInput={setMemo}
          placeholder={`AREC NFT Memo`}
          borderRadius={'6px'}
          small={false}
          fontSize="1rem"
          margin={'0.25rem 0rem'}
        />
      )}
    </ARECContainer>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`

export const ErrorPromptModal = ({
  isOpen,
  errString,
  onDismiss
}: {
  isOpen: boolean
  errString: string | undefined
  onDismiss: () => void
}) => {
  const theme = useContext(ThemeContext)
  const { font14, font20 } = useFontSize()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={120}>
      <Wrapper>
        <Section>
          <RowBetween>
            <Text fontWeight={500} fontSize={font20}>
              Transaction Failed
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <ConfirmedIcon>
            <AlertCircle strokeWidth={1.0} size={'90px'} fontWeight={'900'} color={theme.primaryText1} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={font20}>
              Transaction Failure Message:
            </Text>
            <AutoColumn gap="12px" justify={'center'}>
              <Text fontWeight={600} fontSize={font14} color="" textAlign="center">
                {errString}
              </Text>
            </AutoColumn>

            <Text fontSize={12} color="#565A69" textAlign="center">
              Please check your operation, and connect with <b>Arkreen</b>
            </Text>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}
