import { createAction } from '@reduxjs/toolkit'
import { ConnectionType } from 'connection'

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}

export interface SerializedNFTPair extends SerializedPair {
  bidStatus: boolean
}

export const updateMatchesDarkMode = createAction<{ matchesDarkMode: boolean }>('user/updateMatchesDarkMode')
export const updateUserDarkMode = createAction<{ userDarkMode: boolean }>('user/updateUserDarkMode')
export const updateUserExpertMode = createAction<{ userExpertMode: boolean }>('user/updateUserExpertMode')
export const updateUserSingleHopOnly = createAction<{ userSingleHopOnly: boolean }>('user/updateUserSingleHopOnly')
export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: number }>(
  'user/updateUserSlippageTolerance'
)
export const updateUserDeadline = createAction<{ userDeadline: number }>('user/updateUserDeadline')
export const addSerializedToken = createAction<{ serializedToken: SerializedToken }>('user/addSerializedToken')
export const removeSerializedToken = createAction<{ chainId: number; address: string }>('user/removeSerializedToken')
export const addSerializedPair = createAction<{ serializedPair: SerializedPair }>('user/addSerializedPair')
export const removeSerializedPair = createAction<{ chainId: number; tokenAAddress: string; tokenBAddress: string }>(
  'user/removeSerializedPair'
)
export const addSerializedNFTPair = createAction<{ serializedNFTPair: SerializedNFTPair }>('user/addSerializedNFTPair')
export const removeSerializedNFTPair = createAction<{ chainId: number; tokenAAddress: string; tokenBAddress: string }>(
  'user/removeSerializedNFTPair'
)
export const toggleURLWarning = createAction<void>('app/toggleURLWarning')
export const setARECConfirmCounter = createAction<{ ConfirmCounter: number }>('user/setARECConfirmCounter')
export const updateTestMode = createAction<{ testMode: string }>('user/updateTestMode')
export const updateWalletNetwork = createAction<{ walletNetwork: string }>('user/updateWalletNetwork')
export const updateSelectedWallet = createAction<{ wallet: ConnectionType | undefined }>('user/updateSelectedWallet')
export const setMultiCallErrCounter = createAction<{ multiCallErrCounter: number }>('user/setMultiCallErrCounter')
export const setBridgeFeatureOn = createAction<{ bridgeFeature: string }>('user/setBridgeFeatureOn')
