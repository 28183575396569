import React from 'react'
import { Link as RebassLink, LinkProps } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten, darken } from 'polished'

// LinkProps

export interface WrappedLinkProps extends LinkProps {
  external?: string
  children: React.ReactNode
  color?: string
}

const WrappedLink = ({ external, children, color, ...rest }: WrappedLinkProps) => (
  <RebassLink
    target={external ?? '_blank'}
    rel={external ?? 'noopener noreferrer'}
    color={color ? color : '#2f80ed'}
    {...rest}
  >
    {children}
  </RebassLink>
)

WrappedLink.propTypes = {
  external: PropTypes.bool
}

const Link = styled(WrappedLink)`
  color: ${({ color, theme }) => (color ? color : theme.link)};
`

export default Link

export const CustomLink = styled(RouterLink)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color, theme }) => (color ? color : theme.link)};

  &:visited {
    color: ${({ color, theme }) => (color ? lighten(0.1, color) : lighten(0.1, theme.link))};
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ color, theme }) => (color ? darken(0.1, color) : darken(0.1, theme.link))};
  }
`

export const BasicLink = styled(RouterLink)`
  text-decoration: none;
  height: 2rem;
  color: ${({ theme }) => theme.text1};
  border: none;
  :active,
  :focus,
  :hover {
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    underline: none;
  }
`

export const TitleLink = styled(RouterLink)`
  border: 1px solid ${({ theme }) => theme.primary2};
  border-radius: 6px;
  text-decoration: none;
  align-content: center;
  margin-bottom: -5px;
  box-shadow: 2px 1px 4px 1px rgba(0, 192, 239, 0.6);
  &:hover {
    cursor: pointer;
    text-decoration: none;
    underline: none;
    color: ${({ theme }) => theme.text4};
    background-color: ${({ theme }) => theme.primary1};
    box-shadow: 2px 2px 3px 1px rgba(0, 192, 239, 0.6);
  }
`
