import { createReducer } from '@reduxjs/toolkit'
import { updateArecBankInfo, updateArtPaymentInfo, updateArtSaleInfo } from './actions'
import { BankInfo, ArtPaymentInfo, ARTTokenSaleInfo } from '../../pages/Port/PortInfo'

export interface PortState {
  arecbank: {
    [chainIdMode: string]: BankInfo | undefined
  }
  allArtPaymentInfo: {
    [chainIdMode: string]: {
      [arttoken: string]: ArtPaymentInfo
    }
  }
  allARTTokenSaleInfo: {
    [chainIdMode: string]: {
      [arttokenSale: string]: ARTTokenSaleInfo
    }
  }
}

const initialState: PortState = {
  arecbank: {},
  allArtPaymentInfo: {},
  allARTTokenSaleInfo: {}
}

export default createReducer<PortState>(initialState, builder =>
  builder
    .addCase(updateArecBankInfo, (state, { payload: { chainIdMode, arecbank } }) => {
      state.arecbank[chainIdMode] = arecbank
    })
    .addCase(updateArtPaymentInfo, (state, { payload: { chainIdMode, allArtPaymentInfo } }) => {
      state.allArtPaymentInfo[chainIdMode] = allArtPaymentInfo
    })
    .addCase(updateArtSaleInfo, (state, { payload: { chainIdMode, allARTTokenSaleInfo } }) => {
      state.allARTTokenSaleInfo[chainIdMode] = allARTTokenSaleInfo
    })
)
