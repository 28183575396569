import { useEffect, useState, useMemo } from 'react'

const isClient = typeof window === 'object'

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  }
}

// https://usehooks.com/useWindowSize/
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    if (isClient) {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    return undefined
  }, [])

  return windowSize
}

export function useFontSize() {
  const windowSize = useWindowSize()

  const { font12, font14, font16, font18, font20, font24 } = useMemo(() => {
    if (!windowSize.width || windowSize.width >= 600)
      return { font12: 12, font14: 14, font16: 16, font18: 18, font20: 20, font24: 24 }
    const font12 = Math.floor((12 * windowSize.width) / 424)
    const font14 = Math.floor((14 * windowSize.width) / 424)
    if (font14 >= 14) return { font12: 12, font14: 14, font16: 16, font18: 18, font20: 20, font24: 24 }
    const font16 = Math.floor((16 * windowSize.width) / 424)
    const font18 = Math.floor((18 * windowSize.width) / 424)
    const font20 = Math.floor((20 * windowSize.width) / 424)
    const font24 = Math.floor((24 * windowSize.width) / 424)
    return { font12, font14, font16, font18, font20, font24 }
  }, [windowSize])

  return { font12, font14, font16, font18, font20, font24 }
}
