import { useContext, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { ChainId, Fraction, JSBI } from '@arec/sdk'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonConfirmed } from '../../components/Button'
import Column, { AutoColumn } from '../../components/Column'
import { RowBetween, RowFixed } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import ProgressSteps from '../../components/ProgressSteps'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
import { nanoid } from '@reduxjs/toolkit'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {
  useArkreenTokenContract,
  useRECIssuanceContract,
  useAKREToken,
  useRECIssuerAddress
} from '../../hooks/useContract'
import { splitSignature, solidityPack } from 'ethers/lib/utils'
import { TokenAmount, Token } from '@arec/sdk'
import { BigNumber } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { shortenAddress, shortenCID } from '../../utils'

import { useWeb3React } from '@web3-react/core'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import ARECIssuanceDate, { ErrorPromptModal, M } from '../../components/ARecIssuance'
import { Container } from '../../components/CurrencyInputPanel'
import { DateTime } from 'luxon'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import {
  RECRequest,
  useFetchARECInfo,
  useIssueOwnerRec,
  RequestIssueOwnerRec,
  useIssuancePriceByToken,
  RecCID,
  useMinerListByOwner,
  useBuyMinerURL
} from '../../state/issuance/hooks'

import { useArkreenBalance, useETHBalances } from '../../state/wallet/hooks'
import { useGetTestMode, useUserTransactionTTL } from '../../state/user/hooks'
import { MouseoverTooltip } from '../../components/Tooltip'
import { ExternalLink } from 'theme'

import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import Loader from '../../components/Loader'
import { ExternalLink as LinkIcon, ShoppingCart } from 'react-feather'
import { useMedia } from 'react-use'
import { useArecCount } from '../../state/arec/hooks'
import { useFontSize } from 'hooks/useWindowSize'
import ReactGA from 'react-ga4'
//import useDebounce from '../../hooks/useDebounce'
import TalkingDataUtils from '../../utils/talkingdata'

export interface ProfileAREC {
  startDate: string
  endDate: string
  maxDate: string
  minerNumber: number
  amountTotalRE: string
  totalPowerOutput: string
  priceToIssueREC: string
  minREAmount: string
  cID: string
  region: string
  url: string
}

function IssuanceHelpInfo() {
  return (
    <>
      <Text>
        <M>1.</M> Connect your wallet on Polygon.
      </Text>
      <Text>
        <M>2.</M> Input the end date to issue AREC. The start date is set automatically, which is the date when you
        started mining, or the earliest date when your RE generation proof is available.
      </Text>
      <Text>
        <M>3.</M> Click <M>Approve AKRE</M> to approve to pay the issuance fee with your wallet.
      </Text>
      <Text>
        <M>4.</M> Check the indicated AREC issuance info.
      </Text>
      <Text>
        <M>5.</M> Click <M>Mint AREC</M>, check and sign your AREC issuance request.
      </Text>
      <Text>
        <M>6.</M> Waiting your AREC issuance request been confirmed by Arkreen.
      </Text>
      <Text>
        <M>Reminding:</M> Your request maybe rejected by Arkreen for some security reason. In that case you can update
        your request, or cancel your request.
      </Text>
    </>
  )
}

const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 1rem 0.3rem 1rem;
  background: transparent;

  @media screen and (max-width: 540px) {
    padding: 0.3rem 0.5rem;
  }
`

export const OrderLink = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;
  :hover {
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export function OverallAREC({ recProfile, minerNumber }: { recProfile: ProfileAREC; minerNumber: number }) {
  const theme = useContext(ThemeContext)
  const amountTotalREString = new Fraction(recProfile.amountTotalRE.toString(), JSBI.BigInt(1000000)).toFixed(3)
  const priceToIssueRECString = new Fraction(recProfile.priceToIssueREC, JSBI.BigInt(1000000000000)).toFixed(2)
  const { font14, font24 } = useFontSize()
  const font24Str = font24.toString() + 'px'

  return (
    <ARECContainer>
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Earliest AREC Date:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                This is the date your miner(s) started mining, or the earliest date your renewable energy output is
                available to mint AREC NFT.
              </>
            }
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {recProfile.startDate}
        </Text>
      </RowBetween>
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Latest AREC Date:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the last date your renewable energy output can be mint as an AREC NFT.</>}
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {recProfile.endDate}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Total Miners:
          </Text>
          <QuestionHelper bkgOff={true} small={'s'} info={<>This is the number of the miners you are holding.</>} />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {minerNumber == 100 ? `> 50` : minerNumber}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Total Available RE Amount:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the total renewable energy amount available for minting an AREC NFT.</>}
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {amountTotalREString} KWH
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Price To Issue AREC:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                You need to pay some AKRE tokens for issuing AREC. This price is used to calculate how much AKRE totally
                you should pay. This price is subject to change on Arkreen governance rule.
              </>
            }
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {priceToIssueRECString} AKRE/KWH
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Minimum RE Allowed To Mint:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>Not allowed to mint AREC NFT with renewable energy less than this amount.</>}
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text3}>
          {recProfile.minREAmount} KWH
        </Text>
      </RowBetween>
    </ARECContainer>
  )
}

//export default function Swap({ history }: RouteComponentProps) {
export default function Issuance() {
  const { account, chainId, provider } = useWeb3React()
  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  const simulationMode = useGetTestMode() === 'Simu'

  const TrialMode = useMemo(() => {
    if (simulationMode && chainId === ChainId.MATIC_TESTNET) return true
    return false
  }, [simulationMode, chainId])

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const dateNow = DateTime.now()
    .minus({ days: 2 })
    .toFormat('yyyy-MM-dd')

  const arkreenToken = useAKREToken()
  const arkreenBalance: TokenAmount | undefined = useArkreenBalance()

  const priceIssuance = useIssuancePriceByToken(arkreenToken.address)

  const recProfileInit: ProfileAREC = useMemo(() => {
    return {
      startDate: '2022-09-03',
      endDate: dateNow,
      maxDate: dateNow,
      minerNumber: 2,
      amountTotalRE: '12312000000', //12312000000
      totalPowerOutput: '12312000000',
      priceToIssueREC: priceIssuance ? priceIssuance.toString() : '100000000000',
      minREAmount: '1.00',
      cID: 'bafybeia32b37c3owhbuodg5ef4dmn6jc4g2jzamgv22teipbjcrrrpkauy',
      region: '', //'China',
      url: 'https://bafybeia32b37c3owhbuodg5ef4dmn6jc4g2jzamgv22teipbjcrrrpkauy.ipfs.w3s.link'
    }
  }, [dateNow, priceIssuance])

  const [endDate, setEndDate] = useState<string | undefined>('') // dateNow
  const [endDateMemo, setEndDateMemo] = useState<string | undefined>()

  const { errorAREC, dataAREC, setDataAREC } = useFetchARECInfo({
    simulationMode,
    owner: account ?? undefined,
    endDate: endDateMemo
  })

  const { countMiners, minerList } = useMinerListByOwner(simulationMode, account ?? undefined)

  const [simuRECProfile, setSimuRECProfile] = useState<ProfileAREC>(recProfileInit)

  const minerRECProfile = useMemo(() => {
    if (errorAREC !== '' || dataAREC === undefined) return undefined
    const tempProfile: ProfileAREC = { ...recProfileInit, minerNumber: 0 }
    if (dataAREC.length === 0) {
      return tempProfile
    }
    tempProfile.minerNumber = dataAREC.length

    let startDate = '99990101'
    let endDate = ''
    let maxDate = ''
    // eslint-disable-next-line prefer-const
    let regions: string[] = []
    let amountTotalRE: BigNumber = BigNumber.from(0)
    let totalPowerOutput: BigNumber = BigNumber.from(0)
    for (let i = 0; i < dataAREC.length; i++) {
      if (dataAREC[i].startDate < startDate) {
        startDate = dataAREC[i].startDate
      }
      if (dataAREC[i].endDate > endDate) {
        endDate = dataAREC[i].endDate
      }
      if (dataAREC[i].lastDate > maxDate) {
        maxDate = dataAREC[i].lastDate
      }

      const totalREOutput = dataAREC[i].totalREOutput.split('-')
      if (totalREOutput.length >= 2) {
        amountTotalRE = amountTotalRE.sub(BigNumber.from(totalREOutput[0] + totalREOutput[1]))
      } else {
        amountTotalRE = amountTotalRE.add(BigNumber.from(dataAREC[i].totalREOutput))
      }

      const totalPowerOutputSplit = dataAREC[i].totalPowerOutput.split('-')

      if (totalPowerOutputSplit.length >= 2) {
        totalPowerOutput = totalPowerOutput.sub(BigNumber.from(totalPowerOutputSplit[0] + totalPowerOutputSplit[1]))
      } else {
        totalPowerOutput = totalPowerOutput.add(BigNumber.from(dataAREC[i].totalPowerOutput))
      }

      if (!regions.includes(dataAREC[i].region)) {
        regions.push(dataAREC[i].region)
      }
    }
    tempProfile.startDate = DateTime.fromFormat(startDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.endDate = DateTime.fromFormat(endDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.maxDate = DateTime.fromFormat(maxDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.amountTotalRE = amountTotalRE.toString()
    tempProfile.totalPowerOutput = totalPowerOutput.toString()
    tempProfile.region = '' //regions.join(',')
    tempProfile.cID = ''
    return tempProfile
  }, [errorAREC, dataAREC, recProfileInit])

  const recProfile = TrialMode ? simuRECProfile : minerRECProfile ///////

  const ethBalance = useETHBalances(account ? [account] : [])

  const ifEmptyWallet = useMemo(() => {
    if (!ethBalance || !account || !ethBalance[account]) return false
    return ethBalance[account]?.equalTo('0') ? true : false
  }, [ethBalance, account])

  const arkreenRECIssuanceContract = useRECIssuanceContract(true)
  const { updatetxHash } = useArecCount()

  const approvalAmount: TokenAmount | undefined = useMemo(() => {
    if (!recProfile) return undefined
    const feeToPay = JSBI.multiply(JSBI.BigInt(recProfile.amountTotalRE), JSBI.BigInt(recProfile.priceToIssueREC))
    return new TokenAmount(arkreenToken as Token, feeToPay)
  }, [recProfile, arkreenToken])

  //const feePayToMint = approvalAmount.toFixed(3)-

  const [dateSelected, setDateSelected] = useState(false)
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)

  const ifMoreInput = useMemo(() => {
    if (!approvalAmount || !arkreenBalance) return false
    if (approvalAmount.greaterThan(arkreenBalance)) return true
    return false
  }, [approvalAmount, arkreenBalance])

  const amountTotalRE: BigNumber = recProfile ? BigNumber.from(recProfile.amountTotalRE) : BigNumber.from(0)
  const enableRECMint = amountTotalRE.isZero() ? false : true

  const startDate: string = enableRECMint && recProfile ? recProfile.startDate : dateNow
  const minDate: string | undefined =
    enableRECMint && recProfile
      ? DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd')
          .plus({ days: 0 })
          .toFormat('yyyy-MM-dd')
      : dateNow

  const maxDate: string | undefined = enableRECMint && recProfile ? recProfile.maxDate : dateNow

  const arkreenTokenContract = useArkreenTokenContract(true)

  const deadline = useTransactionDeadline() // custom from users settings

  const [ttl] = useUserTransactionTTL()

  const [approval] = useApproveCallback(approvalAmount, arkreenRECIssuanceContract?.address)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const [{ showConfirm, txnToConfirm, IssueErrorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    IssueErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    IssueErrorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  const MINER_BUY_URL = useBuyMinerURL()

  const RECIssuer = useRECIssuerAddress()

  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  const issueRequest: RequestIssueOwnerRec = useMemo(() => {
    return {
      owner: account ?? undefined,
      issuer: RECIssuer,
      startDate: recProfile?.startDate
        ? DateTime.fromFormat(recProfile?.startDate, 'yyyy-MM-dd').toFormat('yyyyMMdd')
        : undefined,
      endDate: recProfile?.endDate
        ? DateTime.fromFormat(recProfile?.endDate, 'yyyy-MM-dd').toFormat('yyyyMMdd')
        : undefined,
      totalARECPower: amountTotalRE.toHexString(),
      valueApproval: approvalAmount ? BigNumber.from(approvalAmount.raw.toString()).toHexString() : undefined,
      deadlineApproval: signatureData !== null ? signatureData?.deadline : undefined,
      signatureApproval:
        signatureData !== null
          ? solidityPack(['bytes1', 'bytes32', 'bytes32'], [signatureData.v, signatureData.r, signatureData.s])
          : undefined
    }
  }, [account, RECIssuer, recProfile, amountTotalRE, approvalAmount, signatureData])

  const [batchID, setBatchID] = useState<string | undefined>()
  const [arecCID, setArecCID] = useState<RecCID | undefined>()

  const { errorArecCID, arecCID: originArecCID, curBatchID } = useIssueOwnerRec(simulationMode, issueRequest, batchID)

  useEffect(() => {
    if (curBatchID !== batchID) return
    setArecCID(originArecCID)
  }, [originArecCID, curBatchID, batchID])

  const handler = useRef<ReturnType<typeof setTimeout>>()

  const handleSetEndDate = useCallback(
    (endDate: string | undefined) => {
      if (!endDate || !minDate || !maxDate) return

      if (handler.current) {
        clearTimeout(handler.current)
      }

      if (endDate >= minDate && endDate <= maxDate) {
        setEndDate(endDate)
        setArecCID(undefined)
        setSignatureData(null)
      }
      handler.current = setTimeout(() => {
        if (endDate < minDate || endDate > maxDate) {
          window.alert(`Please select the data between ${minDate} and ${maxDate}`)
          return
        }

        setEndDateMemo(endDate)
        setBatchID(nanoid())
        setDateSelected(true)

        const simuRecProfile = { ...recProfileInit } as ProfileAREC
        simuRecProfile.endDate = endDate
        const random = BigNumber.from(Math.floor(Math.random() * 1000000000))
        const amountTotalRE = BigNumber.from(simuRecProfile.amountTotalRE).add(random)
        simuRecProfile.amountTotalRE = amountTotalRE.toString()
        setSimuRECProfile(simuRecProfile)
        setDataAREC(undefined)
      }, 500)
    },
    [minDate, maxDate, handler, recProfileInit, setDataAREC]
  )

  async function handleClearMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, IssueErrorMessage: undefined, txHash })
  }

  const recRequest: RECRequest | undefined = useMemo(() => {
    if (!recProfile) return undefined
    if (!TrialMode && (errorArecCID !== '' || !arecCID || recProfile.endDate === '')) return undefined

    return {
      issuer: RECIssuer,
      startTime: BigNumber.from(DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds()),
      endTime: BigNumber.from(
        DateTime.fromFormat(recProfile.endDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds() + 24 * 3600 - 1
      ),
      amountREC: BigNumber.from(recProfile.amountTotalRE),
      cID: TrialMode ? recProfile.cID : (arecCID as RecCID).cid,
      region: recProfile.region,
      url: TrialMode ? recProfile.url : arecCID?.uri ?? '',
      memo: ''
    }
  }, [TrialMode, recProfile, RECIssuer, errorArecCID, arecCID])

  async function handleRECRequest() {
    if (
      !arkreenRECIssuanceContract ||
      !arkreenTokenContract ||
      !signatureData ||
      !deadline ||
      !ttl ||
      !approvalAmount ||
      !recRequest
    )
      return

    // In simulation mode, does not check
    if (!TrialMode && (errorArecCID !== '' || !arecCID)) return ///////////////

    const signatureToPay = [
      arkreenTokenContract.address,
      approvalAmount.raw.toString(),
      signatureData.deadline,
      signatureData.v,
      signatureData.r,
      signatureData.s
    ]

    setIsRequesting(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, IssueErrorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['mintRECRequest'](recRequest, signatureToPay)
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .mintRECRequest(recRequest, signatureToPay, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then(async (response: TransactionResponse) => {
            setIsRequesting(false)
            setDateSelected(false)
            setSignatureData(null)
            addTransaction(response, {
              summary: `Request AREC issued by ${shortenAddress(recRequest.issuer, 6)}`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              IssueErrorMessage: undefined,
              txHash: response.hash
            })
            updatetxHash(response.hash)

            const data = {
              address: account
            }

            const eventType = 'AREC_Issuance_Request'

            ReactGA.event({
              category: eventType,
              action: eventType,
              label: JSON.stringify(data)
            })

            TalkingDataUtils.sendEvent(eventType, data)
          })
          .catch((error: any) => {
            setIsRequesting(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Request AREC Issuance failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Request AREC Issuance failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsRequesting(false)
        console.log('Request AREC Issuance failed:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          IssueErrorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function onAttemptToApprove() {
    if (!arkreenTokenContract || !arkreenRECIssuanceContract || !provider || !deadline || !ttl)
      throw new Error('missing dependencies')
    if (!approvalAmount) throw new Error('missing liquidity amount')

    // try to gather a signature for permission
    setIsApproving(true)
    const nonce = await arkreenTokenContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]
    const domain = {
      name: TrialMode ? 'Arkreen DAO Token' : 'Arkreen Token',
      version: '1',
      chainId: chainId,
      verifyingContract: arkreenTokenContract.address
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    const nowDeadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const usedDeadline = deadline.lt(nowDeadline) ? nowDeadline : deadline.toNumber()

    const message = {
      owner: account,
      spender: arkreenRECIssuanceContract?.address,
      value: approvalAmount.raw.toString(),
      nonce: nonce.toNumber(),
      deadline: usedDeadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    provider
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setIsApproving(false)
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: usedDeadline
        })
      })
      .catch(error => {
        setIsApproving(false)
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          console.log('User reject the permission!')
          // approveCallback()
        }
        console.log('Error in eth_signTypedData_v4', error)
      })
  }

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow = !(!arkreenTokenContract || !provider || !approvalAmount || !dateSelected)
  const [cID, shortCID] = useMemo(() => {
    const cID = TrialMode ? recProfileInit.cID : arecCID?.cid
    return cID ? [cID, shortenCID(cID)] : ['', '']
  }, [TrialMode, arecCID, recProfileInit])

  const amountTotalREString = new Fraction(amountTotalRE.toString(), JSBI.BigInt(1000000)).toFixed(3)

  const { font14, font16, font20 } = useFontSize()
  const font20Str = font20.toString() + 'px'

  // const now = DateTime.now().toSeconds()
  // const ifLockedForLaunch = now >= 1708992000 && now < 1709078400 // 1709020800 1708963200
  const ifLockedForLaunch = false

  return (
    <AppBody style={{ marginTop: below540 ? '30px' : '30px' }}>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'AREC Issuance'}>
          {chainId && <QuestionHelper bkgOff={true} text={'AREC Issuance'} info={<IssuanceHelpInfo />} />}
        </PageHeader>
        <Wrapper id="issuance-page">
          <ErrorPromptModal isOpen={!!IssueErrorMessage} errString={IssueErrorMessage} onDismiss={handleClearMessage} />

          <AutoColumn gap={'md'}>
            <ARECIssuanceDate
              active={!isApproving && !isRequesting && (TrialMode || !!recProfile)}
              minDate={minDate}
              maxDate={recProfile?.maxDate}
              startDate={startDate}
              endDate={endDate}
              onChangeDate={handleSetEndDate}
              id="issuance_date"
            >
              {TrialMode || !!recProfile ? (
                <OverallAREC recProfile={recProfile ?? recProfileInit} minerNumber={countMiners ?? 0} />
              ) : !account ? (
                <Text fontWeight={700} textAlign="center" color={theme.text2} fontSize={font16}>
                  Please connect your wallet!
                </Text>
              ) : ifLockedForLaunch ? (
                <Text fontWeight={500} textAlign="center" fontSize={font16} color="red" padding="8px 0px 0px">
                  Arkreen mainnet will be ready at: <br />
                  2024-02-28 00:00:00 UTC+00
                </Text>
              ) : !minerList || minerList.length === 0 ? (
                <ARECContainer>
                  <Text fontWeight={500} textAlign="center" fontSize={font16} padding="2px 0px">
                    You have no miner yet!   Please order from:
                  </Text>
                  <Text fontWeight={500} textAlign="center" fontSize={font16} padding="2px 0px">
                    <OrderLink id="link" href={MINER_BUY_URL}>
                      <ShoppingCart size={font16} />
                      Order Miners
                    </OrderLink>
                  </Text>
                </ARECContainer>
              ) : errorAREC !== '' ? (
                <ARECContainer>
                  <Text fontWeight={400} fontSize={font16} padding="2px 0px">
                    You have {countMiners}
                    miner(s), but your renewable energy output is not enough to mint the AREC. You could order more
                    miners from:
                  </Text>
                  <Text fontWeight={500} textAlign="center" fontSize={font16} padding="2px 0px">
                    <OrderLink id="link" href={MINER_BUY_URL}>
                      <ShoppingCart size={16} />
                      Order Miners
                    </OrderLink>
                  </Text>
                </ARECContainer>
              ) : (
                <div />
              )}
            </ARECIssuanceDate>

            {(TrialMode || !!recProfile) && (
              <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A' }}>
                <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
                  <RowBetween align="center" height={font20Str}>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      AREC Issuer:
                    </Text>
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      Arkreen Fund
                    </Text>
                  </RowBetween>
                  <RowBetween align="center" height={font20Str}>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      Total AREC Amount:
                    </Text>
                    <Text
                      fontWeight={700}
                      fontSize={font14}
                      color={signatureData !== null ? theme.primaryText1 : theme.text2}
                    >
                      {amountTotalREString} KWH
                    </Text>
                  </RowBetween>

                  <RowBetween align="center" height={font20Str}>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      Total Issuance Cost:
                    </Text>
                    <Text
                      fontWeight={700}
                      fontSize={font14}
                      color={signatureData !== null ? theme.primaryText1 : theme.text2}
                    >
                      {approvalAmount?.toFixed(3)} AKRE
                    </Text>
                  </RowBetween>

                  {signatureData !== null && (
                    <RowBetween align="center" height={font20Str}>
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        AREC cID:
                      </Text>
                      {!shortCID || (!simulationMode && curBatchID !== batchID) ? (
                        <Loader fontSize={font14} />
                      ) : (
                        <div>
                          {recRequest?.url && (
                            <ExternalLink href={recRequest?.url} style={{ paddingRight: '6px' }}>
                              <LinkIcon size={font14} color={theme.text2} />
                            </ExternalLink>
                          )}
                          <MouseoverTooltip info={<>{cID}</>}>
                            <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                              {shortCID}
                            </Text>
                          </MouseoverTooltip>
                        </div>
                      )}
                    </RowBetween>
                  )}
                </AutoColumn>
              </Container>
            )}
          </AutoColumn>

          <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : ifLockedForLaunch ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Mainnet is coming
                </Text>
              </ButtonError>
            ) : !arkreenTokenContract || !provider ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Waiting AREC Info
                </Text>
              </ButtonError>
            ) : !approvalAmount || !enableRECMint ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  No AREC to Mint
                </Text>
              </ButtonError>
            ) : !dateSelected ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Select End AREC Date
                </Text>
              </ButtonError>
            ) : (
              <RowBetween>
                <ButtonConfirmed
                  onClick={() => {
                    onAttemptToApprove()
                  }}
                  disabled={signatureData !== null || isApproving || ifMoreInput || ifEmptyWallet}
                  width={ifMoreInput || ifEmptyWallet ? '100%' : '48%'}
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  padding={'18px 0px'}
                >
                  <Text fontSize={font20} fontWeight={500}>
                    {signatureData !== null
                      ? 'AKRE Approved'
                      : ifEmptyWallet
                      ? 'Wallet Empty'
                      : ifMoreInput
                      ? 'AKRE Too Less'
                      : 'Approve AKRE'}
                  </Text>
                </ButtonConfirmed>
                {!(ifMoreInput || ifEmptyWallet) && (
                  <ButtonError
                    onClick={() => handleRECRequest()}
                    width="48%"
                    id="swap-button"
                    disabled={signatureData == null || isRequesting || ifMoreInput || (!TrialMode && !arecCID)}
                    padding={'18px 0px'}
                  >
                    <Text fontSize={font20} fontWeight={500}>
                      Mint AREC
                    </Text>
                  </ButtonError>
                )}
              </RowBetween>
            )}
            {showApproveFlow && !(ifMoreInput || ifEmptyWallet) && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[signatureData !== null && (TrialMode || !!arecCID)]} />
              </Column>
            )}
          </BottomGrouping>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
  )
}

//confirmed={approval === ApprovalState.APPROVED}
