import { Fraction, JSBI } from '@arec/sdk'
import { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { RowBetween, RowFixed } from 'components/Row'
import { Text } from 'rebass'
import QuestionHelper from 'components/QuestionHelper'
import { useFontSize } from 'hooks/useWindowSize'
import { AssetAREC } from 'state/arec/updater'
import { ARECContainer } from 'components/ARecIssuance'
import CopyTool from 'components/AccountDetails/Copy'
import { shortenAddress, shortenContent } from 'utils'
import { MouseoverTooltip } from 'components/Tooltip'
import { useToken } from '../../hooks/Tokens'

export function DetailedAssetInfo({ assetREC }: { assetREC: AssetAREC }) {
  const theme = useContext(ThemeContext)

  const { font14, font24 } = useFontSize()
  const font24Str = font24.toString() + 'px'

  const paymentToken = useToken(assetREC.tokenPay)

  const priceToIssueRECString = useMemo(() => {
    if (!paymentToken) return ''
    const priceString = new Fraction(assetREC.rateToIssue.toString(), JSBI.BigInt('1000000000000000000000')).toFixed(2)
    return priceString + ' ' + paymentToken.symbol + '/KMh'
  }, [paymentToken, assetREC])

  return (
    <ARECContainer>
      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Type of the REC Asset:
          </Text>
          <QuestionHelper bkgOff={true} small={'s'} info={<>This is the type of the REC asset you will issue.</>} />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {assetREC.idAsset}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Price To Issue AREC:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>The price is valued as the number of payment tokens required to pay for issuing REC asset per MWh.</>
            }
          />
        </RowFixed>
        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text1}>
          {priceToIssueRECString}
        </Text>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            ART Token:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                This is the address of the ART token you will receive while you liquidize your the AREC NFT issued here.
              </>
            }
          />
        </RowFixed>

        <CopyTool toCopy={assetREC.tokenREC} fontSize={font14} right={true}>
          <MouseoverTooltip info={<>{assetREC.tokenREC}</>}>
            <Text lineHeight={'24px'} fontWeight={700} fontSize={font14} color={theme.text1}>
              {shortenAddress(assetREC.tokenREC, 6)}
            </Text>
          </MouseoverTooltip>
        </CopyTool>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Payment Token:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the address of the payment token in which you need to pay for issuing the REC asset.</>}
          />
        </RowFixed>

        <CopyTool toCopy={assetREC.tokenPay} fontSize={font14} right={true}>
          <MouseoverTooltip info={<>{assetREC.tokenPay}</>}>
            <Text lineHeight={'24px'} fontWeight={700} fontSize={font14} color={theme.text1}>
              {shortenAddress(assetREC.tokenPay, 6)}
            </Text>
          </MouseoverTooltip>
        </CopyTool>
      </RowBetween>

      <RowBetween align="center" height={font24Str}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Asset description:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the description od the REC asset to be issued.</>}
          />
        </RowFixed>

        <CopyTool toCopy={assetREC.description} fontSize={font14} right={true}>
          <MouseoverTooltip info={<>{assetREC.description}</>}>
            <Text lineHeight={'24px'} fontWeight={700} fontSize={font14} color={theme.text1}>
              {shortenContent(assetREC.description, 20)}
            </Text>
          </MouseoverTooltip>
        </CopyTool>
      </RowBetween>
    </ARECContainer>
  )
}
