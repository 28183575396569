import { createReducer } from '@reduxjs/toolkit'
import { updateArecCount, updateTxHash, updateQuickArecId, updateMintARECHash, updateLastQuickTime } from './actions'
import { updateQuickApproval, updateARECID, updateARECInfo } from './actions'

export interface arecState {
  readonly arecCount: number | undefined
  readonly txHash: string | undefined
  readonly quickArecId: {
    [chainId: number]: {
      [account: string]: number | undefined
    }
  }
  readonly hashMintTx: {
    [chainId: number]: {
      [account: string]: string | undefined
    }
  }
  readonly quickApproval: {
    [chainId: number]: {
      [account: string]: string | undefined
    }
  }
  readonly infoARECNFT: {
    [chainId: number]: {
      [account: string]: string | undefined
    }
  }
  readonly cidARECNFT: {
    [chainId: number]: {
      [account: string]: string | undefined
    }
  }
  readonly lastQuickTime: {
    [chainId: number]: {
      [account: string]: string | undefined
    }
  }
}

const initialState: arecState = {
  arecCount: undefined,
  txHash: undefined,
  quickArecId: {},
  hashMintTx: {},
  quickApproval: {},
  infoARECNFT: {},
  cidARECNFT: {},
  lastQuickTime: {}
}

export default createReducer<arecState>(initialState, builder =>
  builder
    .addCase(updateArecCount, (state, { payload: arecCount }) => {
      return {
        ...state,
        arecCount
      }
    })
    .addCase(updateTxHash, (state, { payload: txHash }) => {
      return {
        ...state,
        txHash
      }
    })
    .addCase(updateQuickArecId, (state, { payload: { chainId, owner, quickArecId } }) => {
      state.quickArecId[chainId] = state.quickArecId[chainId] || {}
      state.quickArecId[chainId][owner] = quickArecId
    })
    .addCase(updateMintARECHash, (state, { payload: { chainId, owner, hashTx } }) => {
      state.hashMintTx[chainId] = state.hashMintTx[chainId] || {}
      state.hashMintTx[chainId][owner] = hashTx
    })
    .addCase(updateQuickApproval, (state, { payload: { chainId, owner, approval } }) => {
      state.quickApproval[chainId] = state.quickApproval[chainId] || {}
      state.quickApproval[chainId][owner] = approval
    })
    .addCase(updateARECInfo, (state, { payload: { chainId, owner, infoARECNFT } }) => {
      state.cidARECNFT[chainId] = state.cidARECNFT[chainId] || {}
      state.cidARECNFT[chainId][owner] = infoARECNFT
    })
    .addCase(updateARECID, (state, { payload: { chainId, owner, cidString } }) => {
      state.cidARECNFT[chainId] = state.cidARECNFT[chainId] || {}
      state.cidARECNFT[chainId][owner] = cidString
    })
    .addCase(updateLastQuickTime, (state, { payload: { chainId, owner, lastQuickTime } }) => {
      state.lastQuickTime = state.lastQuickTime || {}
      state.lastQuickTime[chainId] = state.lastQuickTime[chainId] || {}
      state.lastQuickTime[chainId][owner] = lastQuickTime
    })
)
