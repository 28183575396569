import { Currency, CurrencyAmount, Fraction, Percent } from '@arec/sdk'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { ZERO_FRACTION } from '../../utils'
import { useWeb3React } from '@web3-react/core'
import { SwapCallbackError, BottomGrouping } from '../../components/swap/styleds'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
  errMessage
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: { [field in Field]?: Percent }
  onAdd: () => void
  errMessage?: string
}) {
  const { chainId } = useWeb3React()
  return (
    <>
      <RowBetween>
        <TYPE.Body>{currencies[Field.CURRENCY_A]?.getSymbol(chainId)} Deposited:</TYPE.Body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.Body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.Body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.Body>{currencies[Field.CURRENCY_B]?.getSymbol(chainId)} Deposited:</TYPE.Body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.Body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.Body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.Body>Mean Token Rates: </TYPE.Body>
        <TYPE.Body>
          {`1 ${currencies[Field.CURRENCY_A]?.getSymbol(chainId)} = ${price?.toSignificant(5)} ${currencies[
            Field.CURRENCY_B
          ]?.getSymbol(chainId)}`}
        </TYPE.Body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.Body>
          {`1 ${currencies[Field.CURRENCY_B]?.getSymbol(chainId)} = ${price?.invert().toSignificant(5)} ${currencies[
            Field.CURRENCY_A
          ]?.getSymbol(chainId)}`}
        </TYPE.Body>
      </RowBetween>

      {poolTokenPercentage?.[Field.CURRENCY_A]?.greaterThan(ZERO_FRACTION) && (
        <RowBetween>
          <TYPE.Body>
            Share of Pool {currencies[Field.CURRENCY_A]?.getSymbol(chainId)}🔗
            {currencies[Field.CURRENCY_B]?.getSymbol(chainId)}:
          </TYPE.Body>
          <TYPE.Body>{poolTokenPercentage?.[Field.CURRENCY_A]?.toSignificant(4)}%</TYPE.Body>
        </RowBetween>
      )}

      {poolTokenPercentage?.[Field.CURRENCY_B]?.greaterThan(ZERO_FRACTION) && (
        <RowBetween>
          <TYPE.Body>
            Share of Pool {currencies[Field.CURRENCY_B]?.getSymbol(chainId)}🔗
            {currencies[Field.CURRENCY_A]?.getSymbol(chainId)}:
          </TYPE.Body>
          <TYPE.Body>{poolTokenPercentage?.[Field.CURRENCY_B]?.toSignificant(4)}%</TYPE.Body>
        </RowBetween>
      )}

      <BottomGrouping>
        <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
          <Text fontWeight={500} fontSize={20}>
            Confirm Supply
          </Text>
        </ButtonPrimary>
        {errMessage && <SwapCallbackError error={errMessage} />}
      </BottomGrouping>
    </>
  )
}
