import { useEffect, useState } from 'react'
import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuickArecId, useMintARECHash } from '../../state/arec/hooks'
import { utils } from 'ethers'
import { useGetARECNFTInfo, REC_STATUS } from '../../state/issuance/hooks'

import { useRECIssuanceContract, useArkreenRetirementAddress } from '../../hooks/useContract'

import { IssuanceSteps } from './IssuanceSteps'
import { LiquidizeSteps } from './LiquidizeSteps'

export default function QuickStep() {
  const { account, chainId, provider } = useWeb3React()

  const { quickArecId, updateQuickArecNFTId } = useQuickArecId()

  const { hashMintTx, updateMintARECNFTHash } = useMintARECHash()
  const arecNFTInfo = useGetARECNFTInfo(quickArecId)

  const [owner, setOwner] = useState<string | undefined>()

  const arkreenRECIssuanceContract = useRECIssuanceContract(false)
  const badgeAddress = useArkreenRetirementAddress()
  // const tokenARTAddress = useARECTokenAddress()

  useEffect(() => {
    function tick() {
      if (!account || !chainId || !provider || !hashMintTx) return
      provider.getTransactionReceipt(hashMintTx).then(receipt => {
        if (receipt) {
          const log = receipt.logs.find(log => {
            // event RECRequested(address owner, uint256 tokenId);
            return log.topics[0] == '0x6f2b8258617ca0f7ca19a3f678b401adfe6ac598e2b59348df18c20b41d2626a'
          })
          if (log !== undefined) {
            const data = utils.defaultAbiCoder.decode(['uint256', 'uint256'], log.data)
            updateQuickArecNFTId(chainId, account, (data[1] as BigNumber).toNumber())
          }
        }
      })
    }

    if (!account || !chainId || !provider || !hashMintTx || quickArecId != undefined) return
    const id = setInterval(tick, 3000)
    return () => clearInterval(id)
  }, [chainId, account, provider, hashMintTx, quickArecId, updateQuickArecNFTId])

  useEffect(() => {
    async function checkOwner() {
      if (!arkreenRECIssuanceContract || !quickArecId) return
      let ownerToCheck
      try {
        ownerToCheck = await arkreenRECIssuanceContract.ownerOf(quickArecId)
      } catch (error) {
        return
      }
      if (ownerToCheck !== owner) {
        setOwner(ownerToCheck)
      }
    }
    checkOwner()
  }, [quickArecId, arkreenRECIssuanceContract, owner, setOwner])

  useEffect(() => {
    if (!account || !chainId || !quickArecId || !arecNFTInfo || !owner || !badgeAddress) return
    if (arecNFTInfo.status === REC_STATUS.Rejected) {
      updateMintARECNFTHash(chainId, account, undefined)
      updateQuickArecNFTId(chainId, account, undefined)
    }

    //    if (arecNFTInfo.minter !== account) {
    //      updateMintARECNFTHash(chainId, account, undefined)
    //      updateQuickArecNFTId(chainId, account, undefined)
    //    }

    // owner.toLowerCase() === tokenARTAddress.toLowerCase() // should not check this
    if (owner.toLowerCase() === badgeAddress.toLowerCase()) {
      updateMintARECNFTHash(chainId, account, undefined)
      updateQuickArecNFTId(chainId, account, undefined)
    }
  }, [chainId, account, quickArecId, arecNFTInfo, updateQuickArecNFTId, badgeAddress, updateMintARECNFTHash, owner])

  const ifShowMint =
    !account || !chainId || quickArecId === undefined || arecNFTInfo === undefined || arecNFTInfo.startTime === 0

  return (
    <>
      {ifShowMint && <IssuanceSteps />}
      {!ifShowMint && <LiquidizeSteps arecNFTInfo={arecNFTInfo} />}
    </>
  )
}
