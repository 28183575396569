import { useRef } from 'react'
import { List, MessageCircle, BookOpen, Home, Database, GitHub } from 'react-feather'
import styled from 'styled-components'
//import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ExternalLink } from '../../theme'
import { RowFixed } from '../Row'
import { Text } from 'rebass'

import { StyledMenuButton } from '../MenuApp'
import { useFontSize } from 'hooks/useWindowSize'

/*
const StyledMenuIcon = styled(MenuIcon)`
  height: 24px;
  width: 24px;
  path {
    stroke: ${({ theme }) => theme.text2};
  }
`
*/

const StyledMenuIcon = styled(Database)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.primaryText1};

  ${StyledMenuButton} :hover & {
    color: ${({ theme }) => theme.text5};
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 130px;
  background-color: ${({ theme }) => theme.primary5};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  left: 0rem;
  z-index: 100;
`

export const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export default function MenuInfo() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU_INFO)
  const toggle = useToggleModal(ApplicationModal.MENU_INFO)
  useOnClickOutside(node, open ? toggle : undefined)
  const { font16, font20 } = useFontSize()

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <RowFixed>
          <StyledMenuIcon />
          <Text fontSize={font20} style={{ padding: '0px 6px' }}>
            INFO
          </Text>
        </RowFixed>
      </StyledMenuButton>

      {open && (
        <MenuFlyout onClick={toggle}>
          <MenuItem id="link" draggable="false" href="https://www.arkreen.com">
            <Home size={font16} />
            <span style={{ fontSize: font16 }}>Arkreen</span>
          </MenuItem>
          <MenuItem id="link" draggable="false" href="https://explorer.arkreen.com">
            <List size={font16} />
            <span style={{ fontSize: font16 }}>Explorer</span>
          </MenuItem>
          <MenuItem id="link" draggable="false" href="https://docs.arkreen.com">
            <BookOpen size={font16} />
            <span style={{ fontSize: font16 }}>Docs</span>
          </MenuItem>
          <MenuItem size={16} draggable="false" id="link" href="https://github.com/arkreen">
            <GitHub size={font16} />
            <span style={{ fontSize: font16 }}>Github</span>
          </MenuItem>
          <MenuItem size={16} draggable="false" id="link" href="https://twitter.com/arkreen_network">
            <MessageCircle size={font16} />
            <span style={{ fontSize: font16 }}>Twitter</span>
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
