import React from 'react'
import styled from 'styled-components'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../../theme'
import { CheckCircle, Copy } from 'react-feather'

const CopyIcon = styled(LinkStyledButton)`
  color: ${({ theme }) => theme.text3};
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
  }
`
const TransactionStatusText = styled.span`
  margin-left: 0.25rem;
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

export default function CopyTool(props: {
  toCopy: string
  fontSize?: number
  right?: boolean
  children?: React.ReactNode
}) {
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyIcon onClick={() => setCopied(props.toCopy)} style={{ paddingRight: props.right ? '0px' : undefined }}>
      <TransactionStatusText style={{ fontSize: props.fontSize ?? undefined, marginLeft: '0rem' }}>
        {isCopied ? <CheckCircle size={props.fontSize ?? 16} color="green" /> : <Copy size={props.fontSize ?? 16} />}
        {props.children && <TransactionStatusText>{props.children}</TransactionStatusText>}
      </TransactionStatusText>
    </CopyIcon>
  )
}
