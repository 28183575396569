import { Token, TokenAmount, JSBI, ChainId } from '@arec/sdk'
import { useCallback, useContext, useEffect, useState, useMemo, ChangeEvent } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonConfirmed } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { RowBetween, AutoRow, RowFixed } from '../../components/Row' //
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
//import ProgressSteps from '../../components/ProgressSteps'
import PageHeader from '../../components/PageHeader'
import { TYPE } from '../../theme'
import { StyledPageCard } from '../../components/earn/styled'
import { M, BridgerHeadButton } from '../../components/ARecIssuance' // ExchangeHeadButton

import { useWeb3React } from '@web3-react/core'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import AppBody from '../AppBody'
import Loader from '../../components/Loader'
import QuestionHelper from '../../components/QuestionHelper'
import { useUserTransactionTTL } from 'state/user/hooks'
import { useToken } from '../../hooks/Tokens'
import { Container } from 'components/CurrencyInputPanel'
import { useFontSize } from 'hooks/useWindowSize'
import { utils } from 'ethers'
import { USDC } from '../../constants'
import { DateTime } from 'luxon'
import { splitSignature } from 'ethers/lib/utils' // solidityPack
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useRECIssuanceContract } from '../../hooks/useContract'
import { useETHBalances } from '../../state/wallet/hooks'
import { usePermitTokenContract } from '../../hooks/useContract'
import { ErrorPromptModal, ARECSelect, ARECOption, getPowerString, ButtonUpOrDown } from 'components/ARecIssuance'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { calculateGasMargin } from '../../utils'
import { assetARECListAtom } from 'state/arec/updater'
import { useAtomValue } from 'jotai'
import { DetailedAssetInfo } from './assetInfo'
import { Input as NumericalInput } from 'components/NumericalInput'
import { tryParseAmount } from 'state/swap/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { AssetAREC } from 'state/arec/updater'
import { useGetUserARECList } from '../../state/issuance/hooks'
import { RECData, REC_STATUS } from '../../state/issuance/hooks'
import { useArecCount } from 'state/arec/hooks'
import styled from 'styled-components'
import { Separator } from 'components/SearchModal/styleds'
import { StyledTokenName, CurrencySelect, Aligner } from 'components/CurrencyInputPanel'
import CopyTool from 'components/AccountDetails/Copy'
import CurrencyLogo from 'components/CurrencyLogo'
import { StyledInternalLink } from '../../theme'
import { ArrowRightCircle } from 'react-feather'
import { MouseoverTooltip } from 'components/Tooltip'
import { isIssuer } from 'state/arec/updater'

const InputRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export function BridgeHelpInfo() {
  return (
    <>
      <Text>
        <M>You are allowed to bridge REC asset to Web3 world once you have registered with Arkreen Network.</M>
      </Text>
      <Text>
        <M>1.</M> Connect to your wallet registered with Arkreen.
      </Text>
      <Text>
        <M>2.</M> Select the REC asset you have registered and want to bridge to Web3 world.
      </Text>
      <Text>
        <M>3.</M> Input the amount of the REC asset you want to bridge. You need to pay some bridge fee which is
        proportional to the amount of the REC asset to bridge.
      </Text>
      <Text>
        <M>4.</M> Click the button <M>Approve XXX</M> to approve Arkreen smart contact transferring bridge fee from your
        account.
      </Text>
      <Text>
        <M>5.</M> Click the button <M>Issue REC</M> to initiate the REC asset bridging transaction. While this
        transction is ledgered on blockchain, an AREC NFT will be minted to your account, anyhow most data item of which
        is still empty and waiting for you to supplement.
      </Text>
    </>
  )
}

export default function Bridge() {
  const { account, chainId, provider } = useWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const bridgeAllowed = useAtomValue(isIssuer)

  const assetARECList = useAtomValue(assetARECListAtom)

  const addTransaction = useTransactionAdder()
  const arkreenRECIssuanceContract = useRECIssuanceContract(true)
  const { updatetxHash } = useArecCount()

  const [assetInfo, setAssetInfo] = useState<AssetAREC | undefined>(undefined)

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  const [assetSelected, setAssetSelected] = useState<number | undefined>()
  const onAssetSelect = useCallback(
    (arecSelect: ChangeEvent<HTMLSelectElement>) => {
      setAssetSelected(Number(arecSelect.target.value))
      const idAsset = Number(arecSelect.target.value)
      if (!isNaN(idAsset)) {
        setAssetInfo(assetARECList[idAsset])
      }
    },
    [setAssetSelected, assetARECList]
  )

  const [showRECInfo, setShowRECInfo] = useState(false)

  const assetToken = useToken(assetInfo?.tokenREC)
  const paymentToken = useToken(assetInfo?.tokenPay)
  const payTokenContract = usePermitTokenContract(assetInfo?.tokenPay)

  const [issueAmountInput, setIssueAmountInput] = useState<string>('')

  const handleTypeAmount = useCallback(
    (value: string) => {
      setIssueAmountInput(value)
      setSignatureData(undefined)
    },
    [setIssueAmountInput]
  )

  const assetAmount = tryParseAmount(issueAmountInput, assetToken ?? undefined)
  const payAmount: TokenAmount | undefined = useMemo(() => {
    if (!assetInfo || !assetAmount) return undefined
    const rateToIssue: JSBI = JSBI.BigInt(assetInfo.rateToIssue.toString())
    const feeToPay = JSBI.divide(JSBI.multiply(assetAmount.numerator, rateToIssue), assetAmount.denominator)
    return new TokenAmount(paymentToken as Token, feeToPay)
  }, [assetInfo, paymentToken, assetAmount])

  const paymentBalance = useTokenBalance(account ?? undefined, paymentToken ?? undefined)

  const ifMoreInput = useMemo(() => {
    if (!payAmount || !paymentBalance) return false
    if (payAmount.greaterThan(paymentBalance)) return true
    return false
  }, [payAmount, paymentBalance])

  const [approval, approveCallback] = useApproveCallback(payAmount, arkreenRECIssuanceContract?.address)

  // modal and loading
  const [{ showConfirm, txnToConfirm, errorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: any
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const { numberOfARECNft, allARECInfo, allARECNftTokensID } = useGetUserARECList()

  const [arecSelected, setARECSelected] = useState<number | undefined>()

  useEffect(() => {
    if (arecSelected === undefined && !!allARECInfo && allARECInfo.length !== 0) {
      setARECSelected(0)
    }
  }, [arecSelected, allARECInfo])

  const onARECSelect = useCallback(
    (arecSelect: ChangeEvent<HTMLSelectElement>) => {
      setARECSelected(Number(arecSelect.target.value))
    },
    [setARECSelected]
  )

  const recPowerList = allARECInfo.map((recData: RECData) => {
    return getPowerString(recData.amountREC)
  })

  const recStatusList = allARECInfo.map((recData: RECData) => {
    const recStatus =
      recData?.status === REC_STATUS.Pending
        ? 'Pending'
        : recData?.status === REC_STATUS.Certified
        ? 'Certified'
        : recData?.status === REC_STATUS.Cancelled
        ? 'Cancelled'
        : recData?.status === REC_STATUS.Rejected
        ? 'Rejected'
        : ' '
    return recStatus
  })

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval])

  const ethBalance = useETHBalances(account ? [account] : [])

  const ifEmptyWallet = useMemo(() => {
    if (!ethBalance || !account || !ethBalance[account]) return false
    return ethBalance[account]?.equalTo('0') ? true : false
  }, [ethBalance, account])

  const [signatureData, setSignatureData] = useState<
    { v: number; r: string; s: string; deadline: number } | undefined
  >()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)

  const deadline = useTransactionDeadline() // custom from users settings
  const [ttl] = useUserTransactionTTL()

  async function onAttemptToApprove() {
    if (!payTokenContract || !provider || !deadline || !ttl) return
    if (!payAmount || !chainId || !arkreenRECIssuanceContract) return

    const handlerError = (error: any) => {
      const dataMsg = error?.data?.message
        ? ' Details: ' + error.data.message
        : error?.reason ?? error?.message ?? error?.code

      const dataMsgStr = dataMsg.charAt(0).toUpperCase() + dataMsg.slice(1)

      setARECTxnState({
        attemptingTxn: false,
        txnToConfirm,
        showConfirm,
        errorMessage: dataMsgStr,
        txHash: undefined
      })
    }

    const approve = async () => {
      approveCallback()
        .then(() => {
          setIsWaitingWallet(false)
        })
        .catch(error => {
          handlerError(error)
          setIsWaitingWallet(false)
        })
    }

    setIsWaitingWallet(true)

    // try to gather a signature for permission
    let nonce
    let name
    try {
      if (chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET) throw new Error('cUSD does not support permit!')

      nonce = await payTokenContract.nonces(account)
      name = await payTokenContract.name()
    } catch (error) {
      await approve()
      return
    }

    const payAmountString = payAmount.raw.toString()
    const spender = arkreenRECIssuanceContract.address

    let EIP712Domain: any
    let domain: any

    if (payTokenContract.address === USDC[chainId].address) {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'verifyingContract', type: 'address' },
        { name: 'salt', type: 'bytes32' }
      ]
      domain = {
        name: name,
        version: '1',
        verifyingContract: payTokenContract.address,
        salt: utils.hexZeroPad(utils.hexlify(chainId), 32)
      }
    } else {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ]
      domain = {
        name: name,
        version: '1',
        chainId: chainId,
        verifyingContract: payTokenContract.address
      }
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    const nowDeadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const usedDeadline = deadline.lt(nowDeadline) ? nowDeadline : deadline.toNumber()

    const message = {
      owner: account,
      spender: spender,
      value: payAmountString,
      nonce: nonce.toHexString(),
      deadline: usedDeadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    provider
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: usedDeadline
        })
        setIsWaitingWallet(false)
      })
      .catch(async error => {
        if (error?.code === 4001) {
          handlerError(error)
          setIsWaitingWallet(false)
        } else {
          await approve()
        }
      })
  }

  async function onRequestToIssue() {
    if (!arkreenRECIssuanceContract || !payTokenContract || (!signatureData && approval !== ApprovalState.APPROVED))
      return
    if (!assetSelected || !assetAmount || !deadline || !ttl || !payAmount) return

    let signatureToPay: any
    if (!signatureData) {
      const ZeroString = '0x0000000000000000000000000000000000000000000000000000000000000000'
      const nowDeadline = Math.floor(DateTime.now().toSeconds() + ttl)
      signatureToPay = [payTokenContract.address, 0, nowDeadline, 0, ZeroString, ZeroString]
    } else {
      signatureToPay = [
        payTokenContract.address,
        payAmount.raw.toString(),
        signatureData.deadline,
        signatureData.v,
        signatureData.r,
        signatureData.s
      ]
    }

    const amountREC = assetAmount.raw.toString()
    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['mintESGBatch'](assetSelected, amountREC, signatureToPay)
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .mintESGBatch(assetSelected, amountREC, signatureToPay, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then(async (response: TransactionResponse) => {
            setIsWaitingWallet(false)
            setSignatureData(undefined)
            addTransaction(response, { summary: `Request AREC issued by ` })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            updatetxHash(response.hash)
            handleTypeAmount('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Request AREC Issuance failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Request AREC Issuance failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Request AREC Issuance failed:', error)
        const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: error.message + dataMsg,
          txHash: undefined
        })
      })
  }

  const { font14, font16, font20, font24 } = useFontSize()

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  return (
    <>
      <BridgerHeadButton path={'bridge'} />
      <AppBody>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={'Initiate Bridge'}>
            <QuestionHelper bkgOff={true} text={'REC Bridge Guidance:'} info={<BridgeHelpInfo />} />
          </PageHeader>

          <Wrapper id="swap-page">
            <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
            <AutoColumn gap={'md'}>
              <Container>
                <RowBetween height={'20px'} style={{ marginTop: '1rem' }}>
                  <RowFixed style={{ padding: '0.3rem 1rem 0rem' }}>
                    <TYPE.Body color={theme.text2} fontWeight={300} fontSize={font16}>
                      <strong>Type of the REC asset:</strong>
                    </TYPE.Body>
                  </RowFixed>
                  {assetSelected !== undefined && assetARECList[assetSelected] !== undefined && (
                    <ButtonUpOrDown bShow={showRECInfo} setbShow={setShowRECInfo} />
                  )}
                </RowBetween>

                <div style={{ margin: '0.6rem 0.6rem 0.6rem' }}>
                  <ARECSelect
                    itemSelected={!!assetSelected}
                    value={assetSelected !== undefined ? assetSelected.toString() : 'none'}
                    onChange={onAssetSelect}
                    fontSize={font16}
                    style={{
                      backgroundColor: theme.bg2,
                      padding: '0.6rem 0.6rem',
                      borderRadius: '6px',
                      border: '1px solid',
                      borderColor: theme.primary2
                    }}
                  >
                    <ARECOption
                      key="none"
                      value="none"
                      disabled
                      hidden={!!account && Object.keys(assetARECList).length > 0}
                      style={{ fontSize: font16, fontFamily: 'Tahoma', fontWeight: 600 }}
                    >
                      {!account ? 'Please connect wallet first' : 'Please select the REC asset to issue'}
                    </ARECOption>
                    {Object.keys(assetARECList).map(assetID => {
                      const optionTextID = '00'.concat(assetID)
                      return (
                        <ARECOption
                          key={optionTextID}
                          value={assetID}
                          style={{ fontSize: font16, fontFamily: 'Tahoma', fontWeight: 600 }}
                        >
                          {'REC_ID_'.concat(optionTextID.substring(optionTextID.length - 2)).concat(':')}
                          {'   '}
                          {assetARECList[Number(assetID)].idAsset}
                        </ARECOption>
                      )
                    })}
                  </ARECSelect>
                </div>

                <RowBetween align="center" height="20px" style={{ margin: '1rem 1rem 0.5rem 1rem' }}>
                  <TYPE.Body color={theme.text2} fontWeight={300} fontSize={font16} width={'45%'}>
                    <strong>Amount to Issue:</strong>
                  </TYPE.Body>
                </RowBetween>
                <InputRow style={{ padding: '0rem 0.6rem 1rem' }}>
                  <NumericalInput
                    className="token-amount-input"
                    value={issueAmountInput}
                    disabled={assetSelected === undefined}
                    decLength={3}
                    title={'REC Amount To Issue (in MWH)'}
                    fontSize={font24 + 'px'}
                    onUserInput={handleTypeAmount}
                    style={{ border: '1px solid', borderColor: theme.primary2 }}
                  />
                  <Text fontWeight={500} fontSize={font20} color={theme.text2} style={{ marginLeft: '0.4rem' }}>
                    MWh
                  </Text>
                </InputRow>

                {payAmount && (
                  <RowBetween align="center" style={{ padding: '0rem 1rem 0.5rem 1rem' }}>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', width: '45%' }}>
                      <TYPE.Body color={theme.text2} fontWeight={300} fontSize={font16}>
                        <strong>Issuance Fee:</strong>
                      </TYPE.Body>
                      {payAmount && <CopyTool toCopy={payAmount?.toExact()} fontSize={font14} />}
                    </div>
                    {account ? (
                      <TYPE.Body color={theme.text2} fontWeight={300} fontSize={font16}>
                        <strong>
                          {assetSelected !== undefined ? 'Available: ' + paymentBalance?.toSignificant(6) : ' -'}
                        </strong>
                      </TYPE.Body>
                    ) : null}
                  </RowBetween>
                )}

                {payAmount && (
                  <InputRow style={{ padding: '0rem 0.6rem 0.5rem' }}>
                    <NumericalInput
                      className="token-amount-input"
                      value={payAmount?.toFixed(3) ?? ''}
                      disabled={true}
                      fontSize={font24 + 'px'}
                      onUserInput={() => {}}
                    />

                    <CurrencySelect
                      selected={!!paymentToken}
                      className="open-currency-select-button"
                      disableSelect={true}
                    >
                      <Aligner>
                        {paymentToken ? <CurrencyLogo currency={paymentToken} size={font24 + 'px'} /> : null}
                        {
                          <StyledTokenName
                            className="token-symbol-container"
                            active={Boolean(paymentToken && paymentToken.getSymbol(chainId))}
                          >
                            {(paymentToken && paymentToken.symbol && paymentToken.symbol.length > 20
                              ? paymentToken.symbol.slice(0, 4) +
                                '...' +
                                paymentToken.symbol.slice(paymentToken.symbol.length - 5, paymentToken.symbol.length)
                              : paymentToken?.getSymbol(chainId)) || (account ? 'Select Asset' : 'No Wallet')}
                          </StyledTokenName>
                        }
                      </Aligner>
                    </CurrencySelect>
                  </InputRow>
                )}

                {showRECInfo && assetSelected !== undefined && assetARECList[assetSelected] !== undefined && (
                  <div style={{ padding: '0rem 0.6rem 0.6rem 0.6rem' }}>
                    <DetailedAssetInfo assetREC={assetARECList[assetSelected]} />
                  </div>
                )}
              </Container>
            </AutoColumn>
            <BottomGrouping>
              {!bridgeAllowed && (
                <Text
                  lineHeight={'20px'}
                  marginBottom={'0.5rem'}
                  fontWeight={500}
                  fontSize={16}
                  textAlign={'center'}
                  color={theme.red2}
                >
                  You are not authorized to bridge REC! <br /> Please contact with Arkreen Network first!
                </Text>
              )}
              {!account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : !bridgeAllowed ||
                ifMoreInput ||
                ifEmptyWallet ||
                issueAmountInput === '' ||
                assetSelected == undefined ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    {!bridgeAllowed
                      ? 'Not Approved REC Issuer'
                      : ifEmptyWallet
                      ? 'Wallet Empty'
                      : assetSelected == undefined
                      ? 'Select the REC assset'
                      : issueAmountInput === ''
                      ? 'Input REC Amount to Issue'
                      : ifMoreInput
                      ? 'Payment Token Too Less'
                      : 'Error'}
                  </Text>
                </ButtonError>
              ) : showApproveFlow ? (
                <>
                  <RowBetween>
                    <ButtonConfirmed
                      fontSize={font20}
                      fontWeight={500}
                      onClick={onAttemptToApprove}
                      disabled={signatureData !== undefined || isWaitingWallet || approval === ApprovalState.APPROVED}
                      width={'48%'}
                      altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                      padding={'18px 0px'}
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : (approvalSubmitted && approval === ApprovalState.APPROVED) || signatureData !== undefined ? (
                        paymentToken?.getSymbol(chainId) + ' Approved'
                      ) : (
                        'Approve ' + paymentToken?.getSymbol(chainId)
                      )}
                    </ButtonConfirmed>

                    {!ifEmptyWallet && (
                      <ButtonError
                        onClick={() => onRequestToIssue()}
                        width="48%"
                        id="swap-button"
                        disabled={signatureData == undefined && approval !== ApprovalState.APPROVED}
                        padding={'18px 0px'}
                      >
                        <Text fontSize={font20} fontWeight={500}>
                          Issue REC
                        </Text>
                      </ButtonError>
                    )}
                  </RowBetween>
                </>
              ) : (
                <ButtonConfirmed
                  onClick={() => {
                    onRequestToIssue()
                  }}
                  id="swap-button"
                >
                  <Text fontSize={font20} fontWeight={500}>
                    Request to Issue REC
                  </Text>
                </ButtonConfirmed>
              )}
            </BottomGrouping>
          </Wrapper>
          {numberOfARECNft !== undefined && numberOfARECNft !== 0 && !!allARECInfo && allARECInfo.length ? (
            <>
              <Separator />
              <Container style={{ margin: '1rem 1rem', backgroundColor: theme.bg2 }}>
                <RowBetween align="center" height="20px" style={{ padding: '0.75rem 1rem 0.2rem 1rem' }}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                    <strong>AREC NFT List:</strong>
                  </TYPE.Body>
                  <StyledInternalLink to={`/complete`}>
                    <MouseoverTooltip info={<>Jump to show detailed AREC NFT info</>}>
                      <ArrowRightCircle size="18" />
                    </MouseoverTooltip>
                  </StyledInternalLink>
                </RowBetween>
                <div style={{ margin: '0.8rem 0.6rem 0.6rem' }}>
                  <ARECSelect
                    size={4}
                    itemSelected={true}
                    value={arecSelected ? arecSelected.toString() : ''}
                    onChange={onARECSelect}
                    fontSize={font16}
                  >
                    {allARECInfo.map((recData: RECData, index) => {
                      const optionTextID = '0000'.concat(allARECNftTokensID[index].toString())
                      return (
                        <ARECOption key={optionTextID} value={index}>
                          {'AREC_'.concat(optionTextID.substring(optionTextID.length - 4)).concat(':')}
                          {'   '}
                          {recPowerList[index]} {`   `} {recStatusList[index]}
                        </ARECOption>
                      )
                    })}
                  </ARECSelect>
                </div>
              </Container>
            </>
          ) : null}
        </StyledPageCard>
      </AppBody>
    </>
  )
}
