import { CurrencyAmount, JSBI, Trade, ETHER, Currency, TradeType, ChainId } from '@arec/sdk'
import { Token, WNATIVE_ADDRESS, Price, Fraction, TokenAmount } from '@arec/sdk'
import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { ArrowDown } from 'react-feather'
//import ReactGA from 'react-ga4'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card from '../../components/Card'
import { AutoColumn } from '../../components/Column'
//import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel, { CurrencySelectPanel } from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween, RowFixed, RowFlat } from '../../components/Row'
//import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
//import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
//import ProgressSteps from '../../components/ProgressSteps'
import PageHeader from '../../components/PageHeader'
//import { TitleLink } from '../../components/Link'
import { TYPE } from '../../theme'
//import {SettingsIcon} from '../../components/Settings'
import { StyledPageCard } from '../../components/earn/styled'
import { M } from '../../components/ARecIssuance' // ExchangeHeadButton

import { INITIAL_ALLOWED_SLIPPAGE, ZERO_ADDRESS } from '../../constants'
import { useWeb3React } from '@web3-react/core'
//import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback, useApproveCallbackFromBank } from '../../hooks/useApproveCallback'
//import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { LinkStyledButton } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import Loader from '../../components/Loader'
//import { CardNoise } from '../../components/earn/styled'
import QuestionHelper from '../../components/QuestionHelper'
import { ONE_OVER_10K_FRACTION } from '../../utils'
import styled from 'styled-components'
import { useGetPortBankInfo } from './PortInfo'
import { useGetArtPaymentInfo, useGetArtSaleInfo } from 'state/port/hooks'
//import flatMap from 'lodash.flatmap'
import { useTestMode, useUserTransactionTTL } from 'state/user/hooks'
//import { useCurrency } from '../../hooks/Tokens'
//import { fetchTokens } from '../../components/SearchModal/filtering'
import { useAllTokens } from '../../hooks/Tokens'
//import { Token } from '@arec/sdk'
import { isAddress } from '../../utils'
import { useMedia } from 'react-use'
import { useDefaultsFromURLSearch } from 'state/swap/hooks'
import TransactionConfirmationModal, { ConfirmationModalContentTitle } from 'components/TransactionConfirmationModal'
import { Container } from '../../components/CurrencyInputPanel'
import { useFontSize } from 'hooks/useWindowSize'
import { BigNumber, utils } from 'ethers'
import { USDC } from '../../constants'
import { DateTime } from 'luxon'
import { splitSignature } from 'ethers/lib/utils' // solidityPack
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useActionBuilderContract } from '../../hooks/useContract'
import { useETHBalances } from '../../state/wallet/hooks'
import { usePermitTokenContract } from '../../hooks/useContract'
import { GetCertificateInfo, ErrorPromptModal, ButtonRow } from '../../components/ARecIssuance'
import { MouseoverTooltip } from '../../components/Tooltip'
import { MessageCircle as HelpCircle, AlertTriangle } from 'react-feather'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { calculateGasMargin } from '../../utils'
import FormattedPriceImpact from 'components/swap/FormattedPriceImpact'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import { tradeMeaningfullyDiffers } from 'components/swap/ConfirmSwapModal'
import { SwapShowAcceptChanges } from 'components/swap/styleds'
//import { SeparatorBlack } from 'components/SearchModal/styleds'
//import { useAtomValue } from 'jotai'
//import { approvalTxHash } from 'hooks/useApproveCallback'
//import { useIsTransactionConfirmed } from 'state/transactions/hooks'

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primary1};
`

export function PortHelpInfo() {
  return (
    <>
      <Text>
        <M>1.</M> Connect your wallet.
      </Text>
      <Text>
        <M>2.</M> Select the ART token you want to offset.
      </Text>
      <Text>
        <M>3.</M> Select the payment token you want to pay for the ART token.
      </Text>
      <Text>
        <M>4.</M> Input either the amount of the token you will pay, or the amount of the ART token you want to offset.
      </Text>
      <Text>
        <M>5.</M> This step is optional, only need to complete while you have not permit AREC Dapp before. Click the
        <M> Approve XXX</M> button to approve AREC dapp transferring your payment token.
      </Text>
      <Text>
        <M>6.a</M> Either click the button <M>Commit Climate Action</M> to register your climate action.
      </Text>
      <Text>
        <M>6.b</M> Or click the button <M>Commit and Mint Climate Badge</M> to register your climate action and mint the
        AREC badge SBT.
      </Text>
    </>
  )
}

//export default function Swap({ history }: RouteComponentProps) {
export default function Port() {
  useDefaultsFromURLSearch(false)
  const below540 = useMedia('(max-width: 540px)')
  const { account, chainId, provider } = useWeb3React()
  const [testMode] = useTestMode()
  const theme = useContext(ThemeContext)

  const addTransaction = useTransactionAdder()
  const actionBuilderContract = useActionBuilderContract(true)
  //const hashKeyARECTokenContract = useHashKeyARECContract(true)

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()
  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  //const approvalTxHashValue = useAtomValue(approvalTxHash)
  // const bApprovalConfirmed = useIsTransactionConfirmed(approvalTxHashValue)

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const { FeswTrade, currencyBalances, parsedAmount, currencies, priceGap, inputError } = useDerivedSwapInfo()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const chainIdMode = chainId ? chainId.toString() + '-' + testMode : undefined

  const payTokenAddr = useMemo(() => {
    if (!chainId || !currencies[Field.INPUT]) return undefined
    if (currencies[Field.INPUT] === ETHER) return WNATIVE_ADDRESS[chainId]
    return (currencies[Field.INPUT] as Token).address
  }, [chainId, currencies])

  const artTokenAddr = useMemo(() => {
    if (!chainId || !currencies[Field.OUTPUT] || currencies[Field.OUTPUT] === ETHER) return undefined
    return (currencies[Field.OUTPUT] as Token).address
  }, [chainId, currencies])

  const portBankInfo = useGetPortBankInfo()
  const artTokenDefault: string | undefined = portBankInfo !== undefined ? portBankInfo.ARTTokens?.[0] : undefined

  const artPaymentInfo = useGetArtPaymentInfo(chainIdMode, artTokenAddr ?? artTokenDefault)

  const artPaymentTokenList: string[] | undefined =
    artPaymentInfo !== undefined ? artPaymentInfo.paymentTokens : undefined

  const allTokens = useAllTokens()

  const ifCelo = chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET

  const allPaymentTokens: Currency[] | undefined = useMemo(() => {
    if (!artPaymentTokenList || artPaymentTokenList.length === 0) return undefined
    return artPaymentTokenList.reduce<Currency[]>(
      (accu, addrPayment) => {
        const addr = isAddress(addrPayment)
        if (!chainId || !addr) return accu
        if (!allTokens[addr]) return accu
        if (!ifCelo && addr.toLowerCase() === WNATIVE_ADDRESS[chainId].toLowerCase()) return accu // mask WMATIC
        accu.push(allTokens[addr])
        return accu
      },
      ifCelo ? [] : [] // Currency.ETHER
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, ifCelo, artPaymentTokenList, allTokens])

  const allARTTokens: Currency[] | undefined = useMemo(() => {
    if (!portBankInfo || !portBankInfo.ARTTokens || portBankInfo.ARTTokens.length === 0) return undefined
    return portBankInfo.ARTTokens.reduce<Currency[]>((accu, addrARTToken) => {
      const addr = isAddress(addrARTToken)
      if (!addr) return accu
      if (!allTokens[addr]) return accu
      accu.push(allTokens[addr])
      return accu
    }, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTokens, portBankInfo])

  //const [modeBank, setModeBank] = useState<boolean>(true)
  const modeBank = true

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  // const { address: recipientAddress } = useENSAddress(recipient)

  const artSaleInfo = useGetArtSaleInfo(chainIdMode, artTokenAddr, payTokenAddr)

  const priceART = useMemo<Price | undefined>(() => {
    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT] || !artSaleInfo) return undefined
    return new Price(currencies[Field.INPUT], currencies[Field.OUTPUT], artSaleInfo.salePrices.toString(), '1000000000')
  }, [currencies, artSaleInfo])

  const trade = showWrap ? undefined : FeswTrade

  const [payAmount, artAmount] = useMemo(() => {
    if (!parsedAmount || !priceART || !currencies[Field.INPUT] || !currencies[Field.OUTPUT])
      return [undefined, undefined]

    if (independentField === Field.INPUT) {
      const payAmount = parsedAmount
      const artAmount: CurrencyAmount = new TokenAmount(
        currencies[Field.OUTPUT] as Token,
        new Fraction(parsedAmount.raw, '1').multiply(priceART).quotient
      )
      return [payAmount, artAmount]
    } else {
      const artAmount = parsedAmount
      const payAmount: CurrencyAmount = new TokenAmount(
        currencies[Field.INPUT] as Token,
        new Fraction(parsedAmount.raw, '1').multiply(priceART.invert()).quotient
      )
      return [payAmount, artAmount]
    }
  }, [independentField, parsedAmount, priceART, currencies])

  const parsedAmounts = useMemo(() => {
    return showWrap
      ? {
          [Field.INPUT]: parsedAmount,
          [Field.OUTPUT]: parsedAmount
        }
      : modeBank
      ? {
          [Field.INPUT]: payAmount,
          [Field.OUTPUT]: artAmount
        }
      : {
          [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
          [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
        }
  }, [showWrap, modeBank, parsedAmount, trade, payAmount, artAmount, independentField])

  const reserveART = useMemo(() => {
    if (!modeBank || !artPaymentInfo || !currencies[Field.OUTPUT]) return undefined
    const totalReserve = artPaymentInfo.totalAmountDeposit - artPaymentInfo.totalAmountSold

    const reserveART: CurrencyAmount = new TokenAmount(currencies[Field.OUTPUT] as Token, totalReserve)
    return reserveART
  }, [modeBank, currencies, artPaymentInfo])

  const swapInputError = useMemo(() => {
    if (!parsedAmounts[Field.OUTPUT] || !reserveART) return inputError
    if (reserveART.lessThan(parsedAmounts[Field.OUTPUT])) return 'Liquidity Not Enough'
    return inputError
  }, [parsedAmounts, reserveART, inputError])

  const { onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const oneCurrencyIsETH = currencies[Field.INPUT] === ETHER || currencies[Field.OUTPUT] === ETHER

  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  // modal and loading
  const [{ showConfirm, txnToConfirm, errorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: Trade | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromBank(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval])

  const [approvalBank, approveBankCallback] = useApproveCallback(payAmount, actionBuilderContract?.address)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalBankSubmitted, setApprovalBankSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  useEffect(() => {
    if (approvalBank === ApprovalState.PENDING) {
      setApprovalBankSubmitted(true)
    }
  }, [approvalBank])

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      setSignatureData(undefined)
      setApprovalSubmitted(false)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
      setSignatureData(undefined)
      setApprovalSubmitted(false)
    },
    [onUserInput]
  )

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const [singleHopOnly] = useUserSingleHopOnly()

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(trade?.priceImpact)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(!modeBank && priceImpactSeverity > 3 && !isExpertMode)

  const showApproveBankFlow =
    !swapInputError &&
    (approvalBank === ApprovalState.NOT_APPROVED || approvalBank === ApprovalState.PENDING || approvalBankSubmitted)

  const handleConfirmDismiss = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
    setARECTxnState({ showConfirm: false, txnToConfirm, attemptingTxn, errorMessage, txHash: undefined })
  }, [attemptingTxn, onUserInput, errorMessage, txnToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setARECTxnState({ txnToConfirm: trade, errorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, errorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      setSignatureData(undefined)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
    setApprovalSubmitted(false)
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
    },
    [onCurrencySelection]
  )

  const [nameCertOwner, setNameCertOwner] = useState<string>('')
  const [beneficiary, setBeneficiary] = useState<string>(ZERO_ADDRESS)
  const [nameBeneficiary, setNameBeneficiary] = useState<string>('')
  const [memoCertificate, setMemoCertificate] = useState<string>('')

  const ethBalance = useETHBalances(account ? [account] : [])

  const ifEmptyWallet = useMemo(() => {
    if (!ethBalance || !account || !ethBalance[account]) return false
    return ethBalance[account]?.equalTo('0') ? true : false
  }, [ethBalance, account])

  /*  
  const ifMoreInput = useMemo(() => {
    if (!approvalAmount || !arkreenBalance) return false
    if (approvalAmount.greaterThan(arkreenBalance)) return true
    return false
  }, [approvalAmount, arkreenBalance])
*/

  const errorAddress = useMemo(() => {
    const address = isAddress(beneficiary)
    const error = Boolean(!address && beneficiary !== ZERO_ADDRESS && beneficiary !== '')
    return error
  }, [beneficiary])

  const [signatureData, setSignatureData] = useState<
    { v: number; r: string; s: string; deadline: number } | undefined
  >()
  const [isApproving] = useState<boolean>(false) // setIsApproving
  const deadline = useTransactionDeadline() // custom from users settings
  const [ttl] = useUserTransactionTTL()
  const payToken = usePermitTokenContract(payTokenAddr)

  async function onAttemptToApprove() {
    if (!payToken || !provider || !deadline || !ttl) return
    if (!parsedAmounts[Field.INPUT] || !chainId || !actionBuilderContract) return

    const handlerError = (error: any) => {
      const dataMsg = error?.data?.message
        ? ' Details: ' + error.data.message
        : error?.reason ?? error?.message ?? error?.code

      const dataMsgStr = dataMsg.charAt(0).toUpperCase() + dataMsg.slice(1)

      setARECTxnState({
        attemptingTxn: false,
        txnToConfirm,
        showConfirm,
        errorMessage: dataMsgStr,
        txHash: undefined
      })
    }

    const approve = async () => {
      let callback
      if (modeBank) {
        callback = approveBankCallback
      } else {
        callback = approveCallback
      }

      callback()
        .then(() => {
          setIsWaitingWallet(false)
        })
        .catch(error => {
          handlerError(error)
          setIsWaitingWallet(false)
        })
    }

    setIsWaitingWallet(true)

    // try to gather a signature for permission
    let nonce
    let name
    try {
      nonce = await payToken.nonces(account)
      name = await payToken.name()
    } catch (error) {
      await approve()
      return
    }

    const payAmountString = parsedAmounts[Field.INPUT].raw.toString()
    const spender = actionBuilderContract.address

    let EIP712Domain: any
    let domain: any

    if (payToken.address === USDC[chainId].address && chainId == ChainId.MATIC) {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'verifyingContract', type: 'address' },
        { name: 'salt', type: 'bytes32' }
      ]
      domain = {
        name: name,
        version: '1',
        verifyingContract: payToken.address,
        salt: utils.hexZeroPad(utils.hexlify(chainId), 32)
      }
    } else if (payToken.address === USDC[chainId].address && chainId == ChainId.MATIC_AMOY_TESTNET) {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ]
      domain = {
        name: name,
        version: '2',
        chainId: chainId,
        verifyingContract: payToken.address
      }
    } else {
      EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ]
      domain = {
        name: name,
        version: '1',
        chainId: chainId,
        verifyingContract: payToken.address
      }
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    //const nowDeadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const nowDeadline = Math.floor(DateTime.now().toSeconds() + 3600 * 24) // 24 hours
    const usedDeadline = deadline.lt(nowDeadline) ? nowDeadline : deadline.toNumber()

    const message = {
      owner: account,
      spender: spender,
      value: payAmountString,
      nonce: nonce.toHexString(),
      deadline: usedDeadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    provider
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: usedDeadline
        })
        setIsWaitingWallet(false)
      })
      .catch(async error => {
        if (error?.code === 4001) {
          handlerError(error)
          setIsWaitingWallet(false)
        } else {
          await approve()
        }
      })
  }

  async function handlerActionBuilderNative() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]

    if (!actionBuilderContract || !inputAmount || !outputAmount || outputAmount?.equalTo('0')) return

    const amountPay = inputAmount.raw.toString()
    const tokenART = (outputAmount.currency as Token).address
    const amountART = outputAmount.raw.toString()
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)
    const deadline = Math.floor(DateTime.now().toSeconds() + ttl)

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilderNative'](tokenART, amountART, BANK_MODE, deadline, {
      value: amountPay
    })
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilderNative(tokenART, amountART, BANK_MODE, deadline, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            value: amountPay
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handlerActionBuilder() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]

    if (!actionBuilderContract || !currencies[Field.OUTPUT] || !payToken || !inputAmount) return
    if (!outputAmount || outputAmount?.equalTo('0')) return

    const tokenPay = payToken.address
    const tokenART = (outputAmount.currency as Token).address
    const amountPay = inputAmount.raw.toString()
    const amountART = outputAmount.raw.toString()
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)
    const deadline = Math.floor(DateTime.now().toSeconds() + ttl)

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilder'](
      tokenPay,
      tokenART,
      amountPay,
      amountART,
      BANK_MODE,
      deadline
    )
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilder(tokenPay, tokenART, amountPay, amountART, BANK_MODE, deadline, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handlerActionBuilderWithPermit() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]

    if (!actionBuilderContract || !payToken || !inputAmount) return
    if (!signatureData || !outputAmount || outputAmount?.equalTo('0')) return

    const tokenART = (outputAmount.currency as Token).address
    const offsetValue = BigNumber.from(outputAmount.raw.toString())
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)

    const signatureToPay = [
      payToken.address,
      inputAmount.raw.toString(),
      signatureData.deadline,
      signatureData.v,
      signatureData.r,
      signatureData.s
    ]

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilderWithPermit'](tokenART, offsetValue, BANK_MODE, signatureToPay)
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilderWithPermit(tokenART, offsetValue, BANK_MODE, signatureToPay, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            setSignatureData(undefined)
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handlerActionBuilderBadgeNative() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]
    if (!actionBuilderContract || !inputAmount || !outputAmount || outputAmount?.equalTo('0')) return

    const amountPay = inputAmount.raw.toString()
    const tokenART = (outputAmount.currency as Token).address
    const amountART = outputAmount.raw.toString()
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)
    const deadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const badgeInfo = [beneficiary, nameCertOwner, nameBeneficiary, memoCertificate]

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilderBadgeNative'](
      tokenART,
      amountART,
      BANK_MODE,
      deadline,
      badgeInfo,
      {
        value: amountPay
      }
    )
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilderBadgeNative(tokenART, amountART, BANK_MODE, deadline, badgeInfo, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            value: amountPay
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handlerActionBuilderBadge() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]

    if (!actionBuilderContract || !currencies[Field.OUTPUT] || !payToken || !inputAmount) return
    if (!outputAmount || outputAmount?.equalTo('0')) return

    const tokenPay = payToken.address
    const tokenART = (outputAmount.currency as Token).address
    const amountPay = inputAmount.raw.toString()
    const amountART = outputAmount.raw.toString()
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)
    const deadline = Math.floor(DateTime.now().toSeconds() + ttl)
    const badgeInfo = [beneficiary, nameCertOwner, nameBeneficiary, memoCertificate]

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilderBadge'](
      tokenPay,
      tokenART,
      amountPay,
      amountART,
      BANK_MODE,
      deadline,
      badgeInfo
    )
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilderBadge(tokenPay, tokenART, amountPay, amountART, BANK_MODE, deadline, badgeInfo, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handleActionBuilderBadgeWithPermit() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]
    if (!actionBuilderContract || !payToken || !inputAmount) return
    if (!signatureData || !outputAmount || outputAmount?.equalTo('0')) return

    const tokenART = (outputAmount.currency as Token).address
    const offsetValue = BigNumber.from(outputAmount.raw.toString())
    const BANK_MODE = (modeBank ? 0x02 : 0x00) + (independentField === Field.OUTPUT ? 0x00 : 0x01)

    const badgeInfo = [beneficiary, nameCertOwner, nameBeneficiary, memoCertificate]

    const signatureToPay = [
      payToken.address,
      inputAmount.raw.toString(),
      signatureData.deadline,
      signatureData.v,
      signatureData.r,
      signatureData.s
    ]

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await actionBuilderContract.estimateGas['actionBuilderBadgeWithPermit'](
      tokenART,
      offsetValue,
      BANK_MODE,
      badgeInfo,
      signatureToPay
    )
      .then(async estimatedGasLimit => {
        await actionBuilderContract
          .actionBuilderBadgeWithPermit(tokenART, offsetValue, BANK_MODE, badgeInfo, signatureToPay, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Offset ART token: ${outputAmount.toSignificant(4)} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            handleTypeInput('')
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Commit Climate Action: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Commit Climate Action: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Commit Climate Action tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  const { font14, font20 } = useFontSize()

  function modalHeader() {
    const inputAmount = parsedAmounts[Field.INPUT]
    const outputAmount = parsedAmounts[Field.OUTPUT]

    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
          <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
            <RowBetween align="center" height="20px">
              <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                Amount to Pay:
              </Text>

              <Text
                fontWeight={700}
                fontSize={font14}
                color={
                  !modeBank && showAcceptChanges && trade?.tradeType === TradeType.EXACT_OUTPUT
                    ? theme.red2
                    : theme.primary1
                }
              >
                {`${inputAmount?.toSignificant(6)} ${inputAmount?.currency.getSymbol(chainId)}`}
              </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  ART Amount to Offset:
                </Text>
              </RowFixed>

              <Text
                lineHeight={'24px'}
                fontWeight={700}
                fontSize={font14}
                color={
                  !modeBank && showAcceptChanges && trade?.tradeType === TradeType.EXACT_INPUT
                    ? theme.red2
                    : theme.primary1
                }
              >
                {`${outputAmount?.toSignificant(6)} ${outputAmount?.currency.getSymbol(chainId)}`}
              </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  ART Selling Price:
                </Text>
              </RowFixed>
              <TradePrice
                price={modeBank ? priceART : trade?.executionPrice}
                showInverted={showInverted}
                setShowInverted={setShowInverted}
              />
            </RowBetween>
          </AutoColumn>
        </Container>
        {showAcceptChanges && !modeBank && (
          <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
            <RowBetween>
              <RowFixed>
                <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} />
                <TYPE.Main color={theme.primary1}> Price Updated</TYPE.Main>
              </RowFixed>
              <RowFlat>
                <ButtonPrimary
                  style={{ padding: '.5rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '8px' }}
                  onClick={handleConfirmDismiss}
                >
                  Dismiss
                </ButtonPrimary>
                <ButtonPrimary
                  style={{
                    marginLeft: '0.6rem',
                    padding: '.5rem',
                    width: 'fit-content',
                    fontSize: '0.825rem',
                    borderRadius: '8px'
                  }}
                  onClick={handleAcceptChanges}
                >
                  Accept
                </ButtonPrimary>
              </RowFlat>
            </RowBetween>
          </SwapShowAcceptChanges>
        )}
        <GetCertificateInfo
          certOwner={nameCertOwner}
          beneficiary={beneficiary}
          nameBeneficiary={nameBeneficiary}
          memoCertificate={memoCertificate}
          setCertOwner={setNameCertOwner}
          setBeneficiary={setBeneficiary}
          setNameBeneficiary={setNameBeneficiary}
          setMemoCertificate={setMemoCertificate}
        />
      </AutoColumn>
    )
  }

  const attemptString = `All the ART tokens you get will be offset as a climate action, and a climate badge will 
  be minted for you.`

  function modalBottom() {
    return (
      <RowBetween>
        <ButtonError
          disabled={errorAddress || showAcceptChanges}
          onClick={() => {
            if (
              currencies[Field.INPUT]?.getSymbol() === 'ETH' ||
              (modeBank &&
                chainId &&
                !ifCelo &&
                (currencies[Field.INPUT] as Token).address === WNATIVE_ADDRESS[chainId])
            ) {
              handlerActionBuilderBadgeNative()
            } else if (signatureData === undefined) {
              handlerActionBuilderBadge()
            } else {
              handleActionBuilderBadgeWithPermit()
            }
          }}
          id="liquidize-button"
        >
          <Text fontSize={font20} fontWeight={500}>
            {!errorAddress ? `Commit and Mint Climate Badge` : `Wrong Beneficiary Address`}
          </Text>
        </ButtonError>
      </RowBetween>
    )
  }

  const showAcceptChanges = useMemo(
    () => Boolean(trade && txnToConfirm && tradeMeaningfullyDiffers(trade, txnToConfirm)),
    [txnToConfirm, trade]
  )

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  const HelpForMint1 = (
    <>
      One offset action will be created by offsetting the ART tokens you have paid with the payment token. With any
      offset actions, you could freely mint an AREC Badge SBT at anytime later.
    </>
  )

  const HelpForMint2 = (
    <>
      One offset action will be created by offsetting the ART tokens you have paid with the payment tokens. Based on
      this offset action, an AREC Badge SBT will be minted at the same time.
    </>
  )

  /*
  function ModeOption() {
    return (
      <RowBetween align="center" style={{ height: '20px', padding: '0 20%', margin: '-4px 0px 4px' }}>
        <label>
          <input
            type="radio"
            id="AREC_BANK"
            name="ModeOption"
            checked={modeBank}
            onChange={() => {
              setModeBank(true)
            }}
          />
          Via Bank
        </label>
        <label>
          <input
            type="radio"
            id="AREC_DEX"
            name="ModeOption"
            checked={!modeBank}
            onChange={() => {
              setModeBank(false)
            }}
          />
          Via DEX
        </label>
      </RowBetween>
    )
  }
  */

  // <ExchangeHeadButton path={'AREC Port'} />

  /*
  {(chainId === ChainId.MATIC || chainId === ChainId.MATIC_TESTNET || chainId === ChainId.CELO_TESTNET) && (
    <>
      <ModeOption />
      <SeparatorBlack style={{ margin: '0 4rem 0.5rem', height: '2px', backgroundColor: theme.bg3 }} />
    </>
  )}
  */

  return (
    <>
      <AppBody style={{ marginTop: below540 ? '30px' : '60px' }}>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={'AREC Port'}>
            <QuestionHelper bkgOff={true} text={'AREC Port Guidance:'} info={<PortHelpInfo />} />
          </PageHeader>

          <Wrapper id="swap-page">
            <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
            {modeBank ? (
              <TransactionConfirmationModal
                isOpen={showConfirm && !errorMessage}
                onDismiss={handleConfirmDismiss}
                attemptingTxn={attemptingTxn}
                hash={txHash ? txHash : ''}
                content={() => (
                  <ConfirmationModalContentTitle
                    title={'You will commit'}
                    onDismiss={handleConfirmDismiss}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )}
                pendingText={attemptString}
                pendingTitle={'Commit and Mint Badge'}
                submittedTitle={'Commit and Mint Badge Submitted'}
              />
            ) : (
              <TransactionConfirmationModal
                isOpen={showConfirm && !errorMessage}
                onDismiss={handleConfirmDismiss}
                attemptingTxn={attemptingTxn}
                hash={txHash ? txHash : ''}
                content={() => (
                  <ConfirmationModalContentTitle
                    title={'You will commit'}
                    onDismiss={handleConfirmDismiss}
                    topContent={modalHeader}
                    bottomContent={modalBottom}
                  />
                )}
                pendingText={attemptString}
                pendingTitle={'Commit and Mint Badge'}
                submittedTitle={'Commit and Mint Badge Submitted'}
              />
            )}

            <AutoColumn gap={'md'}>
              {modeBank ? (
                <CurrencySelectPanel
                  label={'PAY'}
                  field={'INPUT'}
                  value={formattedAmounts[Field.INPUT]}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  currencyOptions={allPaymentTokens}
                  id="buy-ART-input"
                />
              ) : (
                <CurrencyInputPanel
                  label={independentField === Field.OUTPUT && !showWrap && trade ? 'PAY (estimated)' : 'PAY'}
                  value={formattedAmounts[Field.INPUT]}
                  exactValue={independentField === Field.OUTPUT ? parsedAmounts[Field.INPUT]?.toExact() : undefined}
                  showMaxButton={!atMaxAmountInput}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  showCommonBases={true}
                  id="swap-currency-input"
                />
              )}
              <AutoColumn justify="space-between">
                <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                  <ArrowDown size="16" color={theme.text2} />
                </AutoRow>
              </AutoColumn>
              <CurrencySelectPanel
                label={!modeBank && independentField === Field.INPUT ? 'OFFSET (estimated)' : 'OFFSET'}
                field={'OUTPUT'}
                value={formattedAmounts[Field.OUTPUT]}
                showMaxButton={false}
                currency={currencies[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                onCurrencySelect={handleOutputSelect}
                currencyOptions={allARTTokens}
                id="buy-ART-output"
              />

              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.text2} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      - Remove receiver
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}

              {showWrap || (modeBank && !reserveART) || (!modeBank && !Boolean(trade)) ? null : (
                <Card padding={'0.5rem .75rem'} backgroundColor={theme.bg2} borderRadius={'8px'}>
                  <AutoColumn gap="4px">
                    {(Boolean(trade) || (modeBank && priceART)) && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            {modeBank ? `ART Selling Price:` : `Swap Price`}
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>This ART sales price is defined by the AREC Bank contract.</>}
                          />
                        </RowFixed>
                        <TradePrice
                          price={modeBank ? priceART : trade?.executionPrice}
                          showInverted={showInverted}
                          setShowInverted={setShowInverted}
                        />
                      </RowBetween>
                    )}
                    {!modeBank && allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                      <RowBetween height={'22px'} align="center">
                        <ClickableText fontWeight={700} fontSize={14} color={theme.text1} onClick={toggleSettings}>
                          Slippage Tolerance
                        </ClickableText>
                        <ClickableText fontWeight={700} fontSize={14} color={theme.text1} onClick={toggleSettings}>
                          {allowedSlippage / 100}%
                        </ClickableText>
                      </RowBetween>
                    )}
                    {!modeBank && priceGap && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            Pool Price Deviation
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={
                              <>
                                This is the token price deviation between the two correlated unidirectional liquidity
                                pools (sub-pools). Once this deviation exceeds a preset percentage (1.0% by default), an
                                internal token exchange between the two sub-pools will take place to eliminate the price
                                deviation.
                              </>
                            }
                          />
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.primary1}>
                          {priceGap.lessThan(ONE_OVER_10K_FRACTION) ? '< 0.01' : priceGap.toSignificant(4)}%
                        </Text>
                      </RowBetween>
                    )}

                    {!modeBank && trade && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontSize={14} fontWeight={500} color={theme.text2}>
                            {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum Received' : 'Maximum Pay'}
                            <QuestionHelper
                              bkgOff={true}
                              small={'s'}
                              info={
                                <>
                                  Your transaction will revert if there is a large, unfavorable price movement before it
                                  is confirmed.
                                </>
                              }
                            />
                          </Text>
                        </RowFixed>
                        <RowFixed>
                          <Text fontWeight={700} color={theme.primary1} fontSize={14}>
                            {trade.tradeType === TradeType.EXACT_INPUT
                              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(
                                  4
                                )} ${trade.outputAmount.currency.getSymbol(chainId)}` ?? '-'
                              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(
                                  4
                                )} ${trade.inputAmount.currency.getSymbol(chainId)}` ?? '-'}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                    )}

                    {!modeBank && Boolean(trade) && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontSize={14} fontWeight={500} color={theme.text2}>
                            Price Impact
                            <QuestionHelper
                              bkgOff={true}
                              small={'s'}
                              info={<>The difference between the market price and estimated price due to trade size.</>}
                            />
                          </Text>
                        </RowFixed>
                        <FormattedPriceImpact priceImpact={trade?.priceImpact} />
                      </RowBetween>
                    )}

                    {modeBank && !!reserveART && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            AREC Bank Reserve:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={
                              <>
                                This represents the total inventory of ART tokens available for sale within the AREC
                                Bank smart contract.
                              </>
                            }
                          />
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.primary1}>
                          {`${reserveART.toSignificant(6)} ${reserveART.currency.getSymbol(chainId)}`}
                        </Text>
                      </RowBetween>
                    )}

                    {modeBank && !!payAmount && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            Amount to Pay:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>Amount of the payment tokens you will pay to offset ART tokens.</>}
                          />
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.primary1}>
                          {`${payAmount.toSignificant(6)} ${payAmount.currency.getSymbol(chainId)}`}
                        </Text>
                      </RowBetween>
                    )}

                    {modeBank && !!artAmount && (
                      <RowBetween height={'22px'} align="center">
                        <RowFixed>
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            ART Amount to Offset:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>Amount of the ART tokens you will offset.</>}
                          />
                        </RowFixed>
                        <Text fontWeight={700} fontSize={14} color={theme.primary1}>
                          {`${artAmount.toSignificant(6)} ${artAmount.currency.getSymbol(chainId)}`}
                        </Text>
                      </RowBetween>
                    )}
                  </AutoColumn>
                </Card>
              )}
            </AutoColumn>
            <BottomGrouping>
              {modeBank &&
                (!account ? (
                  <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                ) : (
                  <AutoColumn gap="8px">
                    {(showApproveBankFlow || swapInputError) && (
                      <ButtonConfirmed
                        onClick={() => onAttemptToApprove()}
                        disabled={
                          isWaitingWallet ||
                          signatureData !== undefined ||
                          isApproving ||
                          !!swapInputError ||
                          ifEmptyWallet ||
                          (approvalBankSubmitted && approvalBank === ApprovalState.PENDING) ||
                          (approvalBankSubmitted && approvalBank === ApprovalState.APPROVED)
                        }
                        padding={'18px 0px'}
                      >
                        <Text fontSize={font20} fontWeight={500}>
                          {!!swapInputError
                            ? swapInputError
                            : ifEmptyWallet
                            ? 'Wallet Empty'
                            : signatureData !== undefined || approvalBank === ApprovalState.APPROVED
                            ? `${payAmount?.currency.getSymbol(chainId)} Approved`
                            : approvalBankSubmitted && approvalBank === ApprovalState.PENDING
                            ? `Approving ${payAmount?.currency.getSymbol(chainId)} `
                            : `Approve ${payAmount?.currency.getSymbol(chainId)} `}
                        </Text>
                      </ButtonConfirmed>
                    )}

                    {!swapInputError && (
                      <ButtonError
                        disabled={
                          isWaitingWallet ||
                          showConfirm ||
                          (approvalBank !== ApprovalState.APPROVED && signatureData === undefined)
                        }
                        onClick={() => {
                          if (
                            currencies[Field.INPUT]?.getSymbol() === 'ETH' ||
                            (chainId &&
                              !ifCelo &&
                              (currencies[Field.INPUT] as Token).address === WNATIVE_ADDRESS[chainId])
                          ) {
                            handlerActionBuilderNative()
                          } else if (signatureData === undefined) {
                            handlerActionBuilder()
                          } else {
                            handlerActionBuilderWithPermit()
                          }
                        }}
                        id="redeem-button"
                      >
                        <ButtonRow>
                          <div />
                          <Text fontSize={font20} fontWeight={500}>
                            Commit Climate Action
                          </Text>
                          {below540 ? (
                            <div />
                          ) : (
                            <div style={{ fontWeight: 'lighter' }}>
                              <MouseoverTooltip info={HelpForMint1}>
                                <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                              </MouseoverTooltip>
                            </div>
                          )}
                        </ButtonRow>
                      </ButtonError>
                    )}

                    {!swapInputError && (
                      <ButtonError
                        disabled={
                          isWaitingWallet ||
                          showConfirm ||
                          (approvalBank !== ApprovalState.APPROVED && signatureData === undefined)
                        }
                        onClick={() =>
                          setARECTxnState({
                            attemptingTxn: false,
                            txnToConfirm,
                            showConfirm: true,
                            errorMessage: undefined,
                            txHash: undefined
                          })
                        }
                        id="redeem-button"
                      >
                        <ButtonRow>
                          <div />
                          <Text fontSize={font20} fontWeight={500}>
                            Commit and Mint Climate Badge
                          </Text>
                          {below540 ? (
                            <div />
                          ) : (
                            <div style={{ fontWeight: 'lighter' }}>
                              <MouseoverTooltip info={HelpForMint2}>
                                <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                              </MouseoverTooltip>
                            </div>
                          )}
                        </ButtonRow>
                      </ButtonError>
                    )}
                  </AutoColumn>
                ))}

              {!modeBank &&
                (!account ? (
                  <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                ) : showWrap ? (
                  <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </ButtonPrimary>
                ) : !!swapInputError ? (
                  <ButtonPrimary disabled={true}>
                    <Text fontSize={font20} fontWeight={500}>
                      {swapInputError}
                    </Text>
                  </ButtonPrimary>
                ) : priceImpactSeverity > 3 ? (
                  <ButtonError disabled={true} error={true}>
                    <Text fontSize={font20} fontWeight={500}>
                      Price Impact Too High
                    </Text>
                  </ButtonError>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <ButtonError disabled={true} error={false}>
                    <Text fontSize={20} fontWeight={500}>
                      {oneCurrencyIsETH
                        ? `Swap Pair Not Created`
                        : singleHopOnly
                        ? `Try to Enable Multi-hop`
                        : `No Swap Router Available`}
                    </Text>
                  </ButtonError>
                ) : (
                  <AutoColumn gap="8px">
                    {showApproveFlow && (
                      <ButtonConfirmed
                        fontSize={font20}
                        fontWeight={500}
                        onClick={() => onAttemptToApprove()}
                        disabled={
                          isWaitingWallet ||
                          signatureData !== undefined ||
                          approval !== ApprovalState.NOT_APPROVED ||
                          approvalSubmitted
                        }
                        altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                        confirmed={approval === ApprovalState.APPROVED}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          currencies[Field.INPUT]?.getSymbol(chainId) + ' Approved'
                        ) : (
                          'Approve ' + currencies[Field.INPUT]?.getSymbol(chainId)
                        )}
                      </ButtonConfirmed>
                    )}
                    <ButtonError
                      onClick={() => {
                        if (currencies[Field.INPUT]?.getSymbol() === 'ETH') {
                          handlerActionBuilderNative()
                        } else if (signatureData === undefined) {
                          handlerActionBuilder()
                        } else {
                          handlerActionBuilderWithPermit()
                        }
                      }}
                      id="swap-button"
                      disabled={
                        !isValid ||
                        isWaitingWallet ||
                        (approval !== ApprovalState.APPROVED && signatureData === undefined) ||
                        !!swapCallbackError
                      }
                      error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                    >
                      <ButtonRow>
                        <div />
                        <Text fontSize={font20} fontWeight={500}>
                          {priceImpactSeverity > 2 ? 'Commit Action Anyway' : 'Commit Climate Action'}
                        </Text>
                        {below540 ? (
                          <div />
                        ) : (
                          <div style={{ fontWeight: 'lighter' }}>
                            <MouseoverTooltip info={HelpForMint1}>
                              <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                            </MouseoverTooltip>
                          </div>
                        )}
                      </ButtonRow>
                    </ButtonError>
                    <ButtonError
                      onClick={() => {
                        setARECTxnState({
                          txnToConfirm: trade,
                          attemptingTxn: false,
                          errorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }}
                      id="swap-button"
                      disabled={
                        !isValid ||
                        isWaitingWallet ||
                        (approval !== ApprovalState.APPROVED && signatureData === undefined) ||
                        !!swapCallbackError
                      }
                      error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                    >
                      <ButtonRow>
                        <div />
                        <Text fontSize={font20} fontWeight={500}>
                          {priceImpactSeverity > 2 ? 'Commit and Mint Badge Anyway' : 'Commit and Mint Climate Badge'}
                        </Text>
                        {below540 ? (
                          <div />
                        ) : (
                          <div style={{ fontWeight: 'lighter' }}>
                            <MouseoverTooltip info={HelpForMint2}>
                              <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                            </MouseoverTooltip>
                          </div>
                        )}
                      </ButtonRow>
                    </ButtonError>
                  </AutoColumn>
                ))}
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}
