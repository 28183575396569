import { FACTORY_ADDRESS, ROUTER_ADDRESS, STAKE_FACTORY_ADDRESS, Token } from '@arec/sdk'
import { Contract } from '@ethersproject/contracts'
import FeswGovernorJson from '@feswap/governance/build/FeswGovernor.json'
import FeswSponsorJson from '@feswap/governance/build/FeswSponsor.json'
import StakingTwinRewardsFactoryJson from '@feswap/governance/build/StakingTwinRewardsFactory.json'
import FeswJson from '@feswap/governance/build/Fesw.json'
import IFeswaNFTJson from '@feswap/governance/build/IFeswaNFT.json'
import FeSwapFactoryJson from '@feswap/core/build/FeSwapFactory.json'
import FeSwapRouterJson from '@feswap/core/build/FeSwapRouter.json'
import StakingTwinRewardsJson from '@feswap/governance/build/StakingTwinRewards.json'
import MerkleDistributorJson from '@feswap/governance/build/MerkleDistributor.json'
import { ChainId, WETH, NFT_BID_ADDRESS, GOVERNANCE_ADDRESS, SPONSOR_ADDRESS } from '@arec/sdk'
import IFeSwapPairJson from '@feswap/core/build/IFeSwapPair.json'
import { useMemo } from 'react'
import { MERKLE_DISTRIBUTOR_ADDRESS, FESW } from '../constants'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import arkreenTokenABI from '../constants/abis/ArkreenToken.json'
import abiArkreenRECIssuance from '../constants/abis/ArkreenRECIssuance.json'
import abiArkreenRECToken from '../constants/abis/ArkreenRECToken.json'
import abiArkreenRetirement from '../constants/abis/ArkreenBadge.json'
import abiArkreenRegistry from '../constants/abis/ArkreenRegistry.json'
import abiArkreenMiner from '../constants/abis/ArkreenMiner.json'
import ActionBuilder_ABI from '../constants/abis/ArkreenBuilder.json'
import HashKeyESG_ABI from '../constants/abis/HashKeyESGBTC.json'
import HART_BANK_ABI from '../constants/abis/ArkreenRECBank.json'

import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { useWeb3React } from '@web3-react/core'
import { useGetTestMode } from '../state/user/hooks'
import { ZERO_ADDRESS } from '../constants'

const { abi: GOVERNANCE_ABI } = FeswGovernorJson
const { abi: SPONSOR_ABI } = FeswSponsorJson
const { abi: STAKE_REWARD_FACTORY_ABI } = StakingTwinRewardsFactoryJson
const { abi: FESW_ABI } = FeswJson
const { abi: NFT_BID_ABI } = IFeswaNFTJson
const { abi: NFT_FACTORY_ABI } = FeSwapFactoryJson
const { abi: NFT_ROUTER_ABI } = FeSwapRouterJson
const { abi: STAKING_REWARDS_ABI } = StakingTwinRewardsJson
const { abi: MERKLE_DISTRIBUTOR_ABI } = MerkleDistributorJson
const { abi: IFeSwapPair } = IFeSwapPairJson

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { provider, account } = useWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !provider) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, provider, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? WETH[chainId as ChainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.GÖRLI:
      case ChainId.ROPSTEN:
      case ChainId.RINKEBY:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IFeSwapPair, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? MULTICALL_NETWORKS[chainId as ChainId] : undefined, MULTICALL_ABI, true)
}

export function useMerkleDistributorContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? MERKLE_DISTRIBUTOR_ADDRESS[chainId] : undefined, MERKLE_DISTRIBUTOR_ABI, true)
}

export function useGovernanceContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(GOVERNANCE_ADDRESS[((chainId ?? ChainId.MAINNET) as ChainId) as ChainId], GOVERNANCE_ABI, true)
}

export function useFeswContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? FESW[chainId].address : undefined, FESW_ABI, true)
}

export function useFeswFactoryContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? FACTORY_ADDRESS[chainId as ChainId] : undefined, NFT_FACTORY_ABI, true)
}

export function useFeswRouterContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(chainId ? ROUTER_ADDRESS[chainId as ChainId] : undefined, NFT_ROUTER_ABI, true)
}

export function useSponsorContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(SPONSOR_ADDRESS[((chainId ?? ChainId.MAINNET) as ChainId) as ChainId], SPONSOR_ABI, true)
}

export function useStakingFactoryContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(STAKE_FACTORY_ADDRESS[(chainId ?? ChainId.MAINNET) as ChainId], STAKE_REWARD_FACTORY_ABI, true)
}

export function useNftBidContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(NFT_BID_ADDRESS[((chainId ?? ChainId.MAINNET) as ChainId) as ChainId], NFT_BID_ABI, true)
}

export function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
}

export function feswType(chainId?: ChainId): string {
  if (
    chainId === ChainId.MAINNET ||
    chainId === ChainId.ROPSTEN ||
    chainId === ChainId.RINKEBY ||
    chainId === ChainId.GÖRLI ||
    chainId === ChainId.KOVAN
  )
    return 'FESW'
  return 'FESW-V2'
}

export function useSocksController(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}

export const defaultIssuerAddressCeloTest = '0x576Ab950B8B3B18b7B53F7edd8A47986a44AE6F4'
//export const arkreenTokenAddressCeloTest  = '0x54E1c534F59343C56549C76D1bDccC8717129832'
export const arkreenIssuanceAddressCeloTest = '0x66e9c20DE3711e7C8c886d461aACd6E092E161BE'
export const arkreenRetirementAddressCeloTest = '0x9b5EE14b0B23876F39747747b227dDe12B62143d'
export const ARECTokenAddressCeloTest = '0x57Fe6324538CeDd43D78C975118Ecf8c137fC8B2'
//export const minerContractAddressCeloTest   = '0xB8663EdC9929D9135E7f6D50f7d3A97862554a72'
export const arkreenRegistryAddressCeloTest = '0x572e9B8B210414b2D76ddf578925D769D96982E6'
export const actionBuilderAddressCeloTest = '0xAC0B2E90b41a1b85520607e60dEf18B59e5a1c9F'
export const HashKeyARECTokenAddressCeloTest = '0x57Fe6324538CeDd43D78C975118Ecf8c137fC8B2'
export const HARTBankAddressCeloTest = '0x827155A6fD0aac8AbE7beb4Ee1a95143255ed438'
export const NativeAssetAddressCeloTest = '0xF194afDf50B03e69Bd7D057c1Aa9e10c9954E4C9'

export const defaultIssuerAddressCelo = '0xaa65582453e121d463A51251E9d8C2BAd27ad99c'
//export const arkreenTokenAddressCelo  = '0x54E1c534F59343C56549C76D1bDccC8717129832'
export const arkreenIssuanceAddressCelo = '0xbB4b287Fdd601662eCf17fB6EDF3943A15D1b63e'
export const arkreenRetirementAddressCelo = '0x5EfbbB0a60110cCda7342A7230A32A4E78815f76'
export const ARECTokenAddressCelo = '0x9BBF9f544F3ceD640090f43FF6B820894f66Aaef'
//export const minerContractAddressCelo   = '0xB8663EdC9929D9135E7f6D50f7d3A97862554a72'
export const arkreenRegistryAddressCelo = '0x960C67B8526E6328b30Ed2c2fAeA0355BEB62A83'
export const actionBuilderAddressCelo = '0x3d5531cF0bC2e8d0658fEc0D1a9995211Ac1f337'
export const HashKeyARECTokenAddressCelo = '0x9BBF9f544F3ceD640090f43FF6B820894f66Aaef'
export const HARTBankAddressCelo = '0x815bFE3aaCF765c9E0A4DdEb98Ad710a4Fb860d3'
export const NativeAssetAddressCelo = '0x471EcE3750Da237f93B8E339c536989b8978a438'

/*
export const defaultIssuerAddressSimu = '0x576Ab950B8B3B18b7B53F7edd8A47986a44AE6F4'
export const arkreenTokenAddressSimu = '0x54E1c534F59343C56549C76D1bDccC8717129832'
export const arkreenIssuanceAddressSimu = '0x95f56340889642A41b913C32d160d2863536E073'
export const arkreenRetirementAddressSimu = '0x5C653b445BE2bdEB6f8f3CD099FC801865Cab835'
export const ARECTokenAddressSimu = '0xb0c9dD915f62d0A37792FD2ce497680E909D8c0F'
export const minerContractAddressSimu = '0xB8663EdC9929D9135E7f6D50f7d3A97862554a72'
export const arkreenRegistryAddressSimu = '0x047Eb5205251c5fC8A21BA8F8D46f57Df62013c8'
export const actionBuilderAddressSimu = '0xA05A9677a9216401CF6800d28005b227F7A3cFae'
export const HashKeyARECTokenAddressSimu = '0x0999afb673944a7b8e1ef8eb0a7c6ffdc0b43e31'
export const HashKeyESGAddressSimu = '0x785dCa2Ca9a51513da1fef9F70E6B6ab02896F67' // 2023/03/15 HashKey ESG Version 0.2
export const HARTBankAddressSimu = '0x7ee6D2A14d6Db71339a010d44793B27895B36d50'
*/

export const defaultIssuerAddressSimu = '0xF1CF65Dbfa9cCEe650a053E218F5788F63bDA60E'
export const arkreenTokenAddressSimu = '0xd092e1f47d4e5d1C1A3958D7010005e8e9B48206'
export const arkreenIssuanceAddressSimu = '0x4fc1d9188610377eA22C577054Fe42627eE49459'
export const arkreenRetirementAddressSimu = '0x8a459D94F30dB4FC5b6e8F1950d67287AF0Bc77C'
export const ARECTokenAddressSimu = '0x615835Cc22064a17df5A3E8AE22F58e67bCcB778'
export const minerContractAddressSimu = '0xF390caaF4FF0d297e0b4C3c1527D707C75541736'
export const arkreenRegistryAddressSimu = '0x908C77c31bA81C2FC0Ec15Ce53cFd65f9c4aEECc'

export const actionBuilderAddressSimu = '0x12De6c1FB46B64e3DA5bFDD274E98B9103353dF7'
export const HashKeyARECTokenAddressSimu = '0x78A2620C3fb96100Dc551Db657005eEeF270F0DF'
export const HashKeyESGAddressSimu = '0x2Bb79dB8b6149F7499CA1bA7eeBE9E736be4dBA9'
export const HARTBankAddressSimu = '0xf9aAcFf1B292F82b60662e47610C570ef58d3c70'
export const CARTTokenAddressMaticSimu = '0x78A2620C3fb96100Dc551Db657005eEeF270F0DF'

// Old polygon testnet
//export const defaultIssuerAddressTest = '0x0AF6Fad1e63De91d5C53Af1dD2e55BB1b278b131'
//export const arkreenTokenAddressTest = '0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2'
//export const arkreenIssuanceAddressTest = '0x7370c2166d7720c41f0931f0bbf67e10d00b0d18'
//export const arkreenRetirementAddressTest = '0xe07968e3b0d64b99ea3653dd925a850ebb9a3bb9'
//export const ARECTokenAddressTest = '0xd1348bb43dbf51a2446db6e40de5f6c178cb2d47'
//export const minerContractAddressTest = '0xC4f795514586275c799729aF5AE7113Bdb7ccc86'
//export const arkreenRegistryAddressTest = '0x61a914363ef99aabca69504cee5ccfd5523c845d'

/* // Mumbi Dev Env
export const defaultIssuerAddressDev = '0xF1CF65Dbfa9cCEe650a053E218F5788F63bDA60E'
export const arkreenTokenAddressDev = '0x8Ab2299351585097101c91FE4b098d95c18D28a7'
export const arkreenIssuanceAddressDev = '0x32Dbe18BBc2C752203b6e1bE87EdE5655A091dFa'
export const arkreenRetirementAddressDev = '0x626f470Ae1427d01f0Fab4D79BC0c9748b07325d'
export const ARECTokenAddressDev = '0x70FdFE7DA492080A8F0233F67C5B48D36d8ceE8b'
export const minerContractAddressDev = '0x682e01f8ecc0524085F51CC7dFB54fDB8729ac22'
export const arkreenRegistryAddressDev = '0xfEcbD33525d9B869e5f3CaB895cd6D7A666209ee'

export const actionBuilderAddressDev = '0xC88535788B4e45966c529D8b3FAd027d1E2d5a0a'
export const HashKeyARECTokenAddressDev = '0xCAABA1AC075Ba045e8C21F9Ae00347EB4FADA3A1'
export const HashKeyESGAddressDev = '0x785dCa2Ca9a51513da1fef9F70E6B6ab02896F67'
export const HARTBankAddressDev = '0x9e1dde2912a804e39e5b19c8b670a6cee0b1ca7a'
export const CARTTokenAddressMaticDev = '0x9031550a0aE38337a19E4eFA372B3e6b0FE94D3f'
*/

// Amoy Dev Env
export const defaultIssuerAddressDev = '0xF1CF65Dbfa9cCEe650a053E218F5788F63bDA60E'
export const arkreenTokenAddressDev = '0xd092e1f47d4e5d1C1A3958D7010005e8e9B48206'
export const arkreenIssuanceAddressDev = '0x4fc1d9188610377eA22C577054Fe42627eE49459'
export const arkreenRetirementAddressDev = '0x8a459D94F30dB4FC5b6e8F1950d67287AF0Bc77C'
export const ARECTokenAddressDev = '0x615835Cc22064a17df5A3E8AE22F58e67bCcB778'
export const minerContractAddressDev = '0xF390caaF4FF0d297e0b4C3c1527D707C75541736'
export const arkreenRegistryAddressDev = '0x908C77c31bA81C2FC0Ec15Ce53cFd65f9c4aEECc'

export const actionBuilderAddressDev = '0x12De6c1FB46B64e3DA5bFDD274E98B9103353dF7'
export const HashKeyARECTokenAddressDev = '0x78A2620C3fb96100Dc551Db657005eEeF270F0DF'
export const HashKeyESGAddressDev = '0x2Bb79dB8b6149F7499CA1bA7eeBE9E736be4dBA9'
export const HARTBankAddressDev = '0xf9aAcFf1B292F82b60662e47610C570ef58d3c70'
export const CARTTokenAddressMaticDev = '0x78A2620C3fb96100Dc551Db657005eEeF270F0DF'

export const defaultIssuerAddressPre = '0x8EEb03d79B08dD763fA549fFA57e5ffF4350B13e'
export const arkreenTokenAddressPre = '0xc83DEd2B70F25C0EB0ef1cDE993DEaA3fAE91314'
export const arkreenIssuanceAddressPre = '0x9745918BAF66e3634502bF9a6C07AD320291D211'
export const arkreenRetirementAddressPre = '0x70A7981b5c9ca1a4250A0C9BBDC2141752deBeeb'
export const ARECTokenAddressPre = '0x2cf7D8C6122a9026d875a8AF0967D8fd6648d9C4'
export const minerContractAddressPre = '0x1F742C5f32C071A9925431cABb324352C6e99953'
export const arkreenRegistryAddressPre = '0x4590B2d8251963E249967D1fa8122974dE574aC6'
export const actionBuilderAddressPre = '0xA05A9677a9216401CF6800d28005b227F7A3cFae'
export const HashKeyARECTokenAddressPre = '0x0999afb673944a7b8e1ef8eb0a7c6ffdc0b43e31'
export const HashKeyESGAddressPre = '0x785dCa2Ca9a51513da1fef9F70E6B6ab02896F67'
export const HARTBankAddressPre = '0x7ee6D2A14d6Db71339a010d44793B27895B36d50'

/*
export const defaultIssuerAddressMatic = '0xec9254677d252df0dCaEb067dFC8b4ea5F6edAfC'
export const arkreenTokenAddressMatic = '0x960C67B8526E6328b30Ed2c2fAeA0355BEB62A83'
export const arkreenIssuanceAddressMatic = '0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B'
export const arkreenRetirementAddressMatic = '0x3d5531cF0bC2e8d0658fEc0D1a9995211Ac1f337'
export const ARECTokenAddressMatic = '0x815bFE3aaCF765c9E0A4DdEb98Ad710a4Fb860d3'
export const minerContractAddressMatic = '0xAc4da3681e51278f82288617c7185a7a119E5b7B'
export const arkreenRegistryAddressMatic = '0x3E8A27dA0BF241f588141659cBb6Bd39717527F1'
export const actionBuilderAddressMatic = '0x7073Ea8C9B0612F3C3FE604425E2af7954c4c92e'
export const HashKeyARECTokenAddressMatic = '0x93b3bb6C51A247a27253c33F0d0C2FF1d4343214'
export const HashKeyESGAddressMatic = '0xfe9341218c7Fcb6DA1eC131a72f914B7C724F200'
export const HARTBankAddressMatic = '0xab65900A52f1DcB722CaB2e5342bB6b128630A28'
*/

export const defaultIssuerAddressMaticRelease = '0xFedD52848Cb44dcDBA95df4cf2BCBD71D58df879'
//export const arkreenTokenAddressMaticRelease = '0x21B101f5d61A66037634f7e1BeB5a733d9987D57' // tAKRE
export const arkreenTokenAddressMaticRelease = '0xE9c21De62C5C5d0cEAcCe2762bF655AfDcEB7ab3' // AKRE
export const arkreenIssuanceAddressMaticRelease = '0x954585adF9425F66a0a2FD8e10682EB7c4F1f1fD'
export const arkreenRetirementAddressMaticRelease = '0x1e5132495cdaBac628aB9F5c306722e33f69aa24'
export const ARECTokenAddressMaticRelease = '0x58E4D14ccddD1E993e6368A8c5EAa290C95caFDF'
export const minerContractAddressMaticRelease = '0xbf8eF5D950F78eF8edBB8674a48cDACa675831Ae'
export const arkreenRegistryAddressMaticRelease = '0xb17faCaCA106fB3D216923DB6CaBFC7C0517029d'
export const actionBuilderAddressMaticRelease = '0x7073Ea8C9B0612F3C3FE604425E2af7954c4c92e'
export const HashKeyARECTokenAddressMaticRelease = '0x93b3bb6C51A247a27253c33F0d0C2FF1d4343214'
export const HashKeyESGAddressMaticRelease = '0xfe9341218c7Fcb6DA1eC131a72f914B7C724F200'
export const HARTBankAddressMaticRelease = '0xab65900A52f1DcB722CaB2e5342bB6b128630A28'

export const defaultIssuerAddressMatic = defaultIssuerAddressMaticRelease
export const arkreenTokenAddressMatic = arkreenTokenAddressMaticRelease
export const arkreenIssuanceAddressMatic = arkreenIssuanceAddressMaticRelease
export const arkreenRetirementAddressMatic = arkreenRetirementAddressMaticRelease
export const ARECTokenAddressMatic = ARECTokenAddressMaticRelease
export const minerContractAddressMatic = minerContractAddressMaticRelease
export const arkreenRegistryAddressMatic = arkreenRegistryAddressMaticRelease
export const actionBuilderAddressMatic = actionBuilderAddressMaticRelease
export const HashKeyARECTokenAddressMatic = HashKeyARECTokenAddressMaticRelease
export const HashKeyESGAddressMatic = HashKeyESGAddressMaticRelease
export const HARTBankAddressMatic = HARTBankAddressMaticRelease

export function useRECIssuerAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return defaultIssuerAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return defaultIssuerAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return defaultIssuerAddressSimu
      if (testMode === 'Dev') return defaultIssuerAddressDev
      if (testMode === 'Pre') return defaultIssuerAddressPre
      return defaultIssuerAddressPre
    } else {
      if (testMode === 'Norm') return defaultIssuerAddressMaticRelease
      return defaultIssuerAddressMatic
    }
  }, [testMode, chainId])
}

export function useArkreenTokenAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return NativeAssetAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return NativeAssetAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return arkreenTokenAddressSimu
      if (testMode === 'Dev') return arkreenTokenAddressDev
      if (testMode === 'Pre') return arkreenTokenAddressPre
      return arkreenTokenAddressPre
    } else {
      if (testMode === 'Norm') return arkreenTokenAddressMaticRelease
      return arkreenTokenAddressMatic
    }
  }, [testMode, chainId])
}

export function useArkreenIssuanceAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return arkreenIssuanceAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return arkreenIssuanceAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return arkreenIssuanceAddressSimu
      if (testMode === 'Dev') return arkreenIssuanceAddressDev
      if (testMode === 'Pre') return arkreenIssuanceAddressPre
      return arkreenIssuanceAddressPre
    } else {
      if (testMode === 'Norm') return arkreenIssuanceAddressMaticRelease
      return arkreenIssuanceAddressMatic
    }
  }, [testMode, chainId])
}

export function useHashKeyARECTokenAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return HashKeyARECTokenAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return HashKeyARECTokenAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return HashKeyARECTokenAddressSimu
      if (testMode === 'Dev') return HashKeyARECTokenAddressDev
      if (testMode === 'Pre') return HashKeyARECTokenAddressPre
      return HashKeyARECTokenAddressPre
    } else {
      if (testMode === 'Norm') return HashKeyARECTokenAddressMaticRelease
      return HashKeyARECTokenAddressMatic
    }
  }, [testMode, chainId])
}

export function useActionBuilderAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return actionBuilderAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return actionBuilderAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return actionBuilderAddressSimu
      if (testMode === 'Dev') return actionBuilderAddressDev
      if (testMode === 'Pre') return actionBuilderAddressPre
      return actionBuilderAddressPre
    } else {
      if (testMode === 'Norm') return actionBuilderAddressMaticRelease
      return actionBuilderAddressMatic
    }
  }, [testMode, chainId])
}

export function useHashKeyESGAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET) {
      return ZERO_ADDRESS
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return HashKeyESGAddressSimu
      if (testMode === 'Dev') return HashKeyESGAddressDev
      if (testMode === 'Pre') return HashKeyESGAddressPre
      return HashKeyESGAddressPre
    } else {
      if (testMode === 'Norm') return HashKeyESGAddressMaticRelease
      return HashKeyESGAddressMatic
    }
  }, [testMode, chainId])
}

export function useHARTBankAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return HARTBankAddressSimu
      if (testMode === 'Dev') return HARTBankAddressDev
      if (testMode === 'Pre') return HARTBankAddressPre
      return HARTBankAddressPre
    } else if (chainId === ChainId.MATIC) {
      if (testMode === 'Norm') return HARTBankAddressMaticRelease
      return HARTBankAddressMatic
    } else if (chainId === ChainId.CELO) {
      return HARTBankAddressCelo
    } else {
      return HARTBankAddressCeloTest
    }
  }, [testMode, chainId])
}

export function useArkreenRetirementAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return arkreenRetirementAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return arkreenRetirementAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return arkreenRetirementAddressSimu
      if (testMode === 'Dev') return arkreenRetirementAddressDev
      if (testMode === 'Pre') return arkreenRetirementAddressPre
      return arkreenRetirementAddressPre
    } else {
      if (testMode === 'Norm') return arkreenRetirementAddressMaticRelease
      return arkreenRetirementAddressMatic
    }
  }, [testMode, chainId])
}

export function useARECTokenAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return ARECTokenAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return ARECTokenAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return ARECTokenAddressSimu
      if (testMode === 'Dev') return ARECTokenAddressDev
      if (testMode === 'Pre') return ARECTokenAddressPre
      return ARECTokenAddressPre
    } else {
      if (testMode === 'Norm') return ARECTokenAddressMaticRelease
      return ARECTokenAddressMatic
    }
  }, [testMode, chainId])
}

export function useMinerContractAddress(): string | undefined {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET) {
      return ZERO_ADDRESS
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return minerContractAddressSimu
      if (testMode === 'Dev') return minerContractAddressDev
      if (testMode === 'Pre') return minerContractAddressPre
      return minerContractAddressPre
    } else {
      if (testMode === 'Norm') return minerContractAddressMaticRelease
      return minerContractAddressMatic
    }
  }, [testMode, chainId])
}

export function useArkreenRegistryAddress(): string {
  const testMode = useGetTestMode()
  const { chainId } = useWeb3React()

  return useMemo(() => {
    if (chainId === ChainId.CELO_TESTNET) {
      return arkreenRegistryAddressCeloTest
    } else if (chainId === ChainId.CELO) {
      return arkreenRegistryAddressCelo
    } else if (chainId === ChainId.MATIC_AMOY_TESTNET) {
      if (testMode === 'Simu') return arkreenRegistryAddressSimu
      if (testMode === 'Dev') return arkreenRegistryAddressDev
      if (testMode === 'Pre') return arkreenRegistryAddressPre
      return arkreenRegistryAddressPre
    } else {
      if (testMode === 'Norm') return arkreenRegistryAddressMaticRelease
      return arkreenRegistryAddressMatic
    }
  }, [testMode, chainId])
}

export function useAKREToken() {
  const { chainId } = useWeb3React()
  const arkreenTokenAddress = useArkreenTokenAddress()
  return new Token(chainId ?? ChainId.MATIC_AMOY_TESTNET, arkreenTokenAddress, 18, 'AKRE', 'Arkreen DAO Token')
}

export function useArkreenTokenContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenTokenAddress = useArkreenTokenAddress()
  return useContract(arkreenTokenAddress, arkreenTokenABI, withSignerIfPossible)
}

export function usePermitTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, arkreenTokenABI, withSignerIfPossible)
}

export function useArkreenRECTokenContract(withSignerIfPossible?: boolean, tokenAddress?: string): Contract | null {
  const ARECTokenAddress = useARECTokenAddress()
  return useContract(tokenAddress ?? ARECTokenAddress, abiArkreenRECToken, withSignerIfPossible)
}

export function useRECIssuanceContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenIssuanceAddress = useArkreenIssuanceAddress()
  return useContract(arkreenIssuanceAddress, abiArkreenRECIssuance, withSignerIfPossible)
}

export function useArkreenRetirementContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenRetirementAddress = useArkreenRetirementAddress()
  return useContract(arkreenRetirementAddress, abiArkreenRetirement, withSignerIfPossible)
}

export function useArkreenRegistryContract(withSignerIfPossible?: boolean): Contract | null {
  const arkreenRegistryAddress = useArkreenRegistryAddress()
  return useContract(arkreenRegistryAddress, abiArkreenRegistry, withSignerIfPossible)
}

export function useArkreenMinerContract(withSignerIfPossible?: boolean): Contract | null {
  const minerContractAddress = useMinerContractAddress()
  return useContract(minerContractAddress, abiArkreenMiner, withSignerIfPossible)
}

export function useActionBuilderContract(withSignerIfPossible?: boolean): Contract | null {
  const actionBuilderAddress = useActionBuilderAddress()
  return useContract(actionBuilderAddress, ActionBuilder_ABI, withSignerIfPossible)
}

export function useHashKeyESGContract(withSignerIfPossible?: boolean): Contract | null {
  const hashKeyESGAddress = useHashKeyESGAddress()
  return useContract(hashKeyESGAddress, HashKeyESG_ABI, withSignerIfPossible)
}

export function useHARTBankContract(withSignerIfPossible?: boolean): Contract | null {
  const HARTBankAddress = useHARTBankAddress()
  return useContract(HARTBankAddress, HART_BANK_ABI, withSignerIfPossible)
}
