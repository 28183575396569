//import { transparentize } from 'polished'color
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
// import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

// text2: darkMode ? '#C3C5CB' : '#565A69',

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#444444',
    text3: darkMode ? '#6C7284' : '#707070',
    text4: darkMode ? '#565A69' : '#9F9FA6',
    text5: darkMode ? '#2C2F36' : '#E9E9E9',

    // backgrounds / greys
    bg1: darkMode ? '#212429' : '#F8F8F8', // E4E4E4
    bg2: darkMode ? '#2C2F36' : '#E6F4EB',
    bg3: darkMode ? '#40444F' : '#B3DEC4', // #cff0f0  #CED0D9  #a6a6ff 80d8d8  #b9b9ff a6a6ff
    bg4: darkMode ? '#565A69' : '#B3DEC4',
    bg5: darkMode ? '#6C7284' : '#CCE9D8',

    //C2C2C2

    // for nav link
    navlink: darkMode ? '#BF4722' : '#BF4722',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : '#d1fcf9',

    divider: darkMode ? 'rgba(43, 43, 43, 0.435)' : 'rgba(43, 4primary53, 43, 0.035)',

    changeColor: darkMode ? '#003030' : '#eddfff', // e3cfff  rgb(227,207,255)  #eddfff
    fillBG: darkMode ? '#000040' : '#eaeafb',

    // fillBG: darkMode ? '#212429' : '#eaeafb',

    // rgba(200,252,248)
    // 87CEFA 209,252,249 200,252,248 222,222,248,  232,216,228 , 233,199,205, 233,216,229, 188,251,247

    //primary colors
    primary1: darkMode ? '#2172E5C0' : '#00742E', //  rgba(14,185,194,0.8)   # 00a63eE0
    primary2: darkMode ? '#3680E7' : '#33A761',
    primary3: darkMode ? '#4D8FEA' : '#66BD89',
    primary4: darkMode ? '#376bad70' : '#99D3B0',
    primary5: darkMode ? '#153d6f70' : '#CCE9D8', // #0c9ca5  dcdee4
    primary: darkMode ? '#3680E7' : '#00923A',

    // color text
    primaryText1: darkMode ? '#6da8ff' : '#00913A', // 0c9da5   #ff007a
    sideBar: darkMode ? '#0a8288' : '#00923A',
    sideBarText: darkMode ? '#0a8288' : '#FFFFFF',
    mainBgrd: darkMode ? '#0a8288' : '#F6FBF7',

    purple1: darkMode ? '#6da8ff' : '#9D68F6',
    purple2: darkMode ? '#6da8ff' : '#B186F8',
    purple3: darkMode ? '#6da8ff' : '#C4A4FA',
    purple4: darkMode ? '#6da8ff' : '#D8C3FB',
    purple5: darkMode ? '#6da8ff' : '#E2D2FC',
    purple6: darkMode ? '#6da8ff' : '#EBE1FD',
    purple7: darkMode ? '#6da8ff' : '#F5F0FE',
    warn1: darkMode ? '#6da8ff' : '#DF9742',
    warn2: darkMode ? '#6da8ff' : '#FCF5EC',

    //    rgb(14,185,194) rgb(14,185,194,0.8)  'rgb(14,185,194,0.8)',
    // rgba(40,254,44,0.6)

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#dcdee4',

    // other
    red1: '#FF6871',
    red2: '#F82D3A',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#ff8000',
    blue1: '#2172E5',
    link: '#2172E5'

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',DefaultTheme
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    fontSize: '16px',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  //  const darkMode = useIsDarkMode()
  const darkMode = false

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  Main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  Primary(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary'} {...props} />
  },
  Body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  MediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  SubHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  Italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var';
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors(false).blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color:  ${({ theme }) => theme.mainBgrd};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
}

body {
  margin: 0px;
  min-height: 100vh;
}
`
//background-image: linear-gradient(rgba(14,185,194,0.3), rgba(229,255,190,0.3));

// rgb(14,185,194)  #0fb9c2

//background-image: linear-gradient(rgba(0,255,64,0.3), rgba(229,255,190,0.3));   // 浅绿

// background-image: linear-gradient(rgba(0,192,239,0.4), rgba(250,234,229,0.3));  // Original

//body {
//  min-height: 100vh;
//  background-image: linear-gradient(rgba(0,192,239,0.3), rgba(250,234,229,0.3));
// background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,1));
