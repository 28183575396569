import { useContext, useState, useCallback, useMemo, ChangeEvent, useLayoutEffect } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { MessageCircle as HelpCircle } from 'react-feather' // MessageCircle  Coffee
import { RowBetween, RowFixed } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
//import { TitleLink } from '../../components/Link'
import { StyledPageCard } from '../../components/earn/styled'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useRECIssuanceContract } from '../../hooks/useContract'
import { calculateGasMargin, isAddress } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useGetUserARECList } from '../../state/issuance/hooks'
import Loader from '../../components/Loader'
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Container } from '../../components/CurrencyInputPanel'
import { TYPE } from '../../theme'
import { RECData, REC_STATUS, RECRequest } from '../../state/issuance/hooks'
import TransactionConfirmationModal, { ConfirmationModalContentTitle } from 'components/TransactionConfirmationModal'
import { ZERO_ADDRESS } from '../../constants'
import { MouseoverTooltip } from '../../components/Tooltip'
import { RedeemHeadButton, getPowerString } from '../../components/ARecIssuance'
import { useMedia } from 'react-use'
import { useArecCount } from '../../state/arec/hooks'
import { useFontSize } from 'hooks/useWindowSize'

import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import {
  GetCertificateInfo,
  ARECSelect,
  ARECOption,
  ButtonRow,
  DetailedARECInfo,
  M,
  ErrorPromptModal
} from '../../components/ARecIssuance'

function RedeemHelpInfo() {
  return (
    <>
      <Text>
        This is to redeem your AREC NFT. After the selected AREC NFT is redeemed, one redeem action will be created and
        recorded on blockchain. Optionally you could mint an AREC climate badge at the same transaction.
      </Text>
      <Text>
        <M>1.</M> Connect your wallet on Polygon.
      </Text>
      <Text>
        <M>2.</M> If any, select the AREC to redeem from the AREC NFT list .
      </Text>
      <Text>
        <M>3.</M> Check the indicated AREC to redeem.
      </Text>
      <Text>
        <M>4.A</M> Either click <M>Redeem</M> if you just want to redeem the AREC as a climate badge action, check and
        sign your AREC redeem transaction.
      </Text>
      <Text>
        <M>4.B</M> Or click <M>Redeem and Mint Badge</M> if you want to redeem the AREC and also mint a climate badge
        badge, check and sign your AREC redeem transaction.
      </Text>
      <Text>
        <M>5.</M> Waiting your AREC redeem transaction been confirmed by the blockchain.
      </Text>
      <Text>
        <M>Reminding:</M> If you redeem your AREC as a climate badge action, you could mint AREC climate badge freely at
        any later time.
      </Text>
    </>
  )
}

export default function Redeem() {
  const { chainId, account } = useWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const below540 = useMedia('(max-width: 540px)')

  const arkreenRECIssuanceContract = useRECIssuanceContract(true)
  const { updatetxHash } = useArecCount()

  const [{ showConfirm, txnToConfirm, attemptingTxn, errorMessage, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const {
    numberOfARECNft,
    allARECInfo,
    allARECNftTokensID,
    totalRECAmountIssued,
    totalRECAmountPending
  } = useGetUserARECList()

  const totalRECAmountIssuedString = getPowerString(totalRECAmountIssued)
  const totalRECAmountPendingString = getPowerString(totalRECAmountPending)

  //  const totalRECAmountIssuedString = (new Fraction(totalRECAmountIssued.toString(), JSBI.BigInt(1000000))).toFixed(3)
  //  const totalRECAmountPendingString = (new Fraction(totalRECAmountPending.toString(), JSBI.BigInt(1000000))).toFixed(3)

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  const addTransaction = useTransactionAdder()

  const [nameCertOwner, setNameCertOwner] = useState<string>('')
  const [beneficiary, setBeneficiary] = useState<string>(ZERO_ADDRESS)
  const [nameBeneficiary, setNameBeneficiary] = useState<string>('')
  const [memoCertificate, setMemoCertificate] = useState<string>('')

  const errorAddress = useMemo(() => {
    const address = isAddress(beneficiary)
    const error = Boolean(!address && beneficiary !== ZERO_ADDRESS && beneficiary !== '')
    return error
  }, [beneficiary])

  const onChangeBeneficiary = useCallback(
    (beneficiary: string) => {
      setBeneficiary(beneficiary)
    },
    [setBeneficiary]
  )

  const [arecSelected, setARECSelected] = useState<number | undefined>()

  const onARECSelect = useCallback(
    (arecSelect: ChangeEvent<HTMLSelectElement>) => {
      setARECSelected(Number(arecSelect.target.value))
    },
    [setARECSelected]
  )

  useLayoutEffect(() => {
    setARECSelected(undefined)
  }, [chainId, account])

  const IDString = useMemo(() => {
    if (arecSelected === undefined || !allARECNftTokensID[arecSelected]) return ''
    const optionTextID = '0000'.concat(allARECNftTokensID[arecSelected].toString())
    return optionTextID.substring(optionTextID.length - 4)
  }, [allARECNftTokensID, arecSelected])

  const recPowerList = allARECInfo.map((recData: RECData) => {
    return getPowerString(recData.amountREC)
  })

  const recStatusList = allARECInfo.map((recData: RECData) => {
    const recStatus =
      recData?.status === REC_STATUS.Pending
        ? 'Pending'
        : recData?.status === REC_STATUS.Certified
        ? 'Certified'
        : recData?.status === REC_STATUS.Cancelled
        ? 'Cancelled'
        : recData?.status === REC_STATUS.Rejected
        ? 'Rejected'
        : ' '
    return recStatus
  })

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  const { font14, font16, font20 } = useFontSize()

  function modalHeader() {
    return (
      <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>
        <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
          <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
            <RowBetween align="center" height="20px">
              <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                NFT ID of AREC to Redeem:
              </Text>
              <Text fontWeight={700} fontSize={font14} color={theme.primary1}>
                {'AREC_'.concat(IDString)}
              </Text>
            </RowBetween>

            <RowBetween align="center" height="24px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  RE Amount to Redeem:
                </Text>
              </RowFixed>
              <Text lineHeight={'24px'} fontWeight={700} fontSize={font14} color={theme.primary1}>
                {arecSelected !== undefined ? recPowerList[arecSelected] : ''}
              </Text>
            </RowBetween>
          </AutoColumn>
        </Container>
        <GetCertificateInfo
          certOwner={nameCertOwner}
          beneficiary={beneficiary}
          nameBeneficiary={nameBeneficiary}
          memoCertificate={memoCertificate}
          setCertOwner={setNameCertOwner}
          setBeneficiary={onChangeBeneficiary}
          setNameBeneficiary={setNameBeneficiary}
          setMemoCertificate={setMemoCertificate}
        />
      </AutoColumn>
    )
  }

  const attemptString = `Your AREC NFT of AREC_${IDString} is being redeemed, a climate badge will 
                        be minted for you.`

  function modalBottom() {
    return (
      <RowBetween>
        <ButtonError disabled={errorAddress} onClick={() => handleRedeemAndMintCertificate()} id="liquidize-button">
          <Text fontSize={font20} fontWeight={500}>
            {!errorAddress ? `Redeem and Mint Badge` : `Wrong Beneficiary Address`}
          </Text>
        </ButtonError>
      </RowBetween>
    )
  }

  const handleConfirmDismiss = useCallback(() => {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm: false, errorMessage, txHash })
  }, [attemptingTxn, txnToConfirm, errorMessage, txHash])

  async function handleRedeemAndMintCertificate() {
    if (!arkreenRECIssuanceContract || arecSelected === undefined) return

    const ARECID = allARECNftTokensID[arecSelected]
    const ARECIDString = '0000'.concat(ARECID.toString())
    const ARECIDStr = ARECIDString.substring(ARECIDString.length - 4)

    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['redeemAndMintCertificate'](
      ARECID,
      beneficiary === '' ? ZERO_ADDRESS : beneficiary,
      nameCertOwner,
      nameBeneficiary,
      memoCertificate
    )
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .redeemAndMintCertificate(
            ARECID,
            beneficiary === '' ? ZERO_ADDRESS : beneficiary,
            nameCertOwner,
            nameBeneficiary,
            memoCertificate,
            { gasLimit: calculateGasMargin(estimatedGasLimit) }
          )
          .then((response: TransactionResponse) => {
            setARECSelected(undefined)
            addTransaction(response, {
              summary: `Redeemed AREC ID: ${ARECIDStr}`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            // setTxHashToCheck(response.hash)
            updatetxHash(response.hash)
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Redeeming AREC failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Redeeming AREC failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        console.log('Error of Redeeming AREC tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function handleRECRedeem() {
    if (!arkreenRECIssuanceContract || arecSelected === undefined) return

    const ARECID = allARECNftTokensID[arecSelected]
    const ARECIDString = '0000'.concat(ARECID.toString())
    const ARECIDStr = ARECIDString.substring(ARECIDString.length - 4)

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['redeem'](ARECID)
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .redeem(ARECID, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            setARECSelected(undefined)
            addTransaction(response, {
              summary: `Redeemed AREC ID: ${ARECIDStr}`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
            //  setTxHashToCheck(response.hash)
            updatetxHash(response.hash)
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Redeeming AREC failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Redeeming AREC failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Redeeming AREC tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  const HelpForMint1 = (
    <>
      After the selected AREC NFT is redeemed, one redeem action will be created and recorded on blockchain. With any
      redeem actions, you could mint an AREC climate badge NFT at anytime later.
    </>
  )

  const HelpForMint2 = (
    <>
      After the selected AREC NFT is redeemed, one redeem action will be created and recorded on blockchain. An AREC
      climate badge NFT will be minted at the same time.
    </>
  )

  return (
    <>
      <RedeemHeadButton path={'Redeem'} />
      <AppBody>
        <StyledPageCard bgColor={'purple'}>
          <PageHeader header={'AREC Redeem'}>
            <QuestionHelper bkgOff={true} text={'AREC Redeem'} info={<RedeemHelpInfo />} />
          </PageHeader>

          <Wrapper id="issuance-page">
            <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
            <TransactionConfirmationModal
              isOpen={showConfirm && !errorMessage}
              onDismiss={handleConfirmDismiss}
              attemptingTxn={attemptingTxn}
              hash={txHash ? txHash : ''}
              content={() => (
                <ConfirmationModalContentTitle
                  title={'You will redeem'}
                  onDismiss={handleConfirmDismiss}
                  topContent={modalHeader}
                  bottomContent={modalBottom}
                />
              )}
              pendingText={attemptString}
              pendingTitle={'Redeem and Mint Badge'}
              submittedTitle={'Redeem and Mint Badge Submitted'}
            />

            <AutoColumn gap={'md'}>
              <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
                <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      Total AREC NFT Number:
                    </Text>
                    {numberOfARECNft === undefined ? (
                      <Loader />
                    ) : (
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {numberOfARECNft}
                      </Text>
                    )}
                  </RowBetween>
                  {!totalRECAmountIssued.isZero() && (
                    <RowBetween align="center" height="20px">
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        Total Issued AREC Amount:
                      </Text>
                      <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {totalRECAmountIssuedString}
                      </Text>
                    </RowBetween>
                  )}
                  {!totalRECAmountPending.isZero() && (
                    <RowBetween align="center" height="20px">
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        Total Pending AREC Amount:
                      </Text>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {totalRECAmountPendingString} KWH
                      </Text>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Container>
              {numberOfARECNft !== undefined && numberOfARECNft !== 0 && (
                <Container>
                  <RowBetween align="center" height="20px" style={{ padding: '0.75rem 1rem 0rem 1rem' }}>
                    <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16} width={'45%'}>
                      <strong>AREC NFT List:</strong>
                    </TYPE.Body>
                  </RowBetween>
                  <div style={{ margin: '0.8rem 0.6rem 0.6rem' }}>
                    <ARECSelect
                      itemSelected={!!arecSelected}
                      value={arecSelected !== undefined ? arecSelected.toString() : 'none'}
                      onChange={onARECSelect}
                      fontSize={font16}
                    >
                      <ARECOption key="none" value="none" disabled hidden>
                        Please select the AREC NFT to redeem
                      </ARECOption>
                      {allARECInfo.map((recData: RECData, index) => {
                        const optionTextID = '0000'.concat(allARECNftTokensID[index].toString())
                        return (
                          <ARECOption key={optionTextID} value={index.toString()}>
                            {'AREC_'.concat(optionTextID.substring(optionTextID.length - 4)).concat(':')}
                            {'   '}
                            {recPowerList[index]} {`   `} {recStatusList[index]}
                          </ARECOption>
                        )
                      })}
                    </ARECSelect>
                  </div>
                  {allARECInfo[0] && arecSelected !== undefined && (
                    <div style={{ padding: '0rem 0.6rem 0.6rem 0.6rem' }}>
                      <DetailedARECInfo recData={allARECInfo[arecSelected]} />
                    </div>
                  )}
                </Container>
              )}
            </AutoColumn>

            {numberOfARECNft !== undefined &&
              numberOfARECNft !== 0 &&
              (arecSelected === undefined ? (
                <Text fontWeight={500} textAlign="center" fontSize={font14} color="red" padding="6px 12px 0px">
                  Note: The AREC NFT will be retired as a climate action once it is redeemed. Make sure you know about
                  AREC redemption !
                </Text>
              ) : (
                <Text fontWeight={500} textAlign="center" fontSize={font16} color="red" padding="6px 12px 0px">
                  The selected AREC NFT will be redeeemed forever !
                </Text>
              ))}

            <BottomGrouping>
              {!account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : numberOfARECNft === undefined ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Waiting AREC Info
                  </Text>
                </ButtonError>
              ) : numberOfARECNft === 0 ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    No AREC NFT
                  </Text>
                </ButtonError>
              ) : arecSelected === undefined ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Select the AREC
                  </Text>
                </ButtonError>
              ) : allARECInfo[arecSelected]?.status === REC_STATUS.Pending ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Pending AREC
                  </Text>
                </ButtonError>
              ) : (
                <>
                  <RowBetween>
                    <ButtonError disabled={isWaitingWallet} onClick={() => handleRECRedeem()} id="redeem-button">
                      <ButtonRow>
                        <div />
                        <Text fontSize={font20} fontWeight={500}>
                          Redeem AREC
                        </Text>
                        {below540 ? (
                          <div />
                        ) : (
                          <div style={{ fontWeight: 'lighter' }}>
                            <MouseoverTooltip info={HelpForMint1}>
                              <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                            </MouseoverTooltip>
                          </div>
                        )}
                      </ButtonRow>
                    </ButtonError>
                  </RowBetween>

                  <RowBetween marginTop="10px">
                    <ButtonError
                      disabled={isWaitingWallet}
                      onClick={() =>
                        setARECTxnState({
                          attemptingTxn: false,
                          txnToConfirm,
                          showConfirm: true,
                          errorMessage: undefined,
                          txHash: undefined
                        })
                      }
                      id="redeem-button"
                    >
                      <ButtonRow>
                        <div />
                        <Text fontSize={font20} fontWeight={500}>
                          Redeem and Mint Badge
                        </Text>
                        {below540 ? (
                          <div />
                        ) : (
                          <div style={{ fontWeight: 'lighter' }}>
                            <MouseoverTooltip info={HelpForMint2}>
                              <HelpCircle size={font20} color={theme.text5} style={{ marginLeft: '8px' }} />
                            </MouseoverTooltip>
                          </div>
                        )}
                      </ButtonRow>
                    </ButtonError>
                  </RowBetween>
                </>
              )}
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}
