import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
//import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import SideNav from '../components/SideNav'
import { useMedia } from 'react-use'
//import { ApplicationModal } from '../state/application/actions'
//import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
//import PoolFinder from './PoolFinder'
import Overview from './Overview'
import Profile from './Profile'

import Test from './Test'
import Mode from './Mode'

import Swap from './Swap'
import Issuance from './Issuance'
import QuickStep from './QuickStep'
import Redeem from './Redeem'
import Liquidize from './Liquidize'
//import RECManager from './RECManager'
import MintCertificate from './Certificate'
import Offset from './Offset'
import Solidify from './Solidify'
import Port from './Port'
import Bridge from './Bridge'
import Complete from './Complete'
import CARTSwap from './CARTSwap'

import { RedirectPathToOverviewOnly, RedirectToSwap } from './Swap/redirects'
//import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 16px 16px 16px;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const below1080 = useMedia('(max-width: 1080px)')
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <ContentWrapper>
        {!below1080 && <SideNav />}
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Polling />
            <Switch>
              <Route exact strict path="/overview" component={Overview} />
              <Route exact strict path="/Profile" component={Profile} />
              <Route exact strict path="/quick" component={QuickStep} />
              <Route exact strict path="/quicks" component={QuickStep} />
              <Route exact strict path="/Issuance" component={Issuance} />
              <Route exact strict path="/Liquidize" component={Liquidize} />
              <Route exact strict path="/Redeem" component={Redeem} />
              <Route exact strict path="/Offset" component={Offset} />
              <Route exact strict path="/Solidify" component={Solidify} />
              <Route exact strict path="/mintCert" component={MintCertificate} />
              <Route exact strict path="/port" component={Port} />
              <Route exact strict path="/bridge" component={Bridge} />
              <Route exact strict path="/complete" component={Complete} />
              <Route exact strict path="/Test" component={Test} />
              <Route exact strict path="/Mode" component={Mode} />
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/cART" component={CARTSwap} />
              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact path="/add" component={AddLiquidity} />
              <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
              <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
              <Route component={RedirectPathToOverviewOnly} />
            </Switch>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </ContentWrapper>
    </Suspense>
  )
}
