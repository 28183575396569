import { Page, Text, Document, StyleSheet, View, Link, Image, Font } from '@react-pdf/renderer'
//import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer'
//import { QRCodeCanvas } from 'qrcode.react'
//import { ChainId } from '@arec/sdk'
import { BadgeInfo } from '../../state/issuance/hooks'
import { Fraction, JSBI } from '@arec/sdk'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { BigNumber } from 'ethers'
import { ZERO_ADDRESS } from '../../constants'
import { OffsetAction, OffsetDetail, RECData } from '../../state/issuance/hooks'
import { getNetworkParameter } from 'connection'

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
})

Font.registerHyphenationCallback(word => [word])

const MyDocument: any = Document
const MyPage: any = Page

const styles = StyleSheet.create({
  body: {
    hyphens: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0
  },
  bodyBadge: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 45
  },
  view: {
    paddingTop: 100,
    height: '100%',
    paddingHorizontal: 80
  },
  pageAction: {
    paddingTop: 60,
    height: '100%',
    paddingHorizontal: 60
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  tableTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    paddingVertical: 6,
    flexWrap: 'wrap',
    fontSize: 16,
    textAlign: 'center',
    borderStyle: 'solid',
    borderColor: 'black',
    border: 0.4
  },
  tableActionTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingLeft: 3,
    flexWrap: 'wrap',
    fontSize: 12,
    textAlign: 'left',
    borderStyle: 'solid',
    borderColor: 'black',
    border: 0.4
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    paddingLeft: 16,
    fontSize: 16,
    textAlign: 'left',
    fontWeight: 800,
    marginTop: 20,
    marginBottom: -10
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 20
  },
  textCertify: {
    fontSize: 14,
    textAlign: 'left',
    marginHorizontal: 6,
    fontFamily: 'Times-Roman'
  },
  textImportant: {
    fontSize: 14,
    fontWeight: 800,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  textLink: {
    fontSize: 14,
    fontWeight: 800,
    textAlign: 'center',
    fontFamily: 'Oswald',
    textDecoration: 'none',
    textDecorationStyle: 'dashed'
  },
  account: {
    fontSize: 14,
    fontWeight: 800,
    color: 'green',
    textAlign: 'center',
    fontFamily: 'Oswald',
    textDecoration: 'none'
  },
  CID: {
    fontSize: 10,
    fontWeight: 600,
    marginRight: 3,
    paddingVertical: 3,
    color: 'blue',
    textAlign: 'center',
    textDecoration: 'none'
  },
  text: {
    margin: 6,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  pageBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%'
  },

  textNew: {
    margin: 10,
    fontSize: 10,
    color: 'white'
  },
  page: { padding: 60 },

  flex: {
    display: 'flex',
    width: 'auto'
  },

  inline: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Times-Roman'
  },

  viewInLine: {
    marginHorizontal: 6,
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  viewInLinePara: {
    margin: '12 6 0 6',
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
  },
  textInLine: {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Times-Roman'
  },
  textBlack: {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Times-Bold',
    fontWeight: 'black'
  },
  textBold: {
    color: 'green',
    fontFamily: 'Times-Bold',
    fontWeight: 'black'
  },
  table: {
    display: 'flex',
    width: 'auto',
    marginHorizontal: 20,
    marginVertical: 3,
    borderStyle: 'solid',
    borderColor: 'grey',
    borderTopWidth: 0.2
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableDetails: {
    paddingTop: 6
  },
  tableItemTitle: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: 'grey',
    borderBottomWidth: 0.2
  },
  tableItemContent: {
    width: '75%',
    borderStyle: 'solid',
    fontFamily: 'Times-Bold',
    borderColor: 'grey',
    borderBottomWidth: 0.2,
    paddingHorizontal: 4
  },
  tableAction: {
    display: 'flex',
    width: 'auto',
    marginHorizontal: 10,
    marginTop: 20,
    borderStyle: 'solid',
    borderColor: 'black',
    border: 0.6
  },
  tableDetail: {
    display: 'flex',
    width: 'auto',
    marginHorizontal: 10,
    marginTop: -1.0,
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: 0.6
  },
  tableItemActionTitle: {
    width: '35%',
    borderStyle: 'solid',
    borderColor: 'black',
    border: 0.4
  },
  tableItemActionContent: {
    hyphens: 'none',
    width: '65%',
    borderStyle: 'solid',
    fontFamily: 'Times-Bold',
    borderColor: 'black',
    border: 0.4,
    paddingHorizontal: 4
  },
  tableCellLeft: {
    marginLeft: 3,
    textAlign: 'left',
    paddingVertical: 3,
    fontSize: 10
  },
  tableCellRight: {
    marginRight: 3,
    textAlign: 'center',
    paddingVertical: 3,
    fontSize: 10
  },
  logoArkreen: {
    position: 'absolute',
    height: '8%',
    bottom: 100,
    right: 100
  },
  logoArkreenDetail: {
    position: 'absolute',
    height: '3%',
    bottom: 40,
    right: 55
  }
})

export function BadgePdf({
  chainId,
  account,
  BadgeID,
  ARECBadgeContrct,
  badgeInfo,
  allOffsetActionsID,
  allOffsetActions,
  allOffsetDetails,
  allARECAmountString,
  allARECDataJson
}: {
  chainId: number
  account: string
  BadgeID: BigNumber
  ARECBadgeContrct: string
  badgeInfo: BadgeInfo
  allOffsetActionsID: BigNumber[]
  allOffsetActions: OffsetAction[]
  allOffsetDetails: { [actionID: number]: OffsetDetail[] }
  allARECAmountString: { [idAREC: number]: string }
  allARECDataJson: { [idAREC: number]: RECData }
}) {
  //    const theme = useContext(ThemeContext)

  const [userBadgeAmountString, userBadgeAmountStringinWMH] = useMemo(() => {
    const offsetTotalAmount = new Fraction(badgeInfo.offsetTotalAmount.toString(), JSBI.BigInt(1000000))
    const userBadgeAmountString = (offsetTotalAmount?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')

    const offsetTotalAmountInMWH = new Fraction(badgeInfo.offsetTotalAmount.toString(), JSBI.BigInt(1000000000))
    const userBadgeAmountStringinWMH = (offsetTotalAmountInMWH?.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(
      ' MWH'
    )
    return [userBadgeAmountString, userBadgeAmountStringinWMH]
  }, [badgeInfo])

  const accountUrl = useMemo(() => {
    return getNetworkParameter(chainId).blockExplorerUrls + 'address/' + account
  }, [chainId, account])

  const badgeUrl = useMemo(() => {
    return getNetworkParameter(chainId).blockExplorerUrls + 'token/' + ARECBadgeContrct
  }, [chainId, ARECBadgeContrct])

  const retirementTime = DateTime.fromSeconds(badgeInfo.creationTime.toNumber())
  //const retirementTimeStr = retirementTime.toFormat('yyyy-MM-dd HH:mm:ss')
  const retirementTimeStr = retirementTime.toRFC2822()

  const actionListString = badgeInfo.offsetIds.reduce<string>((actionListString, actionId) => {
    const actionIdn = actionId.toNumber()
    actionListString = actionListString + ' ' + actionIdn.toString() + ','
    return actionListString
  }, ' ( ID:')

  const allOffsetActionsIDJson = useMemo(() => {
    return allOffsetActionsID.reduce<{ [actionID: number]: OffsetAction }>((allJson, id, index) => {
      const idn = id.toNumber()
      allJson[idn] = allOffsetActions[index]
      return allJson
    }, {})
  }, [allOffsetActionsID, allOffsetActions])

  //  <Text style={styles.tableCellRight}>{!!offsetAction ? offsetAction.issuerREC : 'issuerREC'}</Text>

  return (
    <MyDocument>
      <MyPage style={styles.body} wrap={false}>
        <Image src="https://arec.arkreen.com/images/backImage.png" style={styles.pageBackground} fixed />
        <View style={styles.view}>
          <Text style={styles.title}>Certificate of Climate Actions</Text>
          <Text style={styles.author}>Arkreen Network</Text>
          <Text style={styles.textCertify}>This is to certify that the owner of the account</Text>
          <Text style={styles.textImportant}>
            <Link src={accountUrl} style={styles.account}>
              {account}
            </Link>
          </Text>

          <View style={styles.viewInLine}>
            <Text style={styles.textInLine}>has totally offset&nbsp;</Text>
            <Text style={styles.textBlack}>{userBadgeAmountStringinWMH}</Text>
            <Text style={styles.textInLine}>&nbsp;to commit carbon reduction for</Text>
          </View>

          <Text style={styles.textImportant}>
            {badgeInfo.offsetMessage ?? 'Carbon offset of&nbsp;' + userBadgeAmountStringinWMH}
          </Text>

          <View style={styles.viewInLinePara}>
            <Text style={styles.textInLine}>
              All the carbon reduction details are stored within the AREC Badge with &nbsp;
            </Text>
            <Text style={styles.textBlack}>ID #{BadgeID.toString().padStart(4, '0')}</Text>
            <Text style={styles.textInLine}>&nbsp;of the Arkreen Badge contract</Text>
          </View>

          <Text style={styles.textImportant}>
            <Link src={badgeUrl} style={styles.textLink}>
              {ARECBadgeContrct}
            </Link>
          </Text>

          <View style={styles.viewInLinePara}>
            <Text style={styles.textInLine}>on the Blockchain of &nbsp;</Text>
            <Text style={styles.textBlack}>{getNetworkParameter(chainId).chainName}</Text>
            <Text style={styles.textInLine}>.</Text>
          </View>

          <View style={styles.viewInLinePara}>
            <Text style={styles.textInLine}>Summary of the carbon reduction details are:</Text>
          </View>

          <View style={styles.table}>
            {badgeInfo.offsetEntity !== '' && (
              <View style={styles.tableRow}>
                <View style={styles.tableItemTitle}>
                  <Text style={styles.tableCellLeft}>Offset Entity:</Text>
                </View>
                <View style={styles.tableItemContent}>
                  <Text style={styles.tableCellRight}>{badgeInfo.offsetEntity}</Text>
                </View>
              </View>
            )}

            {badgeInfo.beneficiary !== '' && (
              <View style={styles.tableRow}>
                <View style={styles.tableItemTitle}>
                  <Text style={styles.tableCellLeft}>Beneficiary:</Text>
                </View>
                <View style={styles.tableItemContent}>
                  <Text style={styles.tableCellRight}>
                    {badgeInfo.beneficiary === ZERO_ADDRESS ? badgeInfo.offsetEntity : badgeInfo.beneficiary}
                  </Text>
                </View>
              </View>
            )}

            {badgeInfo.offsetEntityID !== '' && (
              <View style={styles.tableRow}>
                <View style={styles.tableItemTitle}>
                  <Text style={styles.tableCellLeft}>Offset Entity Name:</Text>
                </View>
                <View style={styles.tableItemContent}>
                  <Text style={styles.tableCellRight}>{badgeInfo.offsetEntityID}</Text>
                </View>
              </View>
            )}

            {badgeInfo.beneficiaryID !== '' && (
              <View style={styles.tableRow}>
                <View style={styles.tableItemTitle}>
                  <Text style={styles.tableCellLeft}>Beneficiary Name:</Text>
                </View>
                <View style={styles.tableItemContent}>
                  <Text style={styles.tableCellRight}>{badgeInfo.beneficiaryID}</Text>
                </View>
              </View>
            )}

            {badgeInfo.offsetMessage !== '' && (
              <View style={styles.tableRow}>
                <View style={styles.tableItemTitle}>
                  <Text style={styles.tableCellLeft}>Offset Message:</Text>
                </View>
                <View style={styles.tableItemContent}>
                  <Text style={styles.tableCellRight}>{badgeInfo.offsetMessage}</Text>
                </View>
              </View>
            )}

            <View style={styles.tableRow}>
              <View style={styles.tableItemTitle}>
                <Text style={styles.tableCellLeft}>Badge Creation Time:</Text>
              </View>
              <View style={styles.tableItemContent}>
                <Text style={styles.tableCellRight}>{retirementTimeStr}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableItemTitle}>
                <Text style={styles.tableCellLeft}>Offset Amount:</Text>
              </View>
              <View style={styles.tableItemContent}>
                <Text style={styles.tableCellRight}>{userBadgeAmountString}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableItemTitle}>
                <Text style={styles.tableCellLeft}>Number of Actions:</Text>
              </View>
              <View style={styles.tableItemContent}>
                <Text style={styles.tableCellRight}>{badgeInfo.offsetIds.length}</Text>
              </View>
            </View>
          </View>
          <Image src="https://arec.arkreen.com/Arkreen.png" style={styles.logoArkreen} />
        </View>
      </MyPage>

      <MyPage style={styles.bodyBadge} wrap>
        <Image src="https://arec.arkreen.com/Arkreen.png" style={styles.logoArkreenDetail} fixed />
        <Text style={styles.title}>AREC Climate Badge Details</Text>

        <View style={styles.tableAction}>
          <View style={styles.tableRow}>
            <View style={styles.tableTitle}>
              <Text>AREC Climate Badge ID: &nbsp;&nbsp;</Text>
              <Text style={styles.textBold}>#{BadgeID.toString().padStart(4, '0')}</Text>
            </View>
          </View>

          {badgeInfo.offsetEntity !== '' && (
            <View style={styles.tableRow}>
              <View style={styles.tableItemActionTitle}>
                <Text style={styles.tableCellLeft}>Offset Entity:</Text>
              </View>
              <View style={styles.tableItemActionContent}>
                <Text style={styles.tableCellRight}>{badgeInfo.offsetEntity}</Text>
              </View>
            </View>
          )}

          {badgeInfo.beneficiary !== '' && (
            <View style={styles.tableRow}>
              <View style={styles.tableItemActionTitle}>
                <Text style={styles.tableCellLeft}>Beneficiary:</Text>
              </View>
              <View style={styles.tableItemActionContent}>
                <Text style={styles.tableCellRight}>
                  {badgeInfo.beneficiary === ZERO_ADDRESS ? badgeInfo.offsetEntity : badgeInfo.beneficiary}
                </Text>
              </View>
            </View>
          )}

          {badgeInfo.offsetEntityID !== '' && (
            <View style={styles.tableRow}>
              <View style={styles.tableItemActionTitle}>
                <Text style={styles.tableCellLeft}>Offset Entity Name:</Text>
              </View>
              <View style={styles.tableItemActionContent}>
                <Text style={styles.tableCellRight}>{badgeInfo.offsetEntityID}</Text>
              </View>
            </View>
          )}

          {badgeInfo.beneficiaryID !== '' && (
            <View style={styles.tableRow}>
              <View style={styles.tableItemActionTitle}>
                <Text style={styles.tableCellLeft}>Beneficiary Name:</Text>
              </View>
              <View style={styles.tableItemActionContent}>
                <Text style={styles.tableCellRight}>{badgeInfo.beneficiaryID}</Text>
              </View>
            </View>
          )}

          {badgeInfo.offsetMessage !== '' && (
            <View style={styles.tableRow}>
              <View style={styles.tableItemActionTitle}>
                <Text style={styles.tableCellLeft}>Offset Message:</Text>
              </View>
              <View style={styles.tableItemActionContent}>
                <Text style={styles.tableCellRight}>{badgeInfo.offsetMessage}</Text>
              </View>
            </View>
          )}

          <View style={styles.tableRow}>
            <View style={styles.tableItemActionTitle}>
              <Text style={styles.tableCellLeft}>Badge Creation Time:</Text>
            </View>
            <View style={styles.tableItemActionContent}>
              <Text style={styles.tableCellRight}>{retirementTimeStr}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableItemActionTitle}>
              <Text style={styles.tableCellLeft}>Offset Amount:</Text>
            </View>
            <View style={styles.tableItemActionContent}>
              <Text style={styles.tableCellRight}>{userBadgeAmountString}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableItemActionTitle}>
              <Text style={styles.tableCellLeft}>Number of Actions:</Text>
            </View>
            <View style={styles.tableItemActionContent}>
              <Text style={styles.tableCellRight}>
                {badgeInfo.offsetIds.length}
                {actionListString.substring(0, actionListString.length - 1)} )
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.sectionTitle}>
          <Text> # Climate Actions </Text>
        </View>
        {badgeInfo.offsetIds.map(actionId => {
          const actionIdn = actionId.toNumber()
          const offsetAction = allOffsetActionsIDJson[actionIdn]
          const offsetType = offsetAction.tokenId.lt(BigNumber.from('0x8000000000000000'))
            ? 'REDEEM'
            : offsetAction.tokenId.lt(BigNumber.from('0xC000000000000000'))
            ? 'OFFSET'
            : 'DETAILS'
          const tokenId = offsetAction.tokenId.and(BigNumber.from('0xFFFFFFFFFFFFFF')).toNumber()
          const amountAction = new Fraction(offsetAction.amount.toString(), JSBI.BigInt(1000000))
          const amountActionString = (amountAction.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')
          const timeAction = DateTime.fromSeconds(offsetAction.createdAt.toNumber())
          const timeActionString = timeAction.toRFC2822()
          const offsetDetails = offsetType === 'DETAILS' ? allOffsetDetails[tokenId] : undefined

          const bookmark = `#NFTID_+${tokenId.toString()}`

          return (
            <View key={actionId.toString()}>
              <View style={styles.tableAction} wrap={false}>
                <View style={styles.tableRow}>
                  <View style={styles.tableActionTitle}>
                    <Text>Climate Action: &nbsp;&nbsp;</Text>
                    <Text style={styles.textBold}>#{actionIdn.toString()}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>Offset Entity:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{offsetAction.offsetEntity} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>REC Issuer Address:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{offsetAction.issuerREC} </Text>
                  </View>
                </View>

                {offsetType === 'REDEEM' && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>Redeemed AREC NFT ID:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>
                        <Link style={styles.CID} src={bookmark}>
                          {tokenId}
                        </Link>
                      </Text>
                    </View>
                  </View>
                )}

                {offsetType === 'REDEEM' && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>Redeemed AREC NFT Amount:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>{allARECAmountString[tokenId]}</Text>
                    </View>
                  </View>
                )}

                {offsetType === 'OFFSET' && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>Offset AREC NFT ID:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>
                        <Link style={styles.CID} src={bookmark}>
                          {tokenId}
                        </Link>
                      </Text>
                    </View>
                  </View>
                )}

                {offsetType === 'OFFSET' && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>Offset AREC NFT Amount:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>{allARECAmountString[tokenId]}</Text>
                    </View>
                  </View>
                )}

                {offsetType === 'DETAILS' && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>Action Details ID:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>
                        {tokenId} ( {offsetDetails?.length} Detail Items )
                      </Text>
                    </View>
                  </View>
                )}

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>Amount of Climate Action:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{amountActionString}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>Time of Climate Action:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{timeActionString}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>Used to mint badge:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{offsetAction.bClaimed ? 'Yes' : 'No'}</Text>
                  </View>
                </View>
              </View>

              {offsetType === 'DETAILS' &&
                offsetDetails !== undefined &&
                offsetDetails.map((offsetDetail: OffsetDetail, index) => {
                  const detailTokenId = offsetDetail.tokenId.toNumber()
                  const offsetAmount = new Fraction(offsetDetail.amount.toString(), JSBI.BigInt(1000_000))
                  const offsetAmountString = (
                    offsetAmount?.toFixed(6, {
                      groupSeparator: ',',
                      fractionGroupSize: 3,
                      fractionGroupSeparator: ' '
                    }) ?? '0'
                  ).concat(' KWH')
                  const bookmark = `#NFTID_+${offsetDetail.tokenId.toString()}`
                  return (
                    <View style={styles.tableDetail} wrap={false} key={'offsetDetails' + index.toString()}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableActionTitle}>
                          <Text>Detail Item: &nbsp;&nbsp;</Text>
                          <Text style={styles.textBold}>#{(index + 1).toString()}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableItemActionTitle}>
                          <Text style={styles.tableCellLeft}>Offset AREC NFT ID:</Text>
                        </View>
                        <View style={styles.tableItemActionContent}>
                          <Text style={styles.tableCellRight}>
                            <Link style={styles.CID} src={bookmark}>
                              {detailTokenId}
                            </Link>
                          </Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableItemActionTitle}>
                          <Text style={styles.tableCellLeft}>Offset AREC NFT Amount:</Text>
                        </View>
                        <View style={styles.tableItemActionContent}>
                          <Text style={styles.tableCellRight}>{allARECAmountString[detailTokenId]}</Text>
                        </View>
                      </View>
                      <View style={styles.tableRow}>
                        <View style={styles.tableItemActionTitle}>
                          <Text style={styles.tableCellLeft}>Amount of AREC Offset:</Text>
                        </View>
                        <View style={styles.tableItemActionContent}>
                          <Text style={styles.tableCellRight}>{offsetAmountString}</Text>
                        </View>
                      </View>
                    </View>
                  )
                })}
            </View>
          )
        })}

        <View style={styles.sectionTitle}>
          <Text> # AREC NFTs </Text>
        </View>
        {Object.keys(allARECDataJson).map(idAREC => {
          const RECData = allARECDataJson[Number(idAREC)]
          const amountARECNFT = new Fraction(RECData.amountREC.toString(), JSBI.BigInt(1000000))
          const amountARECNFTString = (amountARECNFT.toFixed(3, { groupSeparator: ',' }) ?? '0').concat(' KWH')
          const bookmark = `NFTID_+${idAREC.toString()}`

          return (
            <View id={bookmark}>
              <View style={styles.tableAction} wrap={false}>
                <View style={styles.tableRow}>
                  <View style={styles.tableActionTitle}>
                    <Text>AREC NFT: &nbsp;&nbsp;</Text>
                    <Text style={styles.textBold}>#{idAREC.toString()}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>AREC NFT Serial Number:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{RECData.serialNumber} </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>AREC NFT Amount:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{amountARECNFTString}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>AREC Start Time:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{DateTime.fromSeconds(RECData.startTime).toRFC2822()}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>AREC End Time:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>{DateTime.fromSeconds(RECData.endTime).toRFC2822()}</Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableItemActionTitle}>
                    <Text style={styles.tableCellLeft}>AREC CID:</Text>
                  </View>
                  <View style={styles.tableItemActionContent}>
                    <Text style={styles.tableCellRight}>
                      <Link src={RECData.url} style={styles.CID}>
                        {RECData.cID}
                      </Link>
                    </Text>
                  </View>
                </View>

                {!!RECData.memo && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableItemActionTitle}>
                      <Text style={styles.tableCellLeft}>AREC Memo:</Text>
                    </View>
                    <View style={styles.tableItemActionContent}>
                      <Text style={styles.tableCellRight}>{RECData.memo}</Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          )
        })}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber - 1} / ${totalPages - 1}`}
          fixed
        />
      </MyPage>
    </MyDocument>
  )
}

/*
  const retirementTime = DateTime.fromSeconds(badgeInfo.creationTime.toNumber())
  //const retirementTimeStr = retirementTime.toFormat('yyyy-MM-dd HH:mm:ss')
  const retirementTimeStr = retirementTime.toRFC2822()

export interface RECData {
  issuer: string
  serialNumber: string
  minter: string
  startTime: number
  endTime: number
  amountREC: BigNumber
  status: number
  cID: string
  region: string
  url: string
  memo: string
  idAsset: number
}
*/
