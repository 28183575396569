import { WalletConnect, WalletConnectConstructorArgs } from '@web3-react/walletconnect-v2'
import { SupportedChainId } from 'constants/chains'
import { Z_INDEX } from 'theme/zIndex'
import { RPC_URLS } from '../constants/networks'
import { getDefaultNetwork } from '.'

export class WalletConnectV2 extends WalletConnect {
  constructor({
    actions,
    onError,
    qrcode = true
  }: Omit<WalletConnectConstructorArgs, 'options'> & { qrcode?: boolean }) {
    const darkmode = window?.matchMedia('(prefers-color-scheme: dark)').matches
    const walletNetwork = getDefaultNetwork()

    super({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
        // optionalChains: [SupportedChainId.POLYGON_MUMBAI, SupportedChainId.POLYGON, SupportedChainId.MAINNET],
        optionalChains: [
          SupportedChainId.POLYGON,
          SupportedChainId.POLYGON_MUMBAI,
          SupportedChainId.CELO,
          SupportedChainId.CELO_ALFAJORES
        ],
        chains: [walletNetwork],
        showQrModal: qrcode,
        rpcMap: RPC_URLS,
        optionalMethods: ['eth_signTypedData', 'eth_signTypedData_v4', 'eth_sign'],
        qrModalOptions: {
          enableExplorer: true,
          themeMode: darkmode ? 'dark' : 'light',
          themeVariables: {
            '--wcm-font-family': '"Inter custom", sans-serif',
            '--wcm-z-index': Z_INDEX.modal.toString()
          }
        }
      },
      onError
    })
  }
}
