import { Currency, Percent, Price } from '@arec/sdk'
import { useContext, useMemo } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { RowBetween } from '../../components/Row'
import { ONE_BIPS, ZERO_PERCENT } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { SeparatorDark } from '../../components/SearchModal/styleds'
import { useWeb3React } from '@web3-react/core'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: { [field in Field]?: Percent }
  price?: { [field in Field]?: Price }
}) {
  const { chainId } = useWeb3React()
  const theme = useContext(ThemeContext)
  const pricePromptAB = useMemo(() => {
    if (!price || !price[Field.CURRENCY_A]) return '-'
    return price[Field.CURRENCY_A]?.lessThan('1')
      ? `${price[Field.CURRENCY_A]?.invert()?.toSignificant(6)} : 1`
      : `1 : ${price[Field.CURRENCY_A]?.toSignificant(6)}`
  }, [price])

  const pricePromptBA = useMemo(() => {
    if (!price || !price[Field.CURRENCY_B]) return '-'
    return price[Field.CURRENCY_B]?.greaterThan('1')
      ? `${price[Field.CURRENCY_B]?.invert()?.toSignificant(6)} : 1`
      : `1 : ${price[Field.CURRENCY_B]?.toSignificant(6)}`
  }, [price])

  return (
    <AutoColumn gap="md">
      <RowBetween>
        <AutoColumn justify="center">
          <TYPE.Black>{pricePromptAB}</TYPE.Black>
          <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
            Price of {currencies[Field.CURRENCY_A]?.getSymbol(chainId)} 🔗{' '}
            {currencies[Field.CURRENCY_B]?.getSymbol(chainId)}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.Black>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.[Field.CURRENCY_A]?.equalTo(ZERO_PERCENT)
                  ? '0'
                  : poolTokenPercentage?.[Field.CURRENCY_A]?.lessThan(ONE_BIPS)
                  ? '<0.01'
                  : poolTokenPercentage?.[Field.CURRENCY_A]?.toFixed(2)) ?? '0'}
            %
          </TYPE.Black>
          <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
            Liquidity Share of the Pool
          </Text>
        </AutoColumn>
      </RowBetween>
      <SeparatorDark />
      <RowBetween>
        <AutoColumn justify="center">
          <TYPE.Black>{pricePromptBA}</TYPE.Black>
          <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
            Price of {currencies[Field.CURRENCY_B]?.getSymbol(chainId)} 🔗{' '}
            {currencies[Field.CURRENCY_A]?.getSymbol(chainId)}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <TYPE.Black>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.[Field.CURRENCY_B]?.equalTo(ZERO_PERCENT)
                  ? '0'
                  : poolTokenPercentage?.[Field.CURRENCY_B]?.lessThan(ONE_BIPS)
                  ? '<0.01'
                  : poolTokenPercentage?.[Field.CURRENCY_B]?.toFixed(2)) ?? '0'}
            %
          </TYPE.Black>
          <Text fontWeight={500} fontSize={14} color={theme.text2} pt={1}>
            Liquidity Share of the Pool
          </Text>
        </AutoColumn>
      </RowBetween>
    </AutoColumn>
  )
}
