import { useCallback, useContext, useEffect, useState, useMemo, ChangeEvent } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonLight, ButtonConfirmed } from '../../components/Button'
import { RowBetween } from '../../components/Row' //
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import { TYPE } from '../../theme'
import { StyledPageCard } from '../../components/earn/styled'
import { M, BridgerHeadButton } from '../../components/ARecIssuance' // ExchangeHeadButton
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from '../../state/application/hooks'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
//import { useMedia } from 'react-use'
import { Container } from 'components/CurrencyInputPanel'
import { useFontSize } from 'hooks/useWindowSize'
import { DateTime } from 'luxon'
import { useRECIssuanceContract } from '../../hooks/useContract'
import { useETHBalances } from '../../state/wallet/hooks'
import {
  ErrorPromptModal,
  ARECSelect,
  ARECOption,
  getPowerString,
  DetailedARECInfoUpdate
} from 'components/ARecIssuance'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { calculateGasMargin } from '../../utils'
import { useGetUserARECList } from '../../state/issuance/hooks'
import { RECData, REC_STATUS } from '../../state/issuance/hooks'
import { useAtomValue } from 'jotai'
import { isIssuer } from 'state/arec/updater'

export function BridgeCompleteInfo() {
  return (
    <>
      <Text>
        <M>1.</M> Redeem the REC asset, and state that your redemption aims to bridge REC onto blockchain. Then acquire
        the redemption proof from the REC organization
      </Text>
      <Text>
        <M>2.</M> Submit the redemption proof file to Arkreen. Arkreen will check the proof file first. If no problem,
        Arkreen will store it to Filecoin and generate the CID of the file. Obtain the CID from Arkreen.
      </Text>
      <Text>
        <M>3.</M> In AREC Dapp, supplememt the missing data item, including:{' '}
        <M>REC starting date, REC ending date, REC region, REC proof file CID, URL of REC proof file</M>, and some memo
        that will be recorded into the AREC NFT.
      </Text>
      <Text>
        <M>4</M> Click the button <M>Update REC Issuance Details</M> to update the details into AREC NFT.
      </Text>
      <Text>
        <M>5.</M> Arkreen will periodly check the AREC information, while all the data items are supplemented, Arkreen
        will check them against the redemption proof file. If all the data items are correct, Arkreen will cerify the
        AREC issuance.
      </Text>
    </>
  )
}

export default function Complete() {
  //const below540 = useMedia('(max-width: 540px)')
  const { account } = useWeb3React()
  const theme = useContext(ThemeContext)
  const addTransaction = useTransactionAdder()
  const showBridge = useAtomValue(isIssuer)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const arkreenRECIssuanceContract = useRECIssuanceContract(true)

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  // modal and loading
  const [{ errorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const { numberOfARECNft, allARECInfo, allARECNftTokensID } = useGetUserARECList()

  const [arecSelected, setARECSelected] = useState<number | undefined>()

  useEffect(() => {
    if (arecSelected === undefined && !!allARECInfo && allARECInfo.length !== 0) {
      setARECSelected(0)
    }
  }, [arecSelected, allARECInfo])

  const onARECSelect = useCallback(
    (arecSelect: ChangeEvent<HTMLSelectElement>) => {
      setARECSelected(Number(arecSelect.target.value))
    },
    [setARECSelected]
  )

  const recPowerList = allARECInfo.map((recData: RECData) => {
    return getPowerString(recData.amountREC)
  })

  const recStatusList = allARECInfo.map((recData: RECData) => {
    const recStatus =
      recData?.status === REC_STATUS.Pending
        ? 'Pending'
        : recData?.status === REC_STATUS.Certified
        ? 'Certified'
        : recData?.status === REC_STATUS.Cancelled
        ? 'Cancelled'
        : recData?.status === REC_STATUS.Rejected
        ? 'Rejected'
        : ' '
    return recStatus
  })

  const ethBalance = useETHBalances(account ? [account] : [])

  const ifEmptyWallet = useMemo(() => {
    if (!ethBalance || !account || !ethBalance[account]) return false
    return ethBalance[account]?.equalTo('0') ? true : false
  }, [ethBalance, account])

  const [newStartDate, setNewStartDate] = useState<string>('')
  const [newEndDate, setNewEndDate] = useState<string>('')
  const [cID, setcID] = useState<string>('')
  const [region, setRegion] = useState<string>('')
  const [url, setUrl] = useState<string>('')
  const [memo, setMemo] = useState<string>('')

  async function onUpdateESGAREC() {
    if (!arkreenRECIssuanceContract || arecSelected === undefined) return

    const tokenID = allARECNftTokensID[arecSelected]

    const startTime = newStartDate === '' ? 0 : DateTime.fromFormat(newStartDate, 'yyyy-MM-dd').toSeconds()
    //    newStartDate === '' ? 0 : DateTime.fromFormat(newStartDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds()

    const endTime = newEndDate === '' ? 0 : DateTime.fromFormat(newEndDate, 'yyyy-MM-dd').toSeconds() + 24 * 3600 - 1
    //      newEndDate === '' ? 0 : DateTime.fromFormat(newEndDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds() + 24 * 3600 - 1

    // console.log('AAAAAAAAAA', tokenID, startTime, endTime, cID, region, url, memo)

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, errorMessage: undefined, txHash: undefined })
    await arkreenRECIssuanceContract.estimateGas['updateRECDataExt'](
      tokenID,
      startTime,
      endTime,
      cID,
      region,
      url,
      memo
    )
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .updateRECDataExt(tokenID, startTime, endTime, cID, region, url, memo, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then(async (response: TransactionResponse) => {
            setIsWaitingWallet(false)
            setARECSelected(undefined)
            addTransaction(response, {
              summary: `update REC Data Ext, Token ID: ${tokenID}`
            })
            setARECTxnState({
              attemptingTxn: false,
              errorMessage: undefined,
              txHash: response.hash
            })
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error(`Request AREC Issuance failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Request AREC Issuance failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Request AREC Issuance failed:', error)
        const dataMsg = error?.data?.message ? ' Details: ' + error.data.message : ''
        setARECTxnState({
          attemptingTxn: false,
          errorMessage: error.message + dataMsg,
          txHash: undefined
        })
      })
  }

  const { font16, font20 } = useFontSize()

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, errorMessage: undefined, txHash })
  }

  return (
    <>
      <BridgerHeadButton path={'complete'} />
      <AppBody>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={'Complete bridge'}>
            <QuestionHelper bkgOff={true} text={'Update REC Details:'} info={<BridgeCompleteInfo />} />
          </PageHeader>

          <Wrapper id="swap-page">
            <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
            {numberOfARECNft !== undefined && numberOfARECNft !== 0 && (
              <Container>
                <RowBetween align="center" height="20px" style={{ padding: '0.6rem 1rem 0.2rem 1rem' }}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={16}>
                    <strong>AREC NFT List:</strong>
                  </TYPE.Body>
                </RowBetween>
                <div style={{ margin: '0.8rem 0.6rem 0.6rem' }}>
                  <ARECSelect
                    size={4}
                    itemSelected={true}
                    value={arecSelected ? arecSelected.toString() : '0'}
                    onChange={onARECSelect}
                    fontSize={font16}
                  >
                    {allARECInfo.map((recData: RECData, index) => {
                      const optionTextID = '0000'.concat(allARECNftTokensID[index].toString())
                      return (
                        <ARECOption key={optionTextID} value={index}>
                          {'AREC_'.concat(optionTextID.substring(optionTextID.length - 4)).concat(':')}
                          {'   '}
                          {recPowerList[index]} {`   `} {recStatusList[index]}
                        </ARECOption>
                      )
                    })}
                  </ARECSelect>
                </div>
                {allARECInfo[0] && arecSelected !== undefined && (
                  <div style={{ padding: '0rem 0.6rem 0.6rem 0.6rem' }}>
                    <DetailedARECInfoUpdate
                      recData={allARECInfo[arecSelected]}
                      newStartDate={newStartDate}
                      newEndDate={newEndDate}
                      setNewStartDate={setNewStartDate}
                      setNewEndDate={setNewEndDate}
                      cID={cID}
                      setcID={setcID}
                      region={region}
                      setRegion={setRegion}
                      url={url}
                      setUrl={setUrl}
                      memo={memo}
                      setMemo={setMemo}
                    />
                  </div>
                )}
              </Container>
            )}

            <BottomGrouping>
              <BottomGrouping>
                {!account ? (
                  <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                ) : (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={onUpdateESGAREC}
                      disabled={
                        isWaitingWallet ||
                        !showBridge ||
                        ifEmptyWallet ||
                        arecSelected === undefined ||
                        allARECInfo[arecSelected].idAsset === 0 ||
                        (allARECInfo[arecSelected].status !== REC_STATUS.Pending &&
                          allARECInfo[arecSelected].status !== REC_STATUS.Rejected)
                      }
                      altDisabledStyle={isWaitingWallet} // show solid button while waiting
                      padding={'18px 0px'}
                    >
                      <Text fontSize={font20} fontWeight={500}>
                        {!showBridge
                          ? 'Not Qualified Issuer'
                          : ifEmptyWallet
                          ? 'Wallet Empty'
                          : arecSelected !== undefined && allARECInfo[arecSelected].status === REC_STATUS.Certified
                          ? 'AREC Certified'
                          : arecSelected !== undefined && allARECInfo[arecSelected].status === REC_STATUS.Cancelled
                          ? 'AREC Cancelled'
                          : arecSelected !== undefined && allARECInfo[arecSelected].idAsset === 0
                          ? 'AREC NFT Not Updateable'
                          : 'Update REC Issuance Details'}
                      </Text>
                    </ButtonConfirmed>
                  </RowBetween>
                )}
              </BottomGrouping>
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}
