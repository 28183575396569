import { ApolloClient, InMemoryCache } from '@apollo/client'
import gql from 'graphql-tag'
import { ChainId } from '@arec/sdk'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { usePortBankState } from 'state/port/hooks'
import { useTestMode } from 'state/user/hooks'

export const arecPortClientSimu = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/lu-derik/arec-port',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const arecPortClientMumbaiDev = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/lu-derik/arec-port-mumbai-dev',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const arecPortClientAmoy = new ApolloClient({
  uri: 'https://api.studio.thegraph.com/query/44303/arec-port-amoy-dev/version/latest',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const arecPortClientNorm = new ApolloClient({
  //uri: 'https://api.thegraph.com/subgraphs/name/lu-derik/arec-port-matic',
  uri: 'https://api.studio.thegraph.com/query/44303/arec-port-polygon/version/latest',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const arecPortClientCeloTest = new ApolloClient({
  //uri: 'https://api.thegraph.com/subgraphs/name/lu-derik/arec-port-celo',
  uri: 'https://api.studio.thegraph.com/query/44303/arec-port-celo-alfajores/version/latest',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const arecPortClientCeloMain = new ApolloClient({
  //uri: 'https://api.thegraph.com/subgraphs/name/lu-derik/arec-port-celo-mainnet',
  uri: 'https://api.studio.thegraph.com/query/44303/arec-port-celo/version/latest',
  cache: new InMemoryCache()
  //  shouldBatch: true
})

export const GET_BANK_INFO = gql`
  query arecBank {
    arecbank(id: "ARECBank") {
      id
      timeTrx
      blockHeight
      numARTTokens
      ARTTokens
    }
  }
`

export const GET_ART_INFO = gql`
  query arecArtInfo($num: Int!) {
    arttokens(first: $num) {
      id
      totalAmountDeposit
      totalAmountSold
      numPaymentToken
      paymentTokens
    }
  }
`

export const GET_ART_SALE_INFO = gql`
  query arttokenSaleInfo($id: String!) {
    arttokenSale(id: $id) {
      id
      saleIncomes
      salePrices
      timesOfSale
      withdrawValues
    }
  }
`

export interface BankInfo {
  id: string | undefined
  timeTrx: string | undefined
  blockHeight: string | undefined
  numARTTokens: number | undefined
  ARTTokens: string[] | undefined
}

export interface bankInfoGraph {
  arecbank: BankInfo
}

export interface ArtPaymentInfo {
  id: string
  totalAmountDeposit: bigint // it is string actually, but it works this way
  totalAmountSold: bigint // it is string actually, but it works this way
  numPaymentToken: number
  paymentTokens: string[]
}

export interface ArtPaymentInfoGraph {
  arttokens: ArtPaymentInfo[]
}

export interface AllArtPaymentInfo {
  [arttoken: string]: ArtPaymentInfo
}

export interface ARTTokenSaleInfo {
  id: string
  salePrices: BigInt
  timesOfSale: BigInt
  saleIncomes: BigInt
  withdrawValues: BigInt
}

export interface ARTTokenSaleInfoGraph {
  arttokenSale: ARTTokenSaleInfo
}

export interface AllARTTokenSaleInfo {
  [arttokenSale: string]: ARTTokenSaleInfo
}

export async function fetchARECBankInfo(chainId: ChainId, testMode: string): Promise<BankInfo> {
  try {
    let portClient = arecPortClientSimu
    if (chainId === ChainId.MATIC) portClient = arecPortClientNorm
    else if (chainId === ChainId.MATIC_AMOY_TESTNET) portClient = arecPortClientAmoy
    else if (chainId === ChainId.CELO_TESTNET) portClient = arecPortClientCeloTest
    else if (chainId === ChainId.CELO) portClient = arecPortClientCeloMain
    else if (testMode === 'Dev') portClient = arecPortClientMumbaiDev

    const { data, errors, loading } = await portClient.query<bankInfoGraph>({
      query: GET_BANK_INFO,
      fetchPolicy: 'no-cache'
    })

    if (loading || errors) {
      console.log('Fetch AREC Bank info error: ', errors)
      return {
        id: undefined,
        timeTrx: undefined,
        blockHeight: undefined,
        numARTTokens: undefined,
        ARTTokens: undefined
      }
    }
    // console.log('BBBBBBBBBBBBBBBBBBB', data, data.arecbank)
    return data.arecbank
  } catch (errors) {
    console.log('Fetch AREC Bank info exception: ', errors)
    return { id: undefined, timeTrx: undefined, blockHeight: undefined, numARTTokens: undefined, ARTTokens: undefined }
  }
}

export async function fetchARTPaymentInfo(
  chainId: ChainId,
  counter: number,
  testMode: string
): Promise<AllArtPaymentInfo | undefined> {
  try {
    let portClient = arecPortClientSimu
    if (chainId === ChainId.MATIC) portClient = arecPortClientNorm
    else if (chainId === ChainId.MATIC_AMOY_TESTNET) portClient = arecPortClientAmoy
    else if (chainId === ChainId.CELO_TESTNET) portClient = arecPortClientCeloTest
    else if (chainId === ChainId.CELO) portClient = arecPortClientCeloMain
    else if (testMode === 'Dev') portClient = arecPortClientMumbaiDev

    const { data, errors, loading } = await portClient.query<ArtPaymentInfoGraph>({
      query: GET_ART_INFO,
      variables: {
        num: counter
      },
      fetchPolicy: 'no-cache'
    })

    if (loading || errors) {
      console.log('Fetch ART Payment Info error: ', errors)
      return undefined
    }

    const allArtPaymentInfo = data.arttokens.reduce<AllArtPaymentInfo>((accumulator, artPaymentInfo) => {
      const id = artPaymentInfo.id
      const { __typename, ...rest } = artPaymentInfo as any // remove __typename
      accumulator[id] = rest
      return accumulator
    }, {})

    // console.log('BBBBBBBBBBBBBBBBBBB', data, data.arttokens, allArtPaymentInfo)
    return allArtPaymentInfo
  } catch (errors) {
    console.log('Fetch ART Payment Info exception: ', errors)
    return undefined
  }
}

export async function fetchARTSaleInfo(
  chainId: ChainId,
  idPayment: string,
  testMode: string
): Promise<ARTTokenSaleInfo | undefined> {
  try {
    let portClient = arecPortClientSimu
    if (chainId === ChainId.MATIC) portClient = arecPortClientNorm
    else if (chainId === ChainId.MATIC_AMOY_TESTNET) portClient = arecPortClientAmoy
    else if (chainId === ChainId.CELO_TESTNET) portClient = arecPortClientCeloTest
    else if (chainId === ChainId.CELO) portClient = arecPortClientCeloMain
    else if (testMode === 'Dev') portClient = arecPortClientMumbaiDev

    const { data, errors, loading } = await portClient.query<ARTTokenSaleInfoGraph>({
      query: GET_ART_SALE_INFO,
      variables: {
        id: idPayment
      },
      fetchPolicy: 'no-cache'
    })

    if (loading || errors) {
      console.log('Fetch ART Sales info error: ', errors)
      return undefined
    }

    // console.log('BBBBBBBBBBBBBBBBBBB', data, data.arttokenSale)
    return data.arttokenSale
  } catch (errors) {
    console.log('Fetch ART Sales info exception: ', errors)
    return undefined
  }
}

export function useGetPortBankInfo() {
  const { chainId } = useWeb3React()
  const [testMode] = useTestMode()
  const chainIdMode = chainId ? chainId.toString() + '-' + testMode : undefined

  const { arecbank, updatePortBanktHandler, updateArtPaymentInfoHandler, updateArtSaleInfoHandler } = usePortBankState(
    chainIdMode
  )
  const blockHeight = arecbank?.blockHeight
  const numARTTokens = arecbank?.numARTTokens

  const [latestBlockHeight, setLatestBlockHeight] = useState<string | undefined>(blockHeight)

  useEffect(() => {
    const delay = latestBlockHeight ? 20000 : 10 // If not intialized, do it immediately
    const handler = setTimeout(async () => {
      if (!chainId || !chainIdMode) return
      const bankInfo = await fetchARECBankInfo(chainId, testMode)
      // console.log('XXXXXXXXXXXXXX', bankInfo.blockHeight, latestBlockHeight)
      if (bankInfo.blockHeight !== latestBlockHeight) {
        updatePortBanktHandler(chainIdMode, bankInfo)
      }
    }, delay) // Fresh the bank info every 20s

    return () => {
      clearTimeout(handler)
    }
  }, [chainId, chainIdMode, latestBlockHeight, updatePortBanktHandler, testMode])

  useEffect(() => {
    async function UpdateArtSalesInfo() {
      if (!chainId || !blockHeight || !numARTTokens || !chainIdMode) return

      if (latestBlockHeight === blockHeight) return

      const allArtPaymentInfo = await fetchARTPaymentInfo(chainId, numARTTokens, testMode)
      if (!allArtPaymentInfo) {
        console.log('Fetch AREC ART Information Error!')
        return
      }

      updateArtPaymentInfoHandler(chainIdMode, allArtPaymentInfo)

      const allARTSaleList = Object.keys(allArtPaymentInfo).flatMap(idART => {
        const paymentTokens = allArtPaymentInfo[idART]
        const artId = idART.substring(4) // Remove 'ART-'
        return paymentTokens.paymentTokens.map(paymentToken => {
          const idARTSale = artId + '-' + paymentToken
          return idARTSale
        })
      })
      const allARTSaleInfo = await Promise.all(
        allARTSaleList.map(async (idARTSale: string) => {
          return await fetchARTSaleInfo(chainId, idARTSale, testMode)
        })
      )

      if (!allARTSaleInfo) {
        console.log('Fetch ART Bank Sales Information Error!')
        return
      }
      const index = allARTSaleInfo.findIndex(artTokenSaleInfo => {
        if (!artTokenSaleInfo) return true
        else return false
      })

      if (index !== -1) {
        console.log('Fetch ART Bank Sales Information Error!')
        return
      }

      const allARTTokenSaleInfo = allARTSaleInfo.reduce<AllARTTokenSaleInfo>((accumulator, artTokenSaleInfo) => {
        const id = artTokenSaleInfo?.id as string
        accumulator[id] = artTokenSaleInfo as ARTTokenSaleInfo
        return accumulator
      }, {})

      updateArtSaleInfoHandler(chainIdMode, allARTTokenSaleInfo)
      setLatestBlockHeight(latestBlockHeight)
    }

    UpdateArtSalesInfo()
  }, [
    chainId,
    chainIdMode,
    blockHeight,
    latestBlockHeight,
    numARTTokens,
    updateArtPaymentInfoHandler,
    updateArtSaleInfoHandler,
    testMode
  ])

  return arecbank
}
