import { ChainId } from '@arec/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BSC]: '0xa9193376D09C7f31283C54e56D013fCF370Cd9D9',
  [ChainId.BSC_TESTNET]: '0x06C2De45973Df34DaB22AD0b767d2bE3eca5D178',
  [ChainId.MATIC]: '0xe15F1eDC35e987604C8658aCE8c0A75d4F23a030', // 0x95028E5B8a734bb7E2071F96De89BABe75be9C8E
  [ChainId.MATIC_TESTNET]: '0x0836851450d157FeAb2c84850f7907Ab563006Be', // 0x3a004D2777F3099f428ec45593636BcA69515B85
  [ChainId.MATIC_AMOY_TESTNET]: '0xabaC2B8525D05A56B1D6d821c4eE2d9292bb61B4',
  [ChainId.HARMONY]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.HARMONY_TESTNET]: '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.FANTOM]: '0x22D4cF72C45F8198CfbF4B568dBdB5A85e8DC0B5',
  [ChainId.FANTOM_TESTNET]: '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.HECO]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.HECO_TESTNET]: '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.ARBITRUM]: '0x80C7DD17B01855a6D2347444a0FCC36136a314de',
  [ChainId.ARBITRUM_TESTNET]: '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.AVALANCHE]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.AVALANCHE_TESTNET]: '0xcfcC81C508a8025879a27257cC0f699F9f2016AB',
  [ChainId.OKEX]: '0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3',
  [ChainId.OKEX_TESTNET]: '',
  [ChainId.PALM]: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
  [ChainId.PALM_TESTNET]: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
  [ChainId.MOONBEAM]: '',
  [ChainId.MOONRIVER]: '0x270f2F35bED92B7A59eA5F08F6B3fd34c8D9D9b5',
  [ChainId.XDAI]: '0x67dA5f2FfaDDfF067AB9d5F025F8810634d84287',
  [ChainId.CELO]: '0xB63e71D6FB9F0b3717239474E6BD189930a3F201',
  [ChainId.CELO_TESTNET]: '0xB8663EdC9929D9135E7f6D50f7d3A97862554a72'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
