import { Currency, Pair, WETH, ChainId } from '@arec/sdk'
import { useState, useContext, useCallback, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { useCurrencyFromToken } from '../../hooks/Tokens'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { CheckCircle } from 'react-feather'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
import { useFontSize } from 'hooks/useWindowSize'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { StyledMenu } from 'components/MenuApp'
import { ApplicationModal } from 'state/application/actions'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
//import { ChevronDown, ChevronUp } from 'react-feather'
import { RowBetween } from '../Row'
import CopyTool from 'components/AccountDetails/Copy'
//import { Text } from 'rebass'
//import Modal from '../Modal'

export const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.6rem 0.75rem 0.6rem' : '0.75rem 0.6rem 0.75rem 0.6rem')};
`

export const CurrencySelect = styled.button<{ selected: boolean; disableSelect: boolean }>`
  align-items: center;
  height: 2.5rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme, disableSelect }) =>
    disableSelect ? theme.bg3 : selected ? theme.bg3 : theme.primary1};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 8px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: disableSelect ? default : pointer;
  user-select: none;
  border: none;
  padding: ${({ disableSelect }) => (disableSelect ? '0 0.5rem' : '0 0.5rem')};

  :hover {
    background-color: ${({ selected, disableSelect, theme }) =>
      selected ? (disableSelect ? theme.bg3 : darken(0.15, theme.bg4)) : darken(0.06, theme.primary1)};
  }
`

export const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

export const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '12px')};
  background: transparent;
  z-index: 1;
`

export const InputPanelX = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '12px')};
  background: transparent;
`

//background-color: ${({ theme }) => theme.bg2};

export const Container = styled.div<{ hideInput?: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '8px')};
  border: 1px solid ${({ theme }) => theme.bg5};
  background: transparent;
`
//background-color: ${({ theme }) => theme.bg1};

export const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '1.25rem' : '1rem')};
`

const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.bg3};
  border: 1px solid ${({ theme }) => theme.bg5};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

interface CurrencyInputPanelProps {
  value: string
  exactValue?: string
  onUserInput: (value: string) => void
  onMax?: () => void
  onMin?: () => void
  showMaxButton: boolean
  showMinButton?: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  disableInput?: boolean
  pair?: Pair | null
  pairTokenOrder?: boolean
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
  details?: () => React.ReactNode
}

export default function CurrencyInputPanel({
  value,
  exactValue,
  onUserInput,
  onMax,
  onMin,
  showMaxButton,
  showMinButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  disableInput = false,
  pair = null, // used for double token logo
  pairTokenOrder,
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
  details
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId } = useWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useContext(ThemeContext)

  const decLength = currency?.decimals
  const token0 = pairTokenOrder ? pair?.token1 : pair?.token0
  const token1 = pairTokenOrder ? pair?.token0 : pair?.token1

  const pairCurrency0 = useCurrencyFromToken(token0 ?? WETH[ChainId.MAINNET]) ?? undefined
  const pairCurrency1 = useCurrencyFromToken(token1 ?? WETH[ChainId.MAINNET]) ?? undefined

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const { font14, font16, font24 } = useFontSize()

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              {label === 'APPROVED' ? (
                <CheckCircle color={theme.green1} size={font16} />
              ) : exactValue ? (
                <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                  <TYPE.Body fontWeight={500} fontSize={font16}>
                    {label}
                  </TYPE.Body>
                  <CopyTool toCopy={exactValue} fontSize={font14} />
                </div>
              ) : (
                <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16}>
                  {label}
                </TYPE.Body>
              )}
              {account && (
                <TYPE.Body
                  onClick={() => {
                    if (!disableInput && onMax) onMax()
                  }}
                  color={theme.text2}
                  fontWeight={500}
                  fontSize={font16}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
                    : ' -'}
                </TYPE.Body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '6px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                disabled={disableInput}
                decLength={decLength}
                fontSize={font24 + 'px'}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMinButton && (
                <StyledBalanceMax disabled={disableInput} onClick={onMin}>
                  MIN
                </StyledBalanceMax>
              )}
              {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceMax disabled={disableInput} onClick={onMax}>
                  MAX
                </StyledBalanceMax>
              )}
            </>
          )}
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            disableSelect={disableCurrencySelect}
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pairCurrency0} currency1={pairCurrency1} size={font24} margin={false} />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={font24 + 'px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pairCurrency0?.getSymbol(chainId)}🔗{pairCurrency1?.getSymbol(chainId)}
                </StyledTokenName>
              ) : (
                <StyledTokenName
                  className="token-symbol-container"
                  active={Boolean(currency && currency.getSymbol(chainId))}
                >
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? currency.symbol.slice(0, 4) +
                      '...' +
                      currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                    : currency?.getSymbol(chainId)) || (account ? t('Select a token') : t('No Wallet'))}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {details && details()}
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}

interface CurrencySelectPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  onMin?: () => void
  showMaxButton: boolean
  showMinButton?: boolean
  label?: string
  field: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  currencyOptions: Currency[] | undefined
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  disableInput?: boolean
  pair?: Pair | null
  hideInput?: boolean
  id: string
  customBalanceText?: string
  details?: () => React.ReactNode
}

export function CurrencySelectPanel({
  value,
  onUserInput,
  onMax,
  onMin,
  showMaxButton,
  showMinButton,
  label = 'Input',
  field,
  onCurrencySelect,
  currency,
  currencyOptions,
  disableCurrencySelect = false,
  hideBalance = false,
  disableInput = false,
  id,
  customBalanceText,
  details
}: CurrencySelectPanelProps) {
  //  const { t } = useTranslation()

  const { account } = useWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useContext(ThemeContext)

  const decLength = currency?.decimals

  const { font16, font24 } = useFontSize()

  return (
    <InputPanelX id={id}>
      <Container>
        <LabelRow>
          <RowBetween>
            {label === 'APPROVED' ? (
              <CheckCircle color={theme.green1} size={font16} />
            ) : (
              <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16}>
                {label}
              </TYPE.Body>
            )}
            {account && (
              <TYPE.Body
                onClick={() => {
                  if (!disableInput && onMax) onMax()
                }}
                color={theme.text2}
                fontWeight={500}
                fontSize={font16}
                style={{ display: 'inline', cursor: 'pointer' }}
              >
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
                  : ' -'}
              </TYPE.Body>
            )}
          </RowBetween>
        </LabelRow>
        <InputRow selected={disableCurrencySelect}>
          <NumericalInput
            className="token-amount-input"
            value={value}
            disabled={disableInput}
            decLength={decLength}
            fontSize={font24 + 'px'}
            onUserInput={val => {
              onUserInput(val)
            }}
          />
          {account && currency && showMinButton && (
            <StyledBalanceMax disabled={disableInput} onClick={onMin}>
              MIN
            </StyledBalanceMax>
          )}
          {account && currency && showMaxButton && label !== 'To' && (
            <StyledBalanceMax disabled={disableInput} onClick={onMax}>
              MAX
            </StyledBalanceMax>
          )}

          {currencyOptions && (
            <OptionCurrencySelector
              currencyOptions={currencyOptions}
              onCurrencySelect={onCurrencySelect}
              selectedCurrency={currency ?? undefined}
              field={field}
            />
          )}
        </InputRow>
        {details && details()}
      </Container>
    </InputPanelX>
  )
}

/*
const Chevron = styled.span<{ open: boolean }>`
  padding-top: 1px;
  color: ${({ open, theme }) => (open ? theme.primaryText1 : theme.primary2)};
`
*/

const InternalMenuItem = styled.div`
  flex: 1;
  padding: 8px;
  color: ${({ theme }) => theme.text1};
  border-radius: 8px;

  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalLinkMenuItem = styled(InternalMenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.bg1};
    text-decoration: none;
  }
`

const MenuFlyout = styled.span`
  min-width: 200px;
  max-height: 300px;
  overflow: auto;
  background-color: ${({ theme }) => theme.primary5};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 0.5px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 48px;
  z-index: 1060;
  right: 0px;
`

function CurrencyItem({
  currency,
  option,
  field,
  empty = false
}: {
  currency?: Currency
  option: boolean
  field: string
  empty?: boolean
}) {
  const { chainId, account } = useWeb3React()
  const { font18, font24 } = useFontSize()

  return (
    <Aligner>
      {currency && <CurrencyLogo currency={currency} size={font24 + 'px'} />}
      <StyledTokenName
        className="token-symbol-container"
        style={{ fontSize: option ? font18 : undefined }}
        active={Boolean(currency && currency.getSymbol(chainId))}
      >
        {(currency && currency.symbol && currency.symbol.length > 20
          ? currency.symbol.slice(0, 4) +
            '...' +
            currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
          : currency?.getSymbol(chainId)) ??
          (account ? (field === 'INPUT' ? 'Payment token' : empty ? 'No ART token' : 'ART token') : 'No Wallet')}
      </StyledTokenName>
      {!option && <StyledDropDown selected={!!currency} />}
    </Aligner>
  )
}

export function OptionCurrencySelector({
  currencyOptions,
  onCurrencySelect,
  selectedCurrency,
  field
}: {
  selectedCurrency?: Currency
  currencyOptions: Currency[]
  onCurrencySelect: (currency: Currency) => void
  field: string
}) {
  const { chainId } = useWeb3React()
  const node = useRef<HTMLDivElement>()
  const tokenList = field === 'INPUT' ? ApplicationModal.PAY_TOKENS : ApplicationModal.ART_TOKENS
  const open = useModalOpen(tokenList)
  const toggle = useToggleModal(tokenList)
  useOnClickOutside(node, open ? toggle : undefined)
  //  const { font20 } = useFontSize()

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <CurrencySelect
        selected={!!selectedCurrency}
        className="open-currency-select-button"
        disableSelect={false}
        onClick={toggle}
      >
        <Aligner>
          <CurrencyItem currency={selectedCurrency} field={field} option={false} empty={currencyOptions.length === 0} />
        </Aligner>
      </CurrencySelect>

      {open && currencyOptions.length > 0 && (
        <MenuFlyout>
          {currencyOptions.map((currency: Currency, index) => {
            const optionTextID = index.toString() + '_' + currency.getSymbol(chainId)
            return (
              <InternalLinkMenuItem
                key={optionTextID}
                onClick={() => {
                  onCurrencySelect(currencyOptions[index])
                  toggle()
                }}
              >
                <CurrencyItem currency={currency} field={field} option={true} />
              </InternalLinkMenuItem>
            )
          })}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
