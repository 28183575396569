import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from '../index'
import { updateArecCount, updateTxHash, updateQuickArecId, updateMintARECHash, updateLastQuickTime } from './actions'
import { updateQuickApproval, updateARECID, updateARECInfo } from './actions'

import { BigNumber } from 'ethers'
import { useWeb3React } from '@web3-react/core'

export interface QuickApproval {
  readonly batchId: string
  readonly chainId: number
  readonly owner: string
  readonly spender: string
  readonly token: string
  readonly nonce: BigNumber
  readonly value: BigNumber
  readonly deadline: number
  readonly v: number
  readonly r: string
  readonly s: string
}

export interface InfoARECNFT {
  readonly issuer: string
  readonly startTime: number
  readonly endTime: number
  readonly amountREC: BigNumber
  readonly cID: string
  readonly region: string
  readonly url: string
  // readonly memo: string    // Memo always keep empty
}

export interface CID_AREC {
  readonly batchId: string
  readonly cid: string
  readonly uri: string
}

export function serializeQuickApproval(approval: QuickApproval): string {
  return (
    `${approval.batchId}##${approval.chainId.toString()}##${approval.owner}##` +
    `${approval.spender}##${approval.token}##${approval.nonce.toHexString()}##` +
    `${approval.value.toHexString()}##${approval.deadline.toString()}##` +
    `${approval.v.toString()}##${approval.r}##${approval.s}`
  )
}

export function serializeInfoARECNFT(infoARECNFT: InfoARECNFT): string {
  return (
    `${infoARECNFT.issuer}##${infoARECNFT.startTime.toString()}##${infoARECNFT.endTime.toString()}##` +
    `${infoARECNFT.amountREC.toHexString()}##${infoARECNFT.cID}##` +
    `${infoARECNFT.region}##${infoARECNFT.url}`
  )
}

export function serializeCIDAREC(cidAREC: CID_AREC): string {
  return `${cidAREC.batchId}##${cidAREC.cid}##${cidAREC.uri}`
}

export function deserializeQuickApproval(approval: string | undefined): QuickApproval | undefined {
  if (!approval) return undefined
  const pcs = approval.split('##')
  if (pcs.length !== 11) return undefined

  return {
    batchId: pcs[0],
    chainId: Number(pcs[1]),
    owner: pcs[2],
    spender: pcs[3],
    token: pcs[4],
    nonce: BigNumber.from(pcs[5]),
    value: BigNumber.from(pcs[6]),
    deadline: Number(pcs[7]),
    v: Number(pcs[8]),
    r: pcs[9],
    s: pcs[10]
  }
}

export function deserializeInfoARECNFT(infoARECString: string | undefined): InfoARECNFT | undefined {
  if (!infoARECString) return undefined
  const pcs = infoARECString.split('##')
  if (pcs.length !== 7) return undefined

  return {
    issuer: pcs[0],
    startTime: Number(pcs[1]),
    endTime: Number(pcs[2]),
    amountREC: BigNumber.from(pcs[3]),
    cID: pcs[4],
    region: pcs[5],
    url: pcs[6]
  }
}

export function deserializeCIDAREC(cidString: string | undefined): CID_AREC | undefined {
  if (!cidString) return undefined
  const pcs = cidString.split('##')
  if (pcs.length !== 3) return undefined

  return { batchId: pcs[0], cid: pcs[1], uri: pcs[2] }
}

export function useArecCount() {
  const dispatch = useDispatch<AppDispatch>()

  const { arecCount, txHash } = useSelector<AppState, AppState['arec']>(state => state.arec)

  const updateArecCountHandler = useCallback(
    (newCount: number) => {
      dispatch(updateArecCount(newCount))
    },
    [dispatch]
  )

  const updatetxHash = useCallback(
    (txHash: string | undefined) => {
      dispatch(updateTxHash(txHash))
    },
    [dispatch]
  )

  return { arecCount, txHash, updateArecCountHandler, updatetxHash }
}

export function useQuickArecId() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const quickArecIdAll = useSelector<AppState, AppState['arec']['quickArecId']>(state => state.arec.quickArecId)

  const quickArecId = useMemo(() => {
    if (!chainId || !account || !quickArecIdAll || quickArecIdAll[chainId] === undefined) return undefined
    return quickArecIdAll[chainId][account]
  }, [chainId, account, quickArecIdAll])

  const updateQuickArecNFTId = useCallback(
    (chainId: number, owner: string, quickArecId?: number) => {
      dispatch(updateQuickArecId({ chainId, owner, quickArecId }))
    },
    [dispatch]
  )
  return { quickArecId, updateQuickArecNFTId }
}

export function useLastQuickTime() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const lastQuickTimeAll = useSelector<AppState, AppState['arec']['lastQuickTime']>(state => state.arec.lastQuickTime)

  const lastQuickTime = useMemo(() => {
    if (!chainId || !account || !lastQuickTimeAll || lastQuickTimeAll[chainId] === undefined) return undefined
    return lastQuickTimeAll[chainId][account]
  }, [chainId, account, lastQuickTimeAll])

  const updateLastQuickStartTime = useCallback(
    (chainId: number, owner: string, lastQuickTime?: string) => {
      dispatch(updateLastQuickTime({ chainId, owner, lastQuickTime }))
    },
    [dispatch]
  )
  return { lastQuickTime, updateLastQuickStartTime }
}

export function useMintARECHash() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const hashMintTxAll = useSelector<AppState, AppState['arec']['hashMintTx']>(state => state.arec.hashMintTx)

  const hashMintTx = useMemo(() => {
    if (!chainId || !account || hashMintTxAll[chainId] === undefined) return undefined
    return hashMintTxAll[chainId][account]
  }, [chainId, account, hashMintTxAll])

  const updateMintARECNFTHash = useCallback(
    (chainId: number, owner: string, hashTx?: string) => {
      dispatch(updateMintARECHash({ chainId, owner, hashTx }))
    },
    [dispatch]
  )
  return { hashMintTx, updateMintARECNFTHash }
}

export function useQuickApproval() {
  const { chainId, account } = useWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  const allQuickApproval = useSelector<AppState, AppState['arec']['quickApproval']>(state => state.arec.quickApproval)
  const quickApproval = useMemo(() => {
    if (!chainId || !account || allQuickApproval[chainId] === undefined) return undefined
    return deserializeQuickApproval(allQuickApproval[chainId][account])
  }, [allQuickApproval, chainId, account])

  const infoARECNFTString = useSelector<AppState, AppState['arec']['infoARECNFT']>(state => state.arec.infoARECNFT)
  const infoARECNFT = useMemo(() => {
    if (!chainId || !account || infoARECNFTString[chainId] === undefined) return undefined
    return deserializeInfoARECNFT(infoARECNFTString[chainId][account])
  }, [infoARECNFTString, chainId, account])

  const cidARECNFT = useSelector<AppState, AppState['arec']['cidARECNFT']>(state => state.arec.cidARECNFT)
  const cidWithBatch = useMemo(() => {
    if (!chainId || !account || cidARECNFT[chainId] === undefined) return undefined
    return deserializeCIDAREC(cidARECNFT[chainId][account])
  }, [cidARECNFT, chainId, account])

  const storeQuickApproval = useCallback(
    (chainId: number, owner: string, quickApproval?: QuickApproval) => {
      if (!quickApproval) dispatch(updateQuickApproval({ chainId, owner, approval: undefined }))
      else dispatch(updateQuickApproval({ chainId, owner, approval: serializeQuickApproval(quickApproval) }))
    },
    [dispatch]
  )

  const storeInfoARECNFT = useCallback(
    (chainId: number, owner: string, infoARECNFT?: InfoARECNFT) => {
      if (!infoARECNFT) dispatch(updateARECInfo({ chainId, owner, infoARECNFT: undefined }))
      else dispatch(updateARECInfo({ chainId, owner, infoARECNFT: serializeInfoARECNFT(infoARECNFT) }))
    },
    [dispatch]
  )

  const storeCidARECNFT = useCallback(
    (chainId: number, owner: string, cidAREC?: CID_AREC) => {
      if (!cidAREC) dispatch(updateARECID({ chainId, owner, cidString: undefined }))
      else dispatch(updateARECID({ chainId, owner, cidString: serializeCIDAREC(cidAREC) }))
    },
    [dispatch]
  )

  return { quickApproval, infoARECNFT, cidWithBatch, storeQuickApproval, storeInfoARECNFT, storeCidARECNFT }
}
