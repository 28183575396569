import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from '../index'
import { updateArecBankInfo, updateArtPaymentInfo, updateArtSaleInfo } from './actions'
import {
  BankInfo,
  AllArtPaymentInfo,
  AllARTTokenSaleInfo,
  ArtPaymentInfo,
  ARTTokenSaleInfo
} from '../../pages/Port/PortInfo'

export function usePortBankState(
  chainIdMode?: string
): {
  arecbank: BankInfo | undefined
  updatePortBanktHandler: (chainIdMode: string, newBankInfo: BankInfo) => void
  updateArtPaymentInfoHandler: (chainIdMode: string, newAllArtPaymentInfo: AllArtPaymentInfo) => void
  updateArtSaleInfoHandler: (chainIdMode: string, newAllARTTokenSaleInfo: AllARTTokenSaleInfo) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const arecbank = useSelector<AppState, AppState['port']['arecbank']>(state => state.port.arecbank)

  const updatePortBanktHandler = useCallback(
    (chainIdMode: string, arecbank: BankInfo) => {
      dispatch(updateArecBankInfo({ chainIdMode, arecbank }))
    },
    [dispatch]
  )

  const updateArtPaymentInfoHandler = useCallback(
    (chainIdMode: string, allArtPaymentInfo: AllArtPaymentInfo) => {
      dispatch(updateArtPaymentInfo({ chainIdMode, allArtPaymentInfo }))
    },
    [dispatch]
  )

  const updateArtSaleInfoHandler = useCallback(
    (chainIdMode: string, allARTTokenSaleInfo: AllARTTokenSaleInfo) => {
      dispatch(updateArtSaleInfo({ chainIdMode, allARTTokenSaleInfo }))
    },
    [dispatch]
  )

  return {
    arecbank: chainIdMode ? arecbank[chainIdMode] : undefined,
    updatePortBanktHandler,
    updateArtPaymentInfoHandler,
    updateArtSaleInfoHandler
  }
}

export function useGetArtPaymentInfo(chainIdMode?: string, artToken?: string): ArtPaymentInfo | undefined {
  const allArtPaymentInfo = useSelector<AppState, AppState['port']['allArtPaymentInfo']>(
    state => state.port.allArtPaymentInfo
  )
  if (!artToken || !chainIdMode) return undefined
  return allArtPaymentInfo[chainIdMode]?.['ART-' + artToken.toLowerCase()] ?? undefined
}

export function useGetArtSaleInfo(
  chainIdMode?: string,
  artToken?: string,
  paymentToken?: string
): ARTTokenSaleInfo | undefined {
  const allARTTokenSaleInfo = useSelector<AppState, AppState['port']['allARTTokenSaleInfo']>(
    state => state.port.allARTTokenSaleInfo
  )
  if (!artToken || !paymentToken || !chainIdMode) return undefined
  const idARTTokenSale = artToken.toLowerCase() + '-' + paymentToken.toLowerCase()
  return allARTTokenSaleInfo[chainIdMode]?.[idARTTokenSale] ?? undefined
}
