// eslint-disable-next-line no-restricted-imports
import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

import { getConnection } from 'connection/utils'

import { darken } from 'polished'
import { Activity } from 'react-feather'
import { useAppSelector } from 'state/hooks'

import styled from 'styled-components/macro'

import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { useFontSize } from 'hooks/useWindowSize'
import { getChainInfo } from 'constants/chainInfo'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  :focus {
    outline: none;
  }
`

const Web3Connect = styled.div`
  background-color: ${({ theme }) => theme.warn2};
  width: 100%;
  border-radius: 8px;
  margin: 0px;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  :focus {
    outline: none;
  }
`

const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.2, theme.red1)};
  }
`

const Web3StatusConnect = styled(Web3Connect)<{ faded?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  border: none;
  color: ${({ theme }) => theme.text2};
  font-weight: 400;
  :hover {
    background-color: ${({ theme }) => theme.primary4};
  }
`

const Web3StatusConnected = styled(Web3Connect)<{ pending?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  background-color: ${({ pending, theme }) => (pending ? theme.primary1 : darken(0.03, theme.warn2))};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.warn1)};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border: 0px;
  `};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  font-weight: 500;
  :hover {
    background-color: ${({ pending, theme }) => (pending ? darken(0.05, theme.primary1) : theme.primary4)};
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()
  const connectionType = getConnection(connector).type

  const error = useAppSelector(state => state.connection.errorByConnectionType[getConnection(connector).type])

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  const { font16 } = useFontSize()

  const info = chainId ? getChainInfo(chainId) : undefined

  if (!chainId) {
    return null
  } else if (error || !info) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error ?? !info ? `Wrong Network` : 'Unkown Error'}</Text>
      </Web3StatusError>
    )
  } else if (account) {
    return (
      <Web3StatusConnected
        data-testid="web3-status-connected"
        onClick={toggleWalletModal}
        pending={hasPendingTransactions}
      >
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : (
          <>
            <Text style={{ fontSize: font16 }}> {ENSName || shortenAddress(account)}</Text>
          </>
        )}
        {!hasPendingTransactions && <StatusIcon connectionType={connectionType} />}
      </Web3StatusConnected>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <Text style={{ fontSize: font16 }}>{'Connect to a wallet'}</Text>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
