import { useRef } from 'react'
import { TrendingUp, Repeat, Award, Heart, CheckCircle, LogOut, Sunrise, Link2, FastForward } from 'react-feather'
import { Anchor } from 'react-feather'
import styled from 'styled-components'
import { BasicLink } from '../Link'
import { useHistory } from 'react-router-dom'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { Text } from 'rebass'
import { RowFixed } from '../Row'
import { useFontSize } from 'hooks/useWindowSize'
import { useGetBridgeFeature } from '../../state/user/hooks'

import { useWeb3React } from '@web3-react/core'
import { ChainId } from '@arec/sdk'

const Option = styled.div<{ activeText?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  margin-left: 12px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.text1};
  :hover {
    opacity: 1;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

//color: ${({ theme }) => theme.primaryText1};

export const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin-left: 1rem;
  padding: 0px 10px;
  height: 36px;
  border-radius: 0.5rem;

  svg {
    margin-top: 2px;
  }

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    color: ${({ theme }) => theme.text5};
    background-color: ${({ theme }) => theme.primary1};
  }
`

const StyledMenuAppIcon = styled(Anchor)`
  height: 24px;
  width: 24px;
  stroke: ${({ theme }) => theme.primaryText1};
  ${StyledMenuButton} :hover & {
    stroke: ${({ theme }) => theme.text5};
  }
`

export const StyledMenu = styled.div`
  margin-left: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;
`

export const MenuFlyout = styled.span`
  width: 160px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.primary5};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  left: 0rem;
  z-index: 1060;
`

export default function MenuApp() {
  const history = useHistory()
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MEMU_APP)
  const toggle = useToggleModal(ApplicationModal.MEMU_APP)
  useOnClickOutside(node, open ? toggle : undefined)
  const { font16, font20 } = useFontSize()
  const { chainId } = useWeb3React()

  const bridgeFeature = useGetBridgeFeature()
  const showExchange = false

  const ifCelo = chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET

  // const ifSimple = history.location.pathname === '/quicks' || history.location.pathname === '/quick'
  const ifSimple = ifCelo ? false : true

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <RowFixed>
          <StyledMenuAppIcon />
          <Text fontSize={font20} style={{ padding: '0px 3px' }}>
            AREC
          </Text>
        </RowFixed>
      </StyledMenuButton>

      {open && (
        <MenuFlyout onClick={toggle}>
          <BasicLink to="/overview" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
            <Option activeText={history.location.pathname.split('/')[1] === 'overview'}>
              <TrendingUp size={font16} />
              <Text fontSize={font16}> Overview </Text>
            </Option>
          </BasicLink>

          {!ifCelo && (
            <BasicLink
              to={ifSimple ? '/quicks' : '/quick'}
              draggable="false"
              style={{ padding: '0.5rem 0rem', height: font16 * 2 }}
            >
              <Option activeText={history.location.pathname.split('/')[1] === 'quick'}>
                <FastForward size={font16} />
                <Text fontSize={font16}> Quick Start </Text>
              </Option>
            </BasicLink>
          )}

          {!ifCelo && !ifSimple && (
            <BasicLink to="/Issuance" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'Issuance'}>
                <CheckCircle size={font16} />
                <Text fontSize={font16}> Issuance </Text>
              </Option>
            </BasicLink>
          )}

          {ifCelo && !ifSimple && (
            <BasicLink to="/bridge" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'bridge'}>
                <Link2 size={font16} />
                <Text fontSize={font16}> AREC Bridge </Text>
              </Option>
            </BasicLink>
          )}

          {!ifSimple && (
            <BasicLink to="/Redeem" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'Redeem'}>
                <LogOut size={font16} />
                <Text fontSize={font16}> Redeem </Text>
              </Option>
            </BasicLink>
          )}

          {!ifSimple && (
            <BasicLink to="/Offset" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'Offset'}>
                <Heart size={font16} />
                <Text fontSize={font16}> Offset </Text>
              </Option>
            </BasicLink>
          )}

          {!ifSimple && (
            <BasicLink to="/mintCert" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'mintCert'}>
                <Award size={font16} />
                <Text fontSize={font16}> Badge </Text>
              </Option>
            </BasicLink>
          )}

          {!ifCelo && showExchange && !ifSimple && (
            <BasicLink to="/swap" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'swap' ||
                    history.location.pathname.split('/')[1] === 'add') ??
                  undefined
                }
              >
                <Repeat size={font16} />
                <Text fontSize={font16}> Exchange </Text>
              </Option>
            </BasicLink>
          )}

          {!ifCelo && bridgeFeature === 'on' && !ifSimple && (
            <BasicLink to="/bridge" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'bridge'}>
                <Link2 size={font16} />
                <Text fontSize={font16}> AREC Bridge </Text>
              </Option>
            </BasicLink>
          )}

          {(ifCelo || bridgeFeature === 'on') && !ifSimple && (
            <BasicLink to="/port" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'port'}>
                <Repeat size={font16} />
                <Text fontSize={font16}> AREC Port </Text>
              </Option>
            </BasicLink>
          )}

          {(ifCelo || bridgeFeature === 'on') && !ifSimple && (
            <BasicLink to="/cART" draggable="false" style={{ padding: '0.5rem 0rem', height: font16 * 2 }}>
              <Option activeText={history.location.pathname.split('/')[1] === 'cART'}>
                <Sunrise size={font16} />
                <Text fontSize={font16}> {ifCelo ? `cART Swap` : `AKRE Swap`} </Text>
              </Option>
            </BasicLink>
          )}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
