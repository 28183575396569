import { useContext, useEffect, useState, useMemo, useCallback, ChangeEvent } from 'react'
import { ChainId, Fraction, JSBI } from '@arec/sdk'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonConfirmed, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
import { nanoid } from '@reduxjs/toolkit'
import { useTransactionAdder } from '../../state/transactions/hooks'
import {
  useArkreenTokenContract,
  useRECIssuanceContract,
  useAKREToken,
  useRECIssuerAddress
} from '../../hooks/useContract'
import { splitSignature, solidityPack } from 'ethers/lib/utils'
import { TokenAmount, Token } from '@arec/sdk'
import { BigNumber } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { shortenAddress, shortenCID } from '../../utils'

import { useWeb3React } from '@web3-react/core'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ErrorPromptModal, M } from '../../components/ARecIssuance'
import { Container } from '../../components/CurrencyInputPanel'
import { DateTime } from 'luxon'
import { RECRequest, useFetchARECInfo, useIssueOwnerRec, RequestIssueOwnerRec } from '../../state/issuance/hooks'
import { useIssuancePriceByToken, useMinerListByOwner, useBuyMinerURL, RecCID } from '../../state/issuance/hooks'
import { useArkreenBalance, useETHBalances } from '../../state/wallet/hooks'
import { useGetTestMode } from '../../state/user/hooks'
import { MouseoverTooltip } from '../../components/Tooltip'
import { ExternalLink } from 'theme'

import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import Loader from '../../components/Loader'
import { ExternalLink as LinkIcon, ShoppingCart, CornerDownLeft, CornerRightUp } from 'react-feather'
import { useMedia } from 'react-use'
import { useArecCount, useMintARECHash, useLastQuickTime } from '../../state/arec/hooks'
import { useFontSize } from 'hooks/useWindowSize'
import useDebounce from 'hooks/useDebounce'

export const StyledNumberInput = styled.input`
  width: 100%;
  padding: 0px 18px;
  position: relative;
  outline: none;
  font-weight: 500;
  height: 2.5rem;
  font-size: 24px;
  text-align: end;
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary3};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  :active,
  :focus,
  :hover {
    outline: none;
    border: 2px solid;
    border-color: ${({ theme }) => theme.primary3};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const StyledRange = styled.input<{ size: number }>`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary2};
    border-radius: 100%;
    border: none;
    transform: translateY(-40%);
    color: ${({ theme }) => theme.primary4};
  }

  &::-moz-range-thumb {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary2};
    border-radius: 100%;
    border: none;
    color: ${({ theme }) => theme.primary5};

    &:hover,
    &:focus {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 24px 32px rgba(0, 0, 0, 0.04);
    }
  }

  &::-ms-thumb {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    background-color: ${({ theme }) => theme.primary2};
    border-radius: 100%;
    color: ${({ theme }) => theme.primary5};

    &:hover,
    &:focus {
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 24px 32px rgba(0, 0, 0, 0.04);
    }
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, ${({ theme }) => theme.primary5}, ${({ theme }) => theme.primary5});
    height: 4px;
  }

  &::-moz-range-track {
    background: linear-gradient(90deg, ${({ theme }) => theme.primary5}, ${({ theme }) => theme.primary5});
    height: 4px;
  }

  &::-ms-track {
    width: 100%;
    border-color: transparent;
    color: transparent;

    background: ${({ theme }) => theme.primary5};
    height: 4px;
  }
  &::-ms-fill-lower {
    background: ${({ theme }) => theme.primary5};
  }
  &::-ms-fill-upper {
    background: ${({ theme }) => theme.primary5};
  }
`

export interface ProfileAREC {
  startDate: string
  endDate: string
  maxDate: string
  minerNumber: number
  amountTotalRE: string
  totalPowerOutput: string
  priceToIssueREC: string
  minREAmount: string
  cID: string
  region: string
  url: string
}

export function IssuanceHelpInfo() {
  return (
    <>
      <Text>
        <M>1.</M> Click <M>Approve AKRE</M> to approve AKRE to pay the issuance fee.
      </Text>
      <Text>
        <M>2.</M> Waiting CID of your renewable energy generation data in IPFS system.
      </Text>
      <Text>
        <M>3.</M> Click <M>Mint AREC</M> to request AREC NFT issuance.
      </Text>
      <Text>
        <M>4.</M> Waiting Arkeeen to check and confirm your ARE issuance request.
      </Text>
      <Text>
        <M>5.</M> Click <M>Liquidize AREC NFT</M> to liquidize AREC and mint ART token.
      </Text>
      <Text>
        <M>6.</M> Waiting and checking youe ART token received.
      </Text>
    </>
  )
}

export const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 1rem 0.3rem 1rem;
  background: transparent;

  @media screen and (max-width: 540px) {
    padding: 0.3rem 0.5rem;
  }
`

export const OrderLink = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  font-weight: 500;
  :hover {
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

export const SwapLink = styled(ExternalLink)`
  flex: 1;
  color: ${({ theme }) => theme.sideBarText};
  border-radius: 6px;
  :focus,
  :hover {
    color: ${({ theme }) => theme.sideBar};
    cursor: pointer;
    background-color: ${({ theme }) => theme.sideBarText};
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

/*
const ChangeBtn = styled.div<{ active: boolean }>`
  flex: 1;
  background-color: ${({ active, theme }) => (active ? theme.primary4 : theme.primary5)};
  padding: 4px 8px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
`
*/

export const title: { [phase: number]: string } = {
  [0]: 'Mint ART One-Stop',
  [1]: 'Step 1/6: Approve AKRE',
  [2]: 'Step 2/6: Waiting AREC CID',
  [3]: 'Step 3/6: Mint AREC NFT',
  [4]: 'Step 4/6: Waiting Confirmation',
  [5]: 'Step 5/6: Liquidize AREC NFT',
  [6]: 'Step 6/6: ART Minted'
}

export function IssuanceSteps() {
  const { account, chainId, provider } = useWeb3React()

  //const { chainId, provider } = useWeb3React()
  //const account = ''

  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  const simulationMode = useGetTestMode() === 'Simu'

  const TrialMode = useMemo(() => {
    if (simulationMode && chainId === ChainId.MATIC_TESTNET) return true
    return false
  }, [simulationMode, chainId])

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const arkreenToken = useAKREToken()
  const arkreenBalance: TokenAmount | undefined = useArkreenBalance()

  const priceIssuance = useIssuancePriceByToken(arkreenToken.address)

  const { updatetxHash } = useArecCount()

  const { updateMintARECNFTHash } = useMintARECHash()

  const [random] = useState<BigNumber>(BigNumber.from(Math.floor(Math.random() * 10000000000)))

  const { lastQuickTime, updateLastQuickStartTime } = useLastQuickTime()

  //const now = DateTime.now().toSeconds()
  //const ifLockedForLaunch = now >= 1708992000 && now < 1709078400 // 1709020800 1708963200
  const ifLockedForLaunch = false

  const recProfileInit: ProfileAREC = useMemo(() => {
    const dateNow = DateTime.now()
      .minus({ days: 2 })
      .toFormat('yyyy-MM-dd')

    return {
      startDate: '2022-09-03',
      endDate: dateNow,
      maxDate: dateNow,
      minerNumber: 2,
      amountTotalRE: BigNumber.from('12312000000')
        .add(random)
        .toString(),
      totalPowerOutput: '12312000000',
      priceToIssueREC: priceIssuance ? priceIssuance.toString() : '100000000000',
      minREAmount: '1.00',
      cID: 'bafybeia32b37c3owhbuodg5ef4dmn6jc4g2jzamgv22teipbjcrrrpkauy',
      region: 'China',
      url: 'https://bafybeia32b37c3owhbuodg5ef4dmn6jc4g2jzamgv22teipbjcrrrpkauy.ipfs.w3s.link'
    }
  }, [priceIssuance, random])

  //const endDateToMint = '2023-07-24'
  const [endDateToMint, setEndDateToMint] = useState<string | undefined>()

  const debouncedEndDateToMint = useDebounce(endDateToMint, 1000)

  const { errorAREC, dataAREC, endDateReady } = useFetchARECInfo({
    simulationMode,
    owner: account,
    endDate: debouncedEndDateToMint
  })

  const { countMiners, minerList } = useMinerListByOwner(simulationMode, account)

  const minerRECProfile = useMemo(() => {
    if (errorAREC !== '' || dataAREC === undefined) return undefined
    const tempProfile: ProfileAREC = { ...recProfileInit, minerNumber: 0 }
    if (dataAREC.length === 0) {
      return tempProfile
    }
    tempProfile.minerNumber = dataAREC.length

    let startDate = '99990101'
    let endDate = ''
    let maxDate = ''
    // eslint-disable-next-line prefer-const
    let regions: string[] = []
    let amountTotalRE: BigNumber = BigNumber.from(0)
    let totalPowerOutput: BigNumber = BigNumber.from(0)
    for (let i = 0; i < dataAREC.length; i++) {
      if (dataAREC[i].startDate < startDate) {
        startDate = dataAREC[i].startDate
      }
      if (dataAREC[i].endDate > endDate) {
        endDate = dataAREC[i].endDate
      }
      if (dataAREC[i].lastDate > maxDate) {
        maxDate = dataAREC[i].lastDate
      }
      //      amountTotalRE = amountTotalRE.add(BigNumber.from(dataAREC[i].totalREOutput))
      //      totalPowerOutput = totalPowerOutput.add(BigNumber.from(dataAREC[i].totalPowerOutput))

      const totalREOutput = dataAREC[i].totalREOutput.split('-')
      if (totalREOutput.length >= 2) {
        amountTotalRE = amountTotalRE.sub(BigNumber.from(totalREOutput[0] + totalREOutput[1]))
      } else {
        amountTotalRE = amountTotalRE.add(BigNumber.from(dataAREC[i].totalREOutput))
      }

      const totalPowerOutputSplit = dataAREC[i].totalPowerOutput.split('-')

      if (totalPowerOutputSplit.length >= 2) {
        totalPowerOutput = totalPowerOutput.sub(BigNumber.from(totalPowerOutputSplit[0] + totalPowerOutputSplit[1]))
      } else {
        totalPowerOutput = totalPowerOutput.add(BigNumber.from(dataAREC[i].totalPowerOutput))
      }

      if (!regions.includes(dataAREC[i].region)) {
        regions.push(dataAREC[i].region)
      }
    }
    tempProfile.startDate = DateTime.fromFormat(startDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.endDate = DateTime.fromFormat(endDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.maxDate = DateTime.fromFormat(maxDate, 'yyyyMMdd').toFormat('yyyy-MM-dd')
    tempProfile.amountTotalRE = amountTotalRE.toString()
    tempProfile.totalPowerOutput = totalPowerOutput.toString()
    tempProfile.region = regions.join(',')
    tempProfile.cID = ''
    return tempProfile
  }, [errorAREC, dataAREC, recProfileInit])

  const recProfile = TrialMode ? recProfileInit : minerRECProfile ///////

  const ifwaiting = endDateReady == 'MAX' || recProfile?.endDate == endDateToMint ? false : true

  const [amountTotalREAvailable, setAmountTotalREAvailable] = useState<string>()

  const daysWithPower = useMemo(() => {
    if (!recProfile) return 0
    const days = DateTime.fromFormat(recProfile.maxDate, 'yyyy-MM-dd').diff(
      DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd'),
      'days'
    ).days
    return days + 1
  }, [recProfile])

  const [daysToIssue, setDaysToIssue] = useState<number>(1)

  const onDaySelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!recProfile) return
      let daysToIssue = Number(event.target.value)
      if (daysToIssue <= 0) daysToIssue = 1
      if (daysToIssue > daysWithPower) daysToIssue = daysWithPower
      setDaysToIssue(daysToIssue)
      const endDate = DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd').plus({ days: daysToIssue - 1 })
      setEndDateToMint(endDate.toFormat('yyyy-MM-dd'))
    },
    [setDaysToIssue, setEndDateToMint, daysWithPower, recProfile]
  )

  useEffect(() => {
    if (!recProfile) return
    if (recProfile.endDate != recProfile.maxDate) return
    const amountTotalREAvailable = new Fraction(recProfile.amountTotalRE, JSBI.BigInt(1000000)).toFixed(3)
    setAmountTotalREAvailable(amountTotalREAvailable)
    if (daysToIssue == daysWithPower) return
    setEndDateToMint(
      DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd')
        .plus({ days: 0 })
        .toFormat('yyyy-MM-dd')
    )
  }, [recProfile, daysToIssue, daysWithPower])

  /*
  const [activeBtn, setActiveBtn] = useState<number>(0)

  const onDayChange = useCallback(
    (days: number) => {
      if (!recProfile) return
      let newDaysToIssue = daysToIssue + days
      if (newDaysToIssue <= 0) newDaysToIssue = 1
      if (newDaysToIssue >= daysWithPower) newDaysToIssue = daysWithPower
      setDaysToIssue(newDaysToIssue)
      const endDate = DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd').plus({ days: newDaysToIssue })
      setEndDateToMint(endDate.toFormat('yyyy-MM-dd'))
      setActiveBtn(days + 6)
    },
    [daysToIssue, daysWithPower, setActiveBtn, setDaysToIssue, setEndDateToMint, recProfile]
  )
*/

  const ethBalance = useETHBalances(account ? [account] : [])

  const ifEmptyWallet = useMemo(() => {
    if (!ethBalance || !account || !ethBalance[account]) return false
    return ethBalance[account]?.equalTo('0') ? true : false
  }, [ethBalance, account])

  const arkreenRECIssuanceContract = useRECIssuanceContract(true)

  const approvalAmount: TokenAmount | undefined = useMemo(() => {
    if (!recProfile) return undefined
    const feeToPay = JSBI.multiply(JSBI.BigInt(recProfile.amountTotalRE), JSBI.BigInt(recProfile.priceToIssueREC))
    return new TokenAmount(arkreenToken as Token, feeToPay)
  }, [recProfile, arkreenToken])

  const [signatureData, setSignatureData] = useState<
    { v: number; r: string; s: string; deadline: number } | undefined
  >()

  const ifMoreInput = useMemo(() => {
    if (!approvalAmount || !arkreenBalance) return false
    if (approvalAmount.greaterThan(arkreenBalance)) return true
    return false
  }, [approvalAmount, arkreenBalance])

  const amountTotalRE: BigNumber = recProfile ? BigNumber.from(recProfile.amountTotalRE) : BigNumber.from(0)
  const enableRECMint = amountTotalRE.isZero() ? false : true

  const arkreenTokenContract = useArkreenTokenContract(true)

  const [approval] = useApproveCallback(approvalAmount, arkreenRECIssuanceContract?.address)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field

  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const [{ showConfirm, txnToConfirm, IssueErrorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    IssueErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    IssueErrorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  const MINER_BUY_URL = useBuyMinerURL()

  const RECIssuer = useRECIssuerAddress()

  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  const issueRequest: RequestIssueOwnerRec = useMemo(() => {
    return {
      owner: account ?? undefined,
      issuer: RECIssuer,
      startDate: recProfile?.startDate
        ? DateTime.fromFormat(recProfile?.startDate, 'yyyy-MM-dd').toFormat('yyyyMMdd')
        : undefined,
      endDate: recProfile?.endDate
        ? DateTime.fromFormat(recProfile?.endDate, 'yyyy-MM-dd').toFormat('yyyyMMdd')
        : undefined,
      totalARECPower: amountTotalRE.toHexString(),
      valueApproval: approvalAmount ? BigNumber.from(approvalAmount.raw.toString()).toHexString() : undefined,
      deadlineApproval: signatureData !== undefined ? signatureData?.deadline : undefined,
      signatureApproval:
        signatureData !== undefined
          ? solidityPack(['bytes1', 'bytes32', 'bytes32'], [signatureData.v, signatureData.r, signatureData.s])
          : undefined
    }
  }, [account, RECIssuer, recProfile, amountTotalRE, approvalAmount, signatureData])

  const [batchID, setBatchID] = useState<string | undefined>(nanoid())
  const [arecCID, setArecCID] = useState<RecCID | undefined>()

  const { errorArecCID, arecCID: originArecCID, curBatchID } = useIssueOwnerRec(simulationMode, issueRequest, batchID)

  useEffect(() => {
    if (curBatchID !== batchID) return
    setArecCID(originArecCID)
  }, [originArecCID, curBatchID, batchID])

  async function handleClearMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, IssueErrorMessage: undefined, txHash })
  }

  const recRequest: RECRequest | undefined = useMemo(() => {
    if (!recProfile) return undefined
    if (!TrialMode && (errorArecCID !== '' || !arecCID || recProfile.endDate === '')) return undefined

    return {
      issuer: RECIssuer,
      startTime: BigNumber.from(DateTime.fromFormat(recProfile.startDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds()),
      endTime: BigNumber.from(
        DateTime.fromFormat(recProfile.endDate, 'yyyy-MM-dd', { zone: 'UTC' }).toSeconds() + 24 * 3600 - 1
      ),
      amountREC: BigNumber.from(recProfile.amountTotalRE),
      cID: TrialMode ? recProfile.cID : (arecCID?.cid as string),
      region: recProfile.region,
      url: TrialMode ? recProfile.url : arecCID?.uri ?? '',
      memo: ''
    }
  }, [TrialMode, recProfile, RECIssuer, errorArecCID, arecCID])

  async function handleRECRequest() {
    if (!chainId || !account) return
    if (!arkreenRECIssuanceContract || !arkreenTokenContract || !signatureData || !approvalAmount || !recRequest) return

    // In simulation mode, does not check
    if (!TrialMode && (errorArecCID !== '' || !arecCID)) return ///////////////

    const signatureToPay = [
      arkreenTokenContract.address,
      approvalAmount.raw.toString(),
      signatureData.deadline,
      signatureData.v,
      signatureData.r,
      signatureData.s
    ]

    setIsRequesting(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, IssueErrorMessage: undefined, txHash: undefined })

    await arkreenRECIssuanceContract.estimateGas['mintRECRequest'](recRequest, signatureToPay)
      .then(async estimatedGasLimit => {
        await arkreenRECIssuanceContract
          .mintRECRequest(recRequest, signatureToPay, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then(async (response: TransactionResponse) => {
            updateLastQuickStartTime(chainId, account, DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'))
            updateMintARECNFTHash(chainId, account, response.hash)
            setIsMinting(true)
            setIsRequesting(false)
            setBatchID(nanoid())
            setSignatureData(undefined)
            addTransaction(response, {
              summary: `Request AREC issued by ${shortenAddress(recRequest.issuer, 6)}`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              IssueErrorMessage: undefined,
              txHash: response.hash
            })
            updatetxHash(response.hash)
          })
          .catch((error: any) => {
            setIsRequesting(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Request AREC Issuance failed: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Request AREC Issuance failed: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsRequesting(false)
        console.log('Request AREC Issuance failed:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          IssueErrorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  async function onAttemptToApprove() {
    if (!arkreenTokenContract || !arkreenRECIssuanceContract || !provider || !account || !chainId)
      throw new Error('missing dependencies')
    if (!approvalAmount) throw new Error('missing liquidity amount')

    // try to gather a signature for permission
    //setRandom(BigNumber.from(Math.floor(Math.random() * 10000000000)))

    setIsApproving(true)
    const nonce = await arkreenTokenContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ]
    const domain = {
      name: TrialMode ? 'Arkreen DAO Token' : 'Arkreen Token',
      version: '1',
      chainId: chainId,
      verifyingContract: arkreenTokenContract.address
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' }
    ]

    const usedDeadline = Math.floor(DateTime.now().toSeconds() + 24 * 60 * 60) // signature 24 hours effective

    const message = {
      owner: account,
      spender: arkreenRECIssuanceContract.address,
      value: approvalAmount.raw.toString(),
      nonce: nonce.toNumber(),
      deadline: usedDeadline
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit
      },
      domain,
      primaryType: 'Permit',
      message
    })

    provider
      .send('eth_signTypedData_v4', [account, data])
      .then(splitSignature)
      .then(signature => {
        setIsApproving(false)
        if (simulationMode && chainId == ChainId.MATIC_TESTNET)
          setArecCID({ cid: recProfileInit.cID, uri: recProfileInit.url }) // for simulation
        setSignatureData({
          v: signature.v,
          r: signature.r,
          s: signature.s,
          deadline: usedDeadline
        })
      })
      .catch(error => {
        setIsApproving(false)
        // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
        if (error?.code !== 4001) {
          console.log('User reject the permission!')
          // approveCallback()
        }
        console.log('Error in eth_signTypedData_v4', error)
      })
  }

  const amountTotalREString = new Fraction(amountTotalRE.toString(), JSBI.BigInt(1000000)).toFixed(3)

  const [isMinting, setIsMinting] = useState<boolean>(false)

  const { font14, font16, font20, font24 } = useFontSize()
  const font20Str = font20.toString() + 'px'
  const font24Str = font24.toString() + 'px'

  const [phase, setPhase] = useState<number | undefined>(undefined)

  useEffect(() => {
    function movePhaseUp(newPhase: number) {
      if (phase === undefined || newPhase > phase) setPhase(newPhase)
    }

    if (!chainId || !account) movePhaseUp(0)
    else if (!recProfile || errorAREC !== '' || !approvalAmount) movePhaseUp(0)
    else {
      if (!signatureData) movePhaseUp(1)
      else if (!arecCID) movePhaseUp(2)
      else movePhaseUp(3)
    }
  }, [phase, chainId, account, errorAREC, recProfile, arecCID, approvalAmount, signatureData, isMinting, setIsMinting])

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  // const showApproveFlow = !(!arkreenTokenContract || !provider || !approvalAmount)
  const [cID, shortCID, urlCID] = useMemo(() => {
    const cID = TrialMode ? recProfileInit.cID : arecCID?.cid
    const urlCID = TrialMode ? recProfile?.url : arecCID?.uri ?? ''
    return cID ? [cID, shortenCID(cID), urlCID] : ['', '', '']
  }, [TrialMode, arecCID, recProfileInit, recProfile])

  const swapURL = `https://app.uniswap.org/#/swap?chain=polygon&inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xE9c21De62C5C5d0cEAcCe2762bF655AfDcEB7ab3`

  return (
    <>
      <AppBody style={{ marginTop: below540 ? '30px' : '60px' }}>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={phase !== undefined ? title[phase] : title[0]}>
            {chainId && (
              <QuestionHelper bkgOff={true} text={'Quick steps to mint ART token'} info={<IssuanceHelpInfo />} />
            )}
          </PageHeader>
          <Wrapper id="issuance-page">
            <ErrorPromptModal
              isOpen={!!IssueErrorMessage}
              errString={IssueErrorMessage}
              onDismiss={handleClearMessage}
            />
            {phase !== undefined && phase > 0 && (
              <Row style={{ padding: '4px', justifyContent: 'left', marginTop: '-0.5rem' }}>
                <Text fontWeight={700} fontSize={font16} color={theme.primary1}>
                  Please follow the steps to mint ART quickly
                </Text>
              </Row>
            )}
            <AutoColumn gap={'md'}>
              <Container>
                {TrialMode || !!recProfile ? (
                  <ARECContainer>
                    <RowBetween align="center" height={font24Str}>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Total Miners:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={<>This is the number of the miners you are holding.</>}
                        />
                      </RowFixed>
                      <RowFixed>
                        <Text lineHeight={font20Str} fontWeight={700} fontSize={font14} color={theme.text2}>
                          {countMiners}
                        </Text>

                        <OrderLink id="link" href={MINER_BUY_URL} style={{ padding: '0px 0px 0px 12px' }}>
                          <ButtonPrimary
                            style={{
                              padding: '0px 12px',
                              width: 'fit-content',
                              borderRadius: '6px',
                              color: 'white',
                              alignItems: 'center'
                            }}
                          >
                            <Text lineHeight={font20Str} fontWeight={700} fontSize={font14}>
                              +
                            </Text>
                          </ButtonPrimary>
                        </OrderLink>
                      </RowFixed>
                    </RowBetween>

                    <RowBetween align="center" height={font24Str}>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Earliest AREC Date:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>
                              This is the date your miner(s) started mining, or the earliest date your renewable energy
                              data is available to mint AREC NFT.
                            </>
                          }
                        />
                      </RowFixed>
                      <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text2}>
                        {recProfile?.startDate}
                      </Text>
                    </RowBetween>

                    {phase == 1 && (
                      <RowBetween align="center" height={font24Str}>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                            Latest AREC Date:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>This is the last date your renewable energy data can be mint as an AREC NFT.</>}
                          />
                        </RowFixed>
                        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text2}>
                          ({daysWithPower} Days) {recProfile?.maxDate}
                        </Text>
                      </RowBetween>
                    )}

                    {phase == 1 && (
                      <RowBetween align="center" height={font24Str}>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                            Available RE Amount:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>This is the all renewable energy amount available for minting an AREC NFT.</>}
                          />
                        </RowFixed>
                        <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.text2}>
                          {amountTotalREAvailable} KWH
                        </Text>
                      </RowBetween>
                    )}

                    <RowBetween align="center" height={font24Str}>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          End AREC Date to Mint:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={<>This is the last date of the renewable energy you select to mint your AREC NFT.</>}
                        />
                      </RowFixed>
                      <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {recProfile?.endDate}
                      </Text>
                    </RowBetween>

                    <RowBetween align="center" height={font24Str}>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          AREC Amount to Mint:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={<>This is the renewable energy amount being selected to mint as an AREC NFT.</>}
                        />
                      </RowFixed>
                      <Text lineHeight={font24Str} fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {amountTotalREString} KWH
                      </Text>
                    </RowBetween>

                    <RowBetween align="center" height={font24Str}>
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          AREC Issuance Fee:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>The fee to pay to issue the AREC NFT based on your renewable energy generation data.</>
                          }
                        />
                      </RowFixed>
                      <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {approvalAmount?.toFixed(3)} AKRE
                      </Text>
                    </RowBetween>

                    {phase !== undefined && phase >= 2 && (
                      <RowBetween align="center" height={font24Str}>
                        <RowFixed>
                          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                            AREC CID:
                          </Text>
                          <QuestionHelper
                            bkgOff={true}
                            small={'s'}
                            info={<>This the CID of the renewable energy generation data in IPFS system.</>}
                          />
                        </RowFixed>
                        {!shortCID || (!simulationMode && curBatchID !== batchID) ? (
                          <Loader fontSize={font14} />
                        ) : (
                          <div>
                            {urlCID && (
                              <ExternalLink href={urlCID} style={{ paddingRight: '6px' }}>
                                <LinkIcon size={font14} color={theme.text2} />
                              </ExternalLink>
                            )}
                            <MouseoverTooltip info={<>{cID}</>}>
                              <Text lineHeight={font24Str} fontWeight={700} fontSize={font14}>
                                {shortCID}
                              </Text>
                            </MouseoverTooltip>
                          </div>
                        )}
                      </RowBetween>
                    )}
                  </ARECContainer>
                ) : !account ? (
                  <Text fontWeight={700} textAlign="center" fontSize={font16} padding="8px 0px">
                    We will quide you to mint ART token,
                    <br /> Please connect your wallet!
                  </Text>
                ) : ifLockedForLaunch ? (
                  <Text fontWeight={500} textAlign="center" fontSize={font16} color="red" padding="8px 0px 0px">
                    Arkreen mainnet will be ready at: <br />
                    2024-02-28 00:00:00 UTC+00
                  </Text>
                ) : !minerList && errorAREC == '' ? (
                  <ARECContainer>
                    <Row justifyContent={'center'} alignItems={'stretch'}>
                      <Text fontWeight={500} textAlign="center" fontSize={font16} padding="8px 0px 8px">
                        Fetching your AREC data ...
                      </Text>
                      <Loader fontSize={font24} stroke={theme.text1} style={{ marginLeft: '12px' }} />
                    </Row>
                  </ARECContainer>
                ) : minerList?.length === 0 ? (
                  <ARECContainer>
                    <Text fontWeight={500} textAlign="center" fontSize={font16} padding="8px 0px 0px">
                      You have no miner yet!   Please order from:
                    </Text>
                    <Text fontWeight={500} textAlign="center" fontSize={font16} padding="8px 0px">
                      <OrderLink id="link" href={MINER_BUY_URL}>
                        <ShoppingCart size={font16} />
                        Order Miners
                      </OrderLink>
                    </Text>
                  </ARECContainer>
                ) : errorAREC !== '' && phase !== undefined && phase < 4 ? (
                  <ARECContainer>
                    {!!lastQuickTime && (
                      <Text fontWeight={400} fontSize={font16} padding="4px 0px">
                        You have minted ART at <b>{lastQuickTime}</b>.
                      </Text>
                    )}
                    <Text fontWeight={400} fontSize={font16} padding="4px 0px">
                      According to our design, everyone is allowed to mint ART one time per day. Please wait at least 24
                      hours before your renewable energy generation data is mature for minting ART again.
                    </Text>
                    <Text fontWeight={400} fontSize={font16} padding="4px 0px">
                      You have
                      <b>{countMiners}</b>
                      miner(s), you are welcome to order more miners from:
                    </Text>
                    <Text fontWeight={500} textAlign="center" fontSize={font16} padding="2px 0px">
                      <OrderLink id="link" href={MINER_BUY_URL}>
                        <ShoppingCart size={16} />
                        Order Miners
                      </OrderLink>
                    </Text>
                  </ARECContainer>
                ) : (
                  <div />
                )}
              </Container>

              {daysWithPower != 0 && phase == 1 && (
                <div>
                  <RowBetween align="center" height={font24Str} style={{ padding: '0px 12px' }}>
                    <RowFixed>
                      <Text fontWeight={700} fontSize={font16} color={theme.text1}>
                        Please input the AREC duration:
                      </Text>
                      <QuestionHelper
                        bkgOff={true}
                        small={'s'}
                        info={
                          <>
                            This the days of the duration starting from the earliest date your renewable energy
                            gerenration data are available to mint the AREC NFT.
                          </>
                        }
                      />
                    </RowFixed>
                    <div>&lt;= {daysWithPower} Days</div>
                  </RowBetween>
                  <div style={{ padding: '4px 6px' }}>
                    <StyledNumberInput
                      type="number"
                      min={1}
                      max={daysWithPower}
                      value={daysToIssue}
                      onChange={onDaySelect}
                    />
                  </div>

                  <div style={{ padding: '0px 6px' }}>
                    <StyledRange
                      type="range"
                      min={1}
                      step={1}
                      max={daysWithPower}
                      value={daysToIssue}
                      onChange={onDaySelect}
                      size={12}
                    />
                  </div>
                  <div
                    style={{
                      padding: '0px 6px',
                      position: 'relative',
                      left: Math.floor((daysToIssue * 88) / daysWithPower) + '%',
                      fontSize: '14px',
                      userSelect: 'none'
                    }}
                  >
                    {daysToIssue}/{daysWithPower}
                  </div>
                </div>
              )}
            </AutoColumn>
            <BottomGrouping>
              {!account ? (
                <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
              ) : ifLockedForLaunch ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Mainnet is coming
                  </Text>
                </ButtonError>
              ) : !arkreenTokenContract || !provider ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Waiting AREC Info
                  </Text>
                </ButtonError>
              ) : !approvalAmount || !enableRECMint ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    No AREC to Mint
                  </Text>
                </ButtonError>
              ) : (
                <RowBetween>
                  {phase != undefined &&
                    phase == 1 &&
                    (ifMoreInput ? (
                      <SwapLink href={swapURL} style={{ paddingRight: '6px' }}>
                        <ButtonConfirmed padding={'18px 0px'}>
                          <Text fontSize={font20} fontWeight={500}>
                            Swap AKRE (AKRE Too Less)
                          </Text>
                          <CornerRightUp size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                        </ButtonConfirmed>
                      </SwapLink>
                    ) : (
                      <ButtonConfirmed
                        onClick={() => {
                          onAttemptToApprove()
                        }}
                        disabled={
                          signatureData !== undefined || isApproving || ifMoreInput || ifEmptyWallet || ifwaiting
                        }
                        altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                        padding={'18px 0px'}
                      >
                        <Text fontSize={font20} fontWeight={500}>
                          {signatureData !== undefined
                            ? 'AKRE Approved'
                            : ifEmptyWallet
                            ? 'Wallet Empty'
                            : ifMoreInput
                            ? 'AKRE Too Less'
                            : isApproving
                            ? 'Approving AKRE'
                            : ifwaiting
                            ? 'Fetching AREC Data'
                            : 'Approve AKRE'}
                        </Text>
                        {isApproving || ifwaiting ? (
                          <Loader fontSize={font24} stroke={theme.white} style={{ marginLeft: '12px' }} />
                        ) : (
                          <CornerDownLeft size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                        )}
                      </ButtonConfirmed>
                    ))}
                  {phase === 2 && (
                    <ButtonError id="swap-button" disabled={true} padding={'18px 0px'}>
                      <Text fontSize={font20} fontWeight={500}>
                        Waiting AREC CID
                      </Text>
                      <Loader fontSize={font24} stroke={theme.white} style={{ marginLeft: '12px' }} />
                    </ButtonError>
                  )}
                  {phase === 3 && (
                    <ButtonError
                      onClick={() => handleRECRequest()}
                      id="swap-button"
                      disabled={isRequesting || isMinting}
                      padding={'18px 0px'}
                    >
                      <Text fontSize={font20} fontWeight={500}>
                        {isRequesting ? 'Minting AREC NFT' : isMinting ? 'Waiting Minting Tx' : 'Mint AREC NFT'}
                      </Text>
                      {isRequesting || isMinting ? (
                        <Loader fontSize={font24} stroke={theme.white} style={{ marginLeft: '12px' }} />
                      ) : (
                        <CornerDownLeft size={font20} stroke={theme.white} style={{ marginLeft: '12px' }} />
                      )}
                    </ButtonError>
                  )}
                </RowBetween>
              )}
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}

/*
                  <div style={{ display: 'flex', gap: '16px', padding: '6px 12px' }}>
                    <ChangeBtn onClick={() => onDayChange(-5)} active={activeBtn == 1}>
                      -5
                    </ChangeBtn>
                    <ChangeBtn onClick={() => onDayChange(-3)} active={activeBtn == 3}>
                      -3
                    </ChangeBtn>
                    <ChangeBtn onClick={() => onDayChange(-1)} active={activeBtn == 5}>
                      -1
                    </ChangeBtn>
                    <ChangeBtn onClick={() => onDayChange(1)} active={activeBtn == 7}>
                      +1
                    </ChangeBtn>
                    <ChangeBtn onClick={() => onDayChange(3)} active={activeBtn == 9}>
                      +3
                    </ChangeBtn>
                    <ChangeBtn onClick={() => onDayChange(5)} active={activeBtn == 11}>
                      +5
                    </ChangeBtn>
                  </div>

*/
