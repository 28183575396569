import { useWeb3React } from '@web3-react/core'
import { ChainId } from '@arec/sdk'
import AppBody from '../AppBody'
import { useARECTokenAddress, useArkreenTokenAddress } from '../../hooks/useContract'
import { USDC } from '../../constants'
import { StyledPageCard } from '../../components/earn/styled'

export default function CARTSwap() {
  const { chainId } = useWeb3React()

  //https://app.uniswap.org/#/swap?chain=polygon&inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xE9c21De62C5C5d0cEAcCe2762bF655AfDcEB7ab3
  const tokenCART = useARECTokenAddress()
  const tokenAKRE = useArkreenTokenAddress()

  const tokenTarget = chainId == ChainId.CELO || chainId == ChainId.CELO_TESTNET ? tokenCART : tokenAKRE

  const tokenUSD = chainId ? USDC[chainId].address : USDC[ChainId.MATIC].address
  const network =
    chainId == ChainId.MATIC
      ? 'polygon'
      : chainId == ChainId.MATIC_AMOY_TESTNET
      ? 'polygon_amoy'
      : chainId == ChainId.CELO
      ? 'celo'
      : chainId == ChainId.CELO_TESTNET
      ? 'celo_alfajores'
      : 'polygon'

  const swapURL = chainId
    ? `https://app.uniswap.org/#/swap?chain=${network}&inputCurrency=${tokenUSD}&outputCurrency=${tokenTarget}`
    : 'https://app.uniswap.org/#/swap'

  return (
    <AppBody style={{ marginTop: '30px' }}>
      <StyledPageCard bgColor={'red'}>
        <iframe
          src={swapURL}
          height="800px"
          min-height="700px"
          width="100%"
          scrolling="no"
          style={{
            background: '#F9F9FF',
            border: '1px',
            overflow: 'hidden',
            padding: '10px',
            display: 'block',
            borderRadius: '8px'
          }}
        />
      </StyledPageCard>
    </AppBody>
  )
}
