import { Text } from 'rebass'

import { useContext, useRef } from 'react'
import { ButtonError } from '../../components/Button'
import { RowBetween } from '../../components/Row'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
//import { DateTime } from 'luxon'
import { AutoColumn } from '../../components/Column'
import { Container } from '../../components/CurrencyInputPanel'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useMulticallContract } from '../../hooks/useContract'
import abiGreenBTC from '../../constants/abis/GreenBTC.json'
import { useContract } from '../../hooks/useContract'

//import { Contract } from '@ethersproject/contracts'
import { useWeb3React } from '@web3-react/core'
//import { useWalletModalToggle } from '../../state/application/hooks'

//import { BigNumber } from 'ethers'
import { useSpring, animated } from 'react-spring'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { ThemeContext } from 'styled-components'

import { getIsCoinbaseWallet, getIsInjected, getIsMetaMask } from 'connection/utils'
import { isMobile } from 'utils/userAgent'

export default function Try() {
  const { chainId } = useWeb3React()

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  const multicallContract = useMulticallContract()

  /**
   * Fetches a chunk of calls, enforcing a minimum block number constraint
   * @param multicallContract multicall contract to fetch against
   * @param chunk chunk of calls to make
   * @param minBlockNumber minimum block number of the result set
   */
  async function fetchChunk() {
    if (!multicallContract) return

    let chunk = [
      ['0x3d5531cF0bC2e8d0658fEc0D1a9995211Ac1f337', '0x18160ddd'],
      ['0x3d5531cF0bC2e8d0658fEc0D1a9995211Ac1f337', '0xb0801e5a'],
      ['0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B', '0x18160ddd'],
      ['0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B', '0x5a6bff20'],
      [
        '0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B',
        '0x70a082310000000000000000000000003d5531cf0bc2e8d0658fec0d1a9995211ac1f337'
      ],
      [
        '0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B',
        '0x70a08231000000000000000000000000815bfe3aacf765c9e0a4ddeb98ad710a4fb860d3'
      ],
      ['0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B', '0xc62330dd'],
      ['0x45D0c0E2480212A60F1a9f2A820F1d7d6472CA6B', '0xe40ba994'],
      ['0x815bFE3aaCF765c9E0A4DdEb98Ad710a4Fb860d3', '0x18160ddd'],
      ['0x815bFE3aaCF765c9E0A4DdEb98Ad710a4Fb860d3', '0xabf0e08a'],
      [
        '0x95028E5B8a734bb7E2071F96De89BABe75be9C8E',
        '0x4d2301cc000000000000000000000000364a71ee7a1c9eb295a4f4850971a1861e9d3c7d'
      ],
      [
        '0x960C67B8526E6328b30Ed2c2fAeA0355BEB62A83',
        '0x70a08231000000000000000000000000364a71ee7a1c9eb295a4f4850971a1861e9d3c7d'
      ]
    ]

    /*  
    let chunk = [
      ['0x7075cAB6bCCA06613e2d071bd918D1a0241379E2', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x76e63a3E7Ba1e2E61D3DA86a87479f983dE89a7E', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x89C296Be2F904F3e99a6125815b4B78F5388d2dd', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8A953CfE442c5E8855cc6c61b1293FA648BAE472', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8C92e38eCA8210f4fcBf17F0951b198Dd7668292', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8a2870fb69A90000D6439b7aDfB01d4bA383A415', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x91cA694d2B293f70Fe722fbA7d8A5259188959c3', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xA041544fe2BE56CCe31Ebb69102B965E06aacE80', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xAa9654BECca45B5BDFA5ac646c939C62b527D394', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xC68e83a305b0FaD69E264A1769a0A070F190D2d6', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xC6d54D2f624bc83815b49d9c2203b1330B841cA0', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xD0660cD418a64a1d44E9214ad8e459324D8157f1', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xD6DF932A45C0f255f85145f286eA0b292B21C90B', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xD86b5923F3AD7b585eD81B448170ae026c65ae9a', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xDBf31dF14B66535aF65AaC99C32e9eA844e14501', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xEE800B277A96B0f490a1A732e1D6395FAD960A26', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xF4B0903774532AEe5ee567C02aaB681a81539e92', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xF501dd45a1198C2E1b5aEF5314A68B9006D842E0', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xFbdd194376de19a88118e84E279b977f165d01b8', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xa3Fa99A148fA48D14Ed51d610c367C61876997F1', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xb33EaAd8d922B1083446DC23f610c2567fB5180f', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xb8cb8a7F4C2885C03e57E973C74827909Fdc2032', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xc2132D05D31c914a87C6611C10748AEb04B58e8F', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xc48F61a288A08F1B80c2edd74652e1276B6A168c', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xc4Ace9278e7E01755B670C0838c3106367639962', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xcE829A89d4A55a63418bcC43F00145adef0eDB8E', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xd5d84e75f48E75f01fb2EB6dFD8eA148eE3d0FEb', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xf50D05A1402d0adAfA880D36050736f9f6ee7dee', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xfe712251173A2cd5F5bE2B46Bb528328EA3565E1', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
      ['0xff88434E29d1E2333aD6baa08D358b436196da6b', '0x70a0823100000000000000000000000013baac85653407206e4b4ae4db40f420af8eead8'],
    ]
  */

    let resultsBlockNumber, returnData

    let retryTimes: number = 0
    while (retryTimes < 3) {
      try {
        ;[resultsBlockNumber, returnData] = await multicallContract.callStatic['aggregate'](chunk)
      } catch (error) {
        console.debug('Failed to fetch chunk inside retry', error)
        console.log('fetchChunk Error', multicallContract.address, retryTimes, chunk)
        retryTimes++
        if (retryTimes >= 3) throw error
        continue
      }
      break
    }
    console.log('returnData, blockNumber', returnData, resultsBlockNumber.toNumber())
    return
  }

  async function Test() {
    const url = 'https://testapi.arkreen.com/account/account/'
    const account = '0x364a71eE7a1C9EB295a4F4850971a1861E9d3c7D'

    const response = await fetch(url + account)

    const json = await response.json()
    console.log('Json data', json)
  }

  const { width, height } = useWindowSize()

  const myTheme = useContext(ThemeContext)

  const fontSize = window.document.documentElement.style.fontSize
  const userAgent = navigator.userAgent
  console.log('width, height BBBBBBBBBBBB', width, height, fontSize, 'A', userAgent, 'B', myTheme.fontSize)

  const isInjected = getIsInjected()
  const isMetaMask = getIsMetaMask()
  const isCoinbaseWallet = getIsCoinbaseWallet()

  const isCoinbaseWalletBrowser = isMobile && isCoinbaseWallet
  const isMetaMaskBrowser = isMobile && isMetaMask
  const isInjectedMobileBrowser = isCoinbaseWalletBrowser || isMetaMaskBrowser

  console.log('isMobile isInjected isMetaMask isCoinbaseWallet', isMobile, isInjected, isMetaMask, isCoinbaseWallet)
  console.log(
    'isCoinbaseWalletBrowser isMetaMaskBrowser isInjectedMobileBrowser',
    isCoinbaseWalletBrowser,
    isMetaMaskBrowser,
    isInjectedMobileBrowser
  )

  async function getData() {
    const url = new URL('https://testapi.arkreen.com/device/device/getMinersByOwnerAddress')
    const parameter = {
      address: '0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4',
      offset: '1',
      limit: '10'
    }
    const search = new URLSearchParams(parameter).toString()
    console.log('search', search, url + '?' + search)

    const response = await fetch(url + '?' + search)

    const json = await response.json()
    console.log('Json data', json)
  }

  // Example POST method implementation:
  //  async function postData(url = '', data = {}) {
  async function getMiners() {
    const url = 'http://101.35.163.23:3001/v1'
    const parameter = {
      jsonrpc: '2.0',
      id: '1',
      method: 'rec_getOwnerRecData',
      params: {
        owner: '0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4',
        endDate: '20221120'
      }
    }

    console.log('JSON.stringify(parameter):', JSON.stringify(parameter))

    //      http://101.35.163.23:300/v1
    //      "https://testapi.arkreen.com/v1
    // 0x364a71eE7a1C9EB295a4F4850971a1861E9d3c7D
    // 0x13BAAc85653407206e4b4ae4DB40f420aF8eeaD8
    //  mode: 'no-cors',                  // no-cors, *cors, same-origin

    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      //        cache: 'no-cache',              // *default, no-cache, reload, force-cache, only-if-cached
      //        credentials: 'same-origin',     // include, *same-origin, omit
      //        redirect: 'follow',             // manual, *follow, error
      //        referrerPolicy: 'no-referrer',  // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(parameter) // body data type must match "Content-Type" header
    })
    console.log('response data', response)
    const json = await response.json()
    console.log('Json data', json)
  }

  // Example POST method implementation:
  //  async function postData(url = '', data = {}) {
  async function postData() {
    const url = 'https://openapi.arkreen.com/account/accountreward/list'
    const parameter = {
      address: '0x576ab950b8b3b18b7b53f7edd8a47986a44ae6f4',
      offset: 1,
      limit: 25
    }

    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(parameter) // body data type must match "Content-Type" header
    })
    console.log('response data', response)
    const json = await response.json()
    console.log('Json data', json)
  }

  const greenBTCAddress = '0x770cB90378Cb59665BbF623a72b90f427701C825'
  const greenBTCContract = useContract(greenBTCAddress, abiGreenBTC, false)

  // Example POST method implementation:
  //  async function postData(url = '', data = {}) {
  async function TestOpensea() {
    /*    
    const url =
      'https://api.opensea.io/api/v2/chain/matic/contract/0xDf51F3DCD849f116948A5B23760B1ca0B5425BdE/nfts/15949/refresh'
    //    const options = {method: 'POST', headers: {'x-api-key': '96b487beb0d8469abb1299c44155559e'}};

/*
   // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'x-api-key': '96b487beb0d8469abb1299c44155559e'
      },
      referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    })
    console.log('response data', response)
*/
    console.log('AAAAAAAAAAA', greenBTCContract)

    const imageNFT = await greenBTCContract?.tokenURI(7717)

    console.log('BBBBBBBBBBBBBBBB', greenBTCContract, imageNFT)
  }

  const divRef = useRef<HTMLDivElement>(null)

  async function getJsonData() {
    postData()
  }

  async function getDivStyle() {
    const htmlStyles = window.getComputedStyle(divRef.current as HTMLDivElement)
    console.log('AAAAAAAAAAAAA htmlStyles', htmlStyles)
  }

  return (
    <>
      <AppBody>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={'Test'}>{chainId && <QuestionHelper text={'Test'} info={<>Test</>} />}</PageHeader>
          <Wrapper id="issuance-page">
            <animated.div style={props}>Hello World</animated.div>

            <AutoColumn gap={'md'}>
              <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
                <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} ref={divRef}>
                      Test
                    </Text>
                  </RowBetween>

                  <RowBetween align="center" height="20px">
                    <ButtonError onClick={getDivStyle}>
                      <Text fontSize={20} fontWeight={500}>
                        GET test style
                      </Text>
                    </ButtonError>
                  </RowBetween>

                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} fontSize={14}>
                      Version
                    </Text>
                    <Text fontWeight={700} fontSize={14}>
                      Ver_0.127 2023/1/24 17:26
                    </Text>
                  </RowBetween>

                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} fontSize={14}>
                      Mobile, Injected, Mask, Coinbase:
                    </Text>
                    <Text fontWeight={700} fontSize={14}>
                      ( {isMobile.toString()}, {isInjected.toString()},{isMetaMask.toString()},
                      {isCoinbaseWallet.toString()} )
                    </Text>
                  </RowBetween>

                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} fontSize={14}>
                      Width, Height:
                    </Text>
                    <Text fontWeight={700} fontSize={14}>
                      ( {width}, {height} )
                    </Text>
                  </RowBetween>
                </AutoColumn>
              </Container>
            </AutoColumn>
            <BottomGrouping>
              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={TestOpensea}>
                  <Text fontSize={20} fontWeight={500}>
                    Test Open Sea
                  </Text>
                </ButtonError>
              </RowBetween>

              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={Test}>
                  <Text fontSize={20} fontWeight={500}>
                    Test
                  </Text>
                </ButtonError>
              </RowBetween>

              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={fetchChunk}>
                  <Text fontSize={20} fontWeight={500}>
                    MultiCall
                  </Text>
                </ButtonError>
              </RowBetween>

              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={getJsonData}>
                  <Text fontSize={20} fontWeight={500}>
                    postData
                  </Text>
                </ButtonError>
              </RowBetween>

              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={() => getData()}>
                  <Text fontSize={20} fontWeight={500}>
                    getData
                  </Text>
                </ButtonError>
              </RowBetween>

              <RowBetween style={{ marginTop: '1rem' }}>
                <ButtonError onClick={() => getMiners()}>
                  <Text fontSize={20} fontWeight={500}>
                    getMiners
                  </Text>
                </ButtonError>
              </RowBetween>
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}
