import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useArecCount } from './hooks'
import { useArkreenRegistryContract, useRECIssuanceContract } from '../../hooks/useContract'
import { BigNumber } from 'ethers'
import { useIsTransactionConfirmed } from '../transactions/hooks'
import { ChainId } from '@arec/sdk'
import { useWalletNetwork, useMultiCallErrCounter } from '../user/hooks'
import { atom, useSetAtom } from 'jotai'
import { useTestMode } from 'state/user/hooks'
import { ZERO_ADDRESS } from '../../constants'

export const isIssuer = atom(false)

export interface AssetAREC {
  readonly idAsset: string
  readonly issuer: string
  readonly tokenREC: string
  readonly tokenPay: string
  readonly rateToIssue: BigNumber
  readonly rateToLiquidize: number
  readonly bActive: boolean
  readonly description: string
}
export interface AssetARECList {
  [idARECAsset: number]: AssetAREC
}

export const assetARECListAtom = atom<AssetARECList>({})

export default function Updater(): null {
  const { chainId, account } = useWeb3React()
  const setIsIssuer = useSetAtom(isIssuer)
  const setAssetARECList = useSetAtom(assetARECListAtom)

  const [testMode] = useTestMode()
  const arkreenRECIssuanceContract = useRECIssuanceContract(false)
  const arkreenRegistryContract = useArkreenRegistryContract(false)
  const [initiated, setInitiated] = useState(false)
  const { txHash, updateArecCountHandler, updatetxHash } = useArecCount()
  const bTxConfirmed = useIsTransactionConfirmed(txHash)
  const [walletNetwork, setWalletNetwork] = useWalletNetwork()
  const [multiCallErrCounter] = useMultiCallErrCounter()
  const [preChainID, setPreChainID] = useState<number | undefined>(undefined)
  const [preAccount, setPreAccoun] = useState<string | undefined>(undefined)

  useEffect(() => {
    async function InitIsIssuer() {
      let isIssuer: boolean = false
      if (
        !arkreenRegistryContract ||
        !account ||
        !chainId ||
        !arkreenRECIssuanceContract ||
        (chainId === ChainId.MATIC && testMode !== 'Norm') // Polygon mainnet in simu mode does not support AREC bridge feature
      ) {
        setIsIssuer(false)
        return
      } else {
        if (
          chainId === ChainId.MATIC_TESTNET ||
          chainId === ChainId.MATIC ||
          chainId === ChainId.MATIC_AMOY_TESTNET ||
          chainId === ChainId.CELO_TESTNET ||
          chainId === ChainId.CELO
        ) {
          isIssuer = await arkreenRECIssuanceContract.AllMVPEntity(account)
          setIsIssuer(isIssuer)
        } else {
          setIsIssuer(false)
        }
        if (!isIssuer) return
      }

      const numAsset: BigNumber = await arkreenRegistryContract.numAsset()
      const assetIDs = [...Array(numAsset.toNumber()).keys()]

      const allAssets = await Promise.all(
        (assetIDs as number[]).map(async id => {
          const amountREC: AssetAREC = await arkreenRegistryContract.allAssets(id + 1)

          return {
            idAsset: amountREC.idAsset,
            issuer: amountREC.issuer,
            tokenREC: amountREC.tokenREC,
            tokenPay: amountREC.tokenPay,
            rateToIssue: amountREC.rateToIssue,
            rateToLiquidize: amountREC.rateToLiquidize,
            bActive: amountREC.bActive,
            description: amountREC.description
          }
        })
      )

      const allAssetsWithID = allAssets.reduce<AssetARECList>((accumulator, asset, index) => {
        // if (asset.issuer !== account) return accumulator
        accumulator[index + 1] = asset
        return accumulator
      }, {})

      setAssetARECList(allAssetsWithID) // TTTTTTTTTTTTTTTTTT
    }

    let tryTimes = 0
    while (tryTimes < 3)
      try {
        InitIsIssuer()
        break
      } catch (err) {
        console.log('Get all assets error:', err)
        tryTimes = tryTimes + 1
      }
  }, [chainId, account, testMode, setIsIssuer, arkreenRegistryContract, arkreenRECIssuanceContract, setAssetARECList])

  useEffect(() => {
    async function InitARECNFTCount() {
      if (!arkreenRECIssuanceContract || !account || !chainId) return
      let walletNetworkTarget: string = 'MaticTest'
      if (chainId === ChainId.MATIC) walletNetworkTarget = 'Matic'
      if (chainId === ChainId.MATIC_AMOY_TESTNET) walletNetworkTarget = 'MaticAmoy'
      if (chainId === ChainId.CELO) walletNetworkTarget = 'Celo'
      if (chainId === ChainId.CELO_TESTNET) walletNetworkTarget = 'CeloTest'
      if (walletNetworkTarget !== walletNetwork) {
        setWalletNetwork(walletNetworkTarget)
      }

      const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

      if (multiCallErrCounter >= 3) await sleep(4000)

      if (!initiated || bTxConfirmed || multiCallErrCounter >= 3 || chainId !== preChainID || account !== preAccount) {
        setInitiated(true)
        let nftARECCount: BigNumber = await arkreenRECIssuanceContract.balanceOf(account ?? ZERO_ADDRESS)
        while (true) {
          await sleep(4000)
          const nftARECCountCheck: BigNumber = await arkreenRECIssuanceContract.balanceOf(account ?? ZERO_ADDRESS)
          if (nftARECCount.eq(nftARECCountCheck)) break
          nftARECCount = nftARECCountCheck
        }

        updateArecCountHandler(nftARECCount.toNumber())
        updatetxHash(undefined)
        if (chainId !== preChainID) setPreChainID(chainId)
        if (account !== preAccount) setPreAccoun(account)
      }
    }
    if (!initiated) InitARECNFTCount()
  }, [
    chainId,
    account,
    testMode,
    preChainID,
    preAccount,
    walletNetwork,
    setWalletNetwork,
    multiCallErrCounter,
    arkreenRECIssuanceContract,
    initiated,
    bTxConfirmed,
    updatetxHash,
    updateArecCountHandler
  ])

  return null
}
