import { TokenAmount, NATIVE, ChainId } from '@arec/sdk'
import { useState, useEffect } from 'react'
import { Text } from 'rebass'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Web3Network, { NETWORK_LABELS } from '../Web3Network'
import { useMedia } from 'react-use'

import styled from 'styled-components'

import LogoS from '../../assets/svg/arkreen_s.png'
import LogoB from '../../assets/svg/arkreen_b.png'

import { useWeb3React } from '@web3-react/core'
import { useETHBalances, useArkreenBalance } from '../../state/wallet/hooks'
import { useWalletNetwork } from '../../state/user/hooks'
import { getConnection } from 'connection/utils'
import { ConnectionType, networkConnection } from 'connection'

import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE } from '../../theme'

import { BlueCard } from '../Card'
import Settings from '../Settings'

import Row, { RowFixed } from '../Row'

import Web3Status from '../Web3Status'
import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import MenuApp from '../MenuApp'
import MenuInfo from '../MenuInfo'
import { useFontSize } from 'hooks/useWindowSize'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 1fr 1fr;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding:  0.2rem 1rem;
    grid-template-columns: 1fr;
    justify-content: flex-start;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 0.5rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 48px;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderLinks = styled(Row)`
  justify-content: left;
  display: flex;
  align-items: center;
  justify-items: left;
  grid-row-gap: 8px;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 8px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`

const UNIAmount = styled(AccountElement)`
  color: ${({ theme }) => theme.primaryText1};
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(BlueCard)`
  border-radius: 8px;
  padding: 8px;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-3deg);
  }
`

const activeClassName = 'ACTIVE'

export const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1.2rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 900;
    color: ${({ theme }) => theme.navlink};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.navlink};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 1rem;
    margin: 0 3px;
  `};
`

function getChainName(chainId: number): string {
  if (chainId === ChainId.MATIC) return 'Matic'
  if (chainId === ChainId.MATIC_TESTNET) return 'MaticTest'
  if (chainId === ChainId.MATIC_AMOY_TESTNET) return 'MaticAmoy'
  if (chainId === ChainId.CELO) return 'Celo'
  if (chainId === ChainId.CELO_TESTNET) return 'CeloTest'
  return 'ETH'
}

function Header() {
  const below1080 = useMedia('(max-width: 1080px)')
  const { account, chainId, connector } = useWeb3React()

  const connectionType = getConnection(connector).type

  const GORV_TOKEN_NAME = 'AKRE'

  const [walletNetwork, setWalletNetwork] = useWalletNetwork()

  const ifCelo = chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET

  useEffect(() => {
    if (!chainId) return
    const chainName = getChainName(chainId)
    if (chainName !== walletNetwork) {
      if (connectionType !== ConnectionType.NETWORK) {
        networkConnection.connector.activate(chainId) // While swith network in wallet, switch in Network also
        setWalletNetwork(chainName)
      }
    }
  }, [chainId, connectionType, walletNetwork, setWalletNetwork])

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  //  const [isDark] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useArkreenBalance() // AKRE

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  const { font14, font18, font24 } = useFontSize()

  return (
    <HeaderFrame>
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>

      {below1080 ? (
        <HeaderLinks>
          <Title href="https://www.arkreen.com">
            <UniIcon>
              <img
                style={{ borderRadius: font18 / 3, height: font24 * 2, paddingTop: '4px' }}
                src={font14 <= 14 ? LogoS : LogoB}
                alt="logo"
              />
            </UniIcon>
          </Title>
          <MenuApp />
          <MenuInfo />
        </HeaderLinks>
      ) : (
        <div />
      )}

      <HeaderControls>
        <HeaderElement>
          <HideSmall>
            {chainId && NETWORK_LABELS[chainId] && (
              <NetworkCard title={NETWORK_LABELS[chainId]}>
                <RowFixed>
                  <Web3Network />
                  <Text style={{ marginLeft: '4px' }}>{NETWORK_LABELS[chainId]}</Text>
                </RowFixed>
              </NetworkCard>
            )}
          </HideSmall>
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.White padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? (
                    <Dots>Claiming {GORV_TOKEN_NAME}</Dots>
                  ) : (
                    `Claim ${GORV_TOKEN_NAME}`
                  )}
                </TYPE.White>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          {!availableClaim && aggregateBalance && !ifCelo && (
            <HideSmall>
              <UNIWrapper>
                <UNIAmount
                  active={!!account && !availableClaim}
                  style={{ pointerEvents: 'auto', border: '1px solid #00923A', borderRadius: '8px' }}
                >
                  {account && (
                    <HideSmall>
                      <TYPE.Primary
                        style={{
                          paddingRight: '.4rem'
                        }}
                      >
                        <CountUp
                          key={countUpValue}
                          isCounting
                          start={parseFloat(countUpValuePrevious)}
                          end={parseFloat(countUpValue)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </TYPE.Primary>
                    </HideSmall>
                  )}
                  {GORV_TOKEN_NAME}
                </UNIAmount>
                <CardNoise />
              </UNIWrapper>
            </HideSmall>
          )}
          <AccountElement
            active={!!account}
            style={{
              pointerEvents: 'auto',
              border: '1px solid #DF9742',
              borderRadius: '8px',
              backgroundColor: '#FCF5EC'
            }}
          >
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, borderRadius: '8px' }} padding="0 0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)} {chainId ? NATIVE[chainId as ChainId].symbol : ''}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Settings />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>
  )
}

export default withRouter(Header)
