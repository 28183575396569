import { Wrapper } from '../../components/swap/styleds'
import { useContext, useCallback } from 'react'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
import AppBody from '../AppBody'
import { AutoColumn } from '../../components/Column'
import { ThemeContext } from 'styled-components'
import { useTestMode, useWalletNetwork, useBridgeFeature } from '../../state/user/hooks'
import { ChainId } from '@arec/sdk'
import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { SupportedChainId, isSupportedChain } from 'constants/chains'
import { getConnection } from 'connection/utils'
import { ConnectionType, getNetworkParameter } from 'connection'

export default function Mode() {
  const theme = useContext(ThemeContext)
  const { chainId, connector } = useWeb3React()
  const [testMode, setTestMode] = useTestMode()
  const [bridgeFeature, setBridgeFeature] = useBridgeFeature()
  const [walletNetwork, setWalletNetwork] = useWalletNetwork()

  const ifCelo = chainId === ChainId.CELO || chainId === ChainId.CELO_TESTNET

  const switchChain = useCallback(async (connector: Connector, newChainId: SupportedChainId) => {
    const connectionType = getConnection(connector).type
    if (!isSupportedChain(newChainId)) {
      throw new Error(`Chain ${newChainId} not supported for connector (${typeof connector})`)
    } else {
      try {
        if (connectionType === ConnectionType.INJECTED || connectionType === ConnectionType.COINBASE_WALLET) {
          const addChainParameter = getNetworkParameter(newChainId)
          await connector.activate(addChainParameter)
        } else {
          await connector.activate(newChainId)
        }
      } catch (error) {
        // In activating a new chain, the connector passes through a deactivated state.
        // If we fail to switch chains, it may remain in this state, and no longer be usable.
        // We defensively re-activate the connector to ensure the user does not notice any change.
        try {
          await connector.activate()
        } catch (error) {
          console.error('Failed to re-activate connector', error)
        }
      }
    }
  }, [])

  return (
    <AppBody style={{ marginTop: '60px' }}>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'Set Test Mode'} />
        <Wrapper id="issuance-page">
          {!ifCelo && (
            <fieldset style={{ borderRadius: '8px', border: '1px solid' + theme.primary4 }}>
              <legend style={{ padding: '0px 6px' }}> Select Test Mode </legend>
              <AutoColumn gap="12px">
                <label>
                  <input
                    type="radio"
                    id="Simu"
                    name="TestMode"
                    value="Simu"
                    checked={testMode === 'Simu'}
                    onClick={() => {
                      if (testMode !== 'Simu') setTestMode('Simu')
                    }}
                    style={{ margin: '0.8rem 1rem 0rem' }}
                  />
                  Simulation Mode
                </label>
                {chainId !== ChainId.MATIC && (
                  <label>
                    <input
                      type="radio"
                      id="Dev"
                      name="TestMode"
                      value="Dev"
                      checked={testMode === 'Dev'}
                      onClick={() => {
                        if (testMode !== 'Dev') setTestMode('Dev')
                      }}
                      style={{ margin: '0rem 1rem' }}
                    />
                    Development Mode
                  </label>
                )}
                {chainId !== ChainId.MATIC && (
                  <label>
                    <input
                      type="radio"
                      id="Pre"
                      name="TestMode"
                      value="Pre"
                      checked={testMode === 'Pre'}
                      onClick={() => {
                        if (testMode !== 'Pre') setTestMode('Pre')
                      }}
                      style={{ margin: '0rem 1rem' }}
                    />
                    Pre-production Mode
                  </label>
                )}
                {chainId !== ChainId.MATIC && (
                  <label>
                    <input
                      type="radio"
                      id="Pub"
                      name="TestMode"
                      value="Pub"
                      checked={testMode === 'Pub'}
                      onClick={() => {
                        if (testMode !== 'Pub') setTestMode('Pub')
                      }}
                      style={{ margin: '0rem 1rem' }}
                    />
                    Public Integration Mode
                  </label>
                )}
                {chainId !== ChainId.MATIC && (
                  <label>
                    <input
                      type="radio"
                      id="User"
                      name="TestMode"
                      value="User"
                      checked={testMode === 'User'}
                      onClick={() => {
                        if (testMode !== 'User') setTestMode('User')
                      }}
                      style={{ margin: '0rem 1rem' }}
                    />
                    User Experience Mode
                  </label>
                )}
                {chainId === ChainId.MATIC && (
                  <label>
                    <input
                      type="radio"
                      id="Norm"
                      name="TestMode"
                      value="Norm"
                      checked={testMode === 'Norm'}
                      onClick={() => {
                        if (testMode !== 'Norm') setTestMode('Norm')
                      }}
                      style={{ margin: '0rem 1rem 0.8rem' }}
                    />
                    Normal Release Mode
                  </label>
                )}
              </AutoColumn>
            </fieldset>
          )}

          {!ifCelo && (
            <fieldset style={{ marginTop: '20px', borderRadius: '8px', border: '1px solid' + theme.primary4 }}>
              <legend style={{ padding: '0px 6px' }}> Open Bridge Feature </legend>
              <AutoColumn gap="12px">
                <label>
                  <input
                    type="radio"
                    id="on"
                    name="bridge"
                    value="on"
                    checked={bridgeFeature === 'on'}
                    onClick={() => {
                      if (bridgeFeature !== 'on') setBridgeFeature('on')
                    }}
                    style={{ margin: '0.8rem 1rem 0rem' }}
                  />
                  Bridge On
                </label>
                <label>
                  <input
                    type="radio"
                    id="off"
                    name="bridge"
                    value="off"
                    checked={bridgeFeature === 'off'}
                    onClick={() => {
                      if (bridgeFeature !== 'off') setBridgeFeature('off')
                    }}
                    style={{ margin: '0rem 1rem' }}
                  />
                  Bridge Off
                </label>
              </AutoColumn>
            </fieldset>
          )}

          <fieldset
            style={{
              marginTop: !ifCelo ? '20px' : undefined,
              borderRadius: '8px',
              border: '1px solid' + theme.primary4
            }}
          >
            <legend style={{ padding: '0px 6px' }}> Select Desired Network </legend>
            <AutoColumn gap="12px">
              <label>
                <input
                  type="radio"
                  id="MaticTest"
                  name="Network"
                  value="MaticTest"
                  checked={walletNetwork === 'MaticTest'}
                  onClick={() => {
                    if (walletNetwork !== 'MaticTest') {
                      setWalletNetwork('MaticTest')
                      switchChain(connector, SupportedChainId.POLYGON_MUMBAI)
                      if (testMode !== 'Simu') setTestMode('Dev')
                    }
                  }}
                  style={{ margin: '0.8rem 1rem 0rem' }}
                />
                Polygon Mumbi
              </label>

              <label>
                <input
                  type="radio"
                  id="MaticAmoy"
                  name="Network"
                  value="MaticAmoy"
                  checked={walletNetwork === 'MaticAmoy'}
                  onClick={() => {
                    if (walletNetwork !== 'MaticAmoy') {
                      setWalletNetwork('MaticAmoy')
                      switchChain(connector, SupportedChainId.POLYGON_AMOY)
                      if (testMode !== 'Simu') setTestMode('Dev')
                    }
                  }}
                  style={{ margin: '0rem 1rem 0rem' }}
                />
                Polygon Amoy
              </label>

              <label>
                <input
                  type="radio"
                  id="Matic"
                  name="Network"
                  value="Matic"
                  checked={walletNetwork === 'Matic'}
                  onClick={() => {
                    if (walletNetwork !== 'Matic') {
                      setWalletNetwork('Matic')
                      switchChain(connector, SupportedChainId.POLYGON)
                      setTestMode('Norm')
                    }
                  }}
                  style={{ margin: '0rem 1rem 0rem' }}
                />
                Polygon Mainnet
              </label>

              <label>
                <input
                  type="radio"
                  id="CeloTest"
                  name="Network"
                  value="CeloTest"
                  checked={walletNetwork === 'CeloTest'}
                  onClick={() => {
                    if (walletNetwork !== 'CeloTest') {
                      setWalletNetwork('CeloTest')
                      switchChain(connector, SupportedChainId.CELO_ALFAJORES)
                    }
                  }}
                  style={{ margin: '0rem 1rem 0rem' }}
                />
                Celo Testnet
              </label>

              <label>
                <input
                  type="radio"
                  id="Celo"
                  name="Network"
                  value="Celo"
                  checked={walletNetwork === 'Celo'}
                  onClick={() => {
                    if (walletNetwork !== 'Celo') {
                      setWalletNetwork('Celo')
                      switchChain(connector, SupportedChainId.CELO)
                    }
                  }}
                  style={{ margin: '0rem 1rem 0.8rem' }}
                />
                Celo Mainnet
              </label>
            </AutoColumn>
          </fieldset>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
  )
}
