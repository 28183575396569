import { createAction } from '@reduxjs/toolkit'
import { BankInfo, AllArtPaymentInfo, AllARTTokenSaleInfo } from '../../pages/Port/PortInfo'

export const updateArecBankInfo = createAction<{ chainIdMode: string; arecbank: BankInfo | undefined }>(
  'port/updateArecBankInfo'
)
export const updateArtPaymentInfo = createAction<{ chainIdMode: string; allArtPaymentInfo: AllArtPaymentInfo }>(
  'port/updateArtPaymentInfo'
)
export const updateArtSaleInfo = createAction<{ chainIdMode: string; allARTTokenSaleInfo: AllARTTokenSaleInfo }>(
  'port/updateArtSaleInfo'
)
