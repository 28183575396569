import { useContext, useState, useCallback, useMemo, ChangeEvent } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { RowBetween, RowFixed } from '../../components/Row'
import { ZERO_ADDRESS } from '../../constants'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import useENS from '../../hooks/useENS'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useArkreenRetirementContract } from '../../hooks/useContract'
import { BigNumber } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { useGetActionList } from '../../state/issuance/hooks'
import Loader from '../../components/Loader'
import { TYPE } from '../../theme'
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Container } from '../../components/CurrencyInputPanel'
import { OffsetAction, RECRequest } from '../../state/issuance/hooks'
import { GetCertificateInfo, ErrorPromptModal, getPowerString, M } from '../../components/ARecIssuance'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { useMedia } from 'react-use'
import { useFontSize } from 'hooks/useWindowSize'

function RetirementHelpInfo() {
  return (
    <>
      <Text>
        This is to mint climate badge based on previous climate actions (redeem and offset). You could also include some
        climate action memo to this badge.
      </Text>
      <Text>
        <M>1.</M> Connect your wallet on Polygon.
      </Text>
      <Text>
        <M>2.</M> Select the climate actions you want to include.
      </Text>
      <Text>
        <M>3.</M> Input the message you want to record in the badge, including your name as the badge owner, climate
        action beneficiary wallet address and name, and any supplement message you want to include. All this items are
        optional
      </Text>
      <Text>
        <M>4.</M> Click <M>Mint Climate Badge</M>, check and sign your transaction.
      </Text>
    </>
  )
}

const ARECContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.3rem 0.6rem 0.3rem 0.6rem;
  background: transparent;
`

export function DetailedRetirementInfo({ retireID, retireData }: { retireID: number[]; retireData: OffsetAction[] }) {
  const theme = useContext(ThemeContext)

  const totalRetirementAmount = retireID.reduce<BigNumber>((totalRetirementAmount, id) => {
    if (!retireData[id]) return totalRetirementAmount
    return totalRetirementAmount.add(retireData[id].amount)
  }, BigNumber.from(0))

  const powerAmountString = getPowerString(totalRetirementAmount)
  const { font14, font24 } = useFontSize()

  return (
    <ARECContainer>
      <RowBetween align="center" height={font24 + 'px'}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            Number of Selected Climate Actions:
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={<>This is the number of climate actions that will be included in the minted climate badge.</>}
          />
        </RowFixed>
        <Text lineHeight={font24 + 'px'} fontWeight={700} fontSize={font14} color={theme.text1}>
          {retireID.length}
        </Text>
      </RowBetween>
      <RowBetween align="center" height={font24 + 'px'}>
        <RowFixed>
          <Text fontWeight={500} fontSize={font14} color={theme.text2}>
            {font14 <= 12 ? `Retired RE Amount:` : `Retired RE Amount Selected:`}
          </Text>
          <QuestionHelper
            bkgOff={true}
            small={'s'}
            info={
              <>
                This is the total renewable energy amount of the selected climate actions to be included in the minted
                climate badge.
              </>
            }
          />
        </RowFixed>
        <Text lineHeight={font24 + 'px'} fontWeight={700} fontSize={font14} color={theme.text1}>
          {powerAmountString}
        </Text>
      </RowBetween>
    </ARECContainer>
  )
}

export default function MintCertificate() {
  const { account, chainId } = useWeb3React()
  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const { allOffsetActionsID, allUnclaimedActionsIDs, totalUnclaimedAmount, allUnclaimedActions } = useGetActionList()

  const arkRetirementCertificateContract = useArkreenRetirementContract(true)

  const [{ showConfirm, txnToConfirm, errorMessage, attemptingTxn, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()
  const [offsetSelected, setOffsetSelected] = useState<number[] | undefined>()

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  const [nameCertOwner, setNameCertOwner] = useState<string>('')
  const [beneficiary, setBeneficiary] = useState<string>(ZERO_ADDRESS)
  const [nameBeneficiary, setNameBeneficiary] = useState<string>('')
  const [memoCertificate, setMemoCertificate] = useState<string>('')
  const [errorAddress, setErrorAddress] = useState(false)

  const { address, loading } = useENS(beneficiary)
  useMemo(() => {
    if (loading) return
    const error = Boolean(!address && beneficiary !== ZERO_ADDRESS && beneficiary !== '')
    setErrorAddress(error)
  }, [loading, address, beneficiary, setErrorAddress])

  const onChangeBeneficiary = useCallback(
    (beneficiary: string) => {
      setBeneficiary(beneficiary)
    },
    [setBeneficiary]
  )

  const onARECSelect = useCallback(
    (arecSelect: ChangeEvent<HTMLSelectElement>) => {
      let selected: number[] = []
      for (let i = 0; i < arecSelect.target.selectedOptions.length; i++) {
        selected.push(Number(arecSelect.target.selectedOptions[i].value))
      }

      setOffsetSelected(selected)
    },
    [setOffsetSelected]
  )

  const retiredPowerUnclaimed = allUnclaimedActions.map((offsetAction: OffsetAction) => {
    return getPowerString(offsetAction.amount)
  })

  const totalUnclaimedAmountString = useMemo(() => {
    return getPowerString(totalUnclaimedAmount)
  }, [totalUnclaimedAmount])

  async function handleMintCertificate() {
    if (
      !arkRetirementCertificateContract ||
      offsetSelected === undefined ||
      !allUnclaimedActionsIDs ||
      offsetSelected.length === 0 ||
      errorAddress
    )
      return

    const retirementID = offsetSelected.map(offsetIndex => allUnclaimedActionsIDs[offsetIndex])

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await arkRetirementCertificateContract.estimateGas['mintCertificate'](
      account,
      beneficiary === '' ? ZERO_ADDRESS : beneficiary,
      nameCertOwner,
      nameBeneficiary,
      memoCertificate,
      retirementID
    )
      .then(async estimatedGasLimit => {
        await arkRetirementCertificateContract
          .mintCertificate(
            account,
            beneficiary === '' ? ZERO_ADDRESS : beneficiary,
            nameCertOwner,
            nameBeneficiary,
            memoCertificate,
            retirementID,
            { gasLimit: calculateGasMargin(estimatedGasLimit) }
          )
          .then((response: TransactionResponse) => {
            setIsWaitingWallet(false)
            setOffsetSelected(undefined)
            addTransaction(response, {
              summary: `Mint climate badge with ${offsetSelected.length} actions`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Mint climate badge: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Mint climate badge: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of mint climate badge tx:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  const { font14, font16, font20 } = useFontSize()

  return (
    <AppBody style={{ marginTop: below540 ? '30px' : '30px' }}>
      <StyledPageCard bgColor={'red'}>
        <PageHeader header={'Mint Climate Badge'}>
          {chainId && <QuestionHelper bkgOff={true} text={'Mint Climate Badge'} info={<RetirementHelpInfo />} />}
        </PageHeader>
        <Wrapper id="issuance-page">
          <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />
          <AutoColumn gap={'md'}>
            <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
              <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
                <RowBetween align="center" height={font20 + 'px'}>
                  <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                    Total Number of Climate Actions:
                  </Text>
                  {allOffsetActionsID === undefined ? (
                    <Loader />
                  ) : (
                    <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                      {allUnclaimedActionsIDs?.length.toString()}
                    </Text>
                  )}
                </RowBetween>
                {allOffsetActionsID !== undefined && (
                  <RowBetween align="center" height={font20 + 'px'}>
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      Total Climate Amount to Mint:
                    </Text>
                    <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                      {totalUnclaimedAmountString}
                    </Text>
                  </RowBetween>
                )}
              </AutoColumn>
            </Container>
            {allUnclaimedActionsIDs !== undefined && allUnclaimedActionsIDs.length !== 0 && (
              <Container>
                <RowBetween align="center" height={font20 + 'px'} style={{ padding: '0.75rem 1rem 0rem 1rem' }}>
                  <TYPE.Body color={theme.text2} fontWeight={500} fontSize={font16}>
                    <strong>Climate Action List:</strong>
                    <QuestionHelper
                      bkgOff={true}
                      small={'m'}
                      info={
                        <>
                          Use <b>CTRL + Click</b> to select multiple climate badge actions.
                        </>
                      }
                    />
                  </TYPE.Body>
                </RowBetween>
                <div style={{ margin: '0.8rem 0.6rem 0.6rem' }}>
                  <select
                    multiple
                    size={5}
                    onChange={onARECSelect}
                    style={{
                      fontSize: '1.0rem',
                      fontWeight: 600,
                      width: '100%',
                      borderColor: offsetSelected ? theme.text1 : theme.primary1,
                      borderWidth: offsetSelected ? '1px' : '2px',
                      color: theme.text1,
                      backgroundColor: theme.bg5,
                      borderRadius: '0px 0px 0px 0px',
                      appearance: 'none',
                      padding: '0.2rem 0.6rem 0rem 0.6rem',
                      fontFamily: 'Tahoma'
                    }}
                  >
                    <option disabled> Select action(s) to mint climate badge NFT </option>
                    {allUnclaimedActionsIDs.map((_, index) => {
                      const optionTextID = '0000'.concat(allUnclaimedActionsIDs[index].toString())
                      return (
                        <option value={index} key={optionTextID}>
                          {'ACT_'.concat(optionTextID.substring(optionTextID.length - 4)).concat(':')}
                          {'         '} {retiredPowerUnclaimed[index]}
                        </option>
                      )
                    })}
                  </select>
                </div>
                {offsetSelected !== undefined && offsetSelected.length !== 0 && (
                  <div style={{ padding: '0.3rem 0.6rem 0.6rem 0.6rem' }}>
                    <DetailedRetirementInfo retireID={offsetSelected} retireData={allUnclaimedActions} />
                  </div>
                )}
              </Container>
            )}

            {allUnclaimedActionsIDs !== undefined && allUnclaimedActionsIDs.length !== 0 && (
              <GetCertificateInfo
                certOwner={nameCertOwner}
                beneficiary={beneficiary}
                nameBeneficiary={nameBeneficiary}
                memoCertificate={memoCertificate}
                setCertOwner={setNameCertOwner}
                setBeneficiary={onChangeBeneficiary}
                setNameBeneficiary={setNameBeneficiary}
                setMemoCertificate={setMemoCertificate}
              />
            )}
          </AutoColumn>

          <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : allUnclaimedActionsIDs === undefined ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Waiting Climate Action Info
                </Text>
              </ButtonError>
            ) : allUnclaimedActionsIDs === undefined || allUnclaimedActionsIDs.length === 0 ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  No Climate Action
                </Text>
              </ButtonError>
            ) : offsetSelected === undefined || offsetSelected.length === 0 ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Select Climate Actions
                </Text>
              </ButtonError>
            ) : errorAddress ? (
              <ButtonError disabled={true} error={false}>
                <Text fontSize={font20} fontWeight={500}>
                  Wrong Beneficiary Address
                </Text>
              </ButtonError>
            ) : (
              <RowBetween>
                <ButtonError disabled={isWaitingWallet} onClick={() => handleMintCertificate()} id="redeem-button">
                  <Text fontSize={font20} fontWeight={500}>
                    Mint Climate Badge
                  </Text>
                </ButtonError>
              </RowBetween>
            )}
          </BottomGrouping>
        </Wrapper>
      </StyledPageCard>
    </AppBody>
  )
}
