import { ReactNode, useMemo, useState, useEffect } from 'react'
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import { Connection } from 'connection'
import { getConnectionName } from 'connection/utils'
import useEagerlyConnect from 'hooks/useEagerlyConnect'
import useOrderedConnections from 'hooks/useOrderedConnections'

export default function Web3Provider({ children }: { children: ReactNode }) {
  useEagerlyConnect()
  const connections = useOrderedConnections()
  const connectors: [Connector, Web3ReactHooks][] = connections.map(({ hooks, connector }) => [connector, hooks])

  // Force a re-render when our connection state changes.
  const [index, setIndex] = useState(0)
  useEffect(() => setIndex(index => index + 1), [connections])
  const key = useMemo(() => {
    return connections.map(({ type }: Connection) => getConnectionName(type)).join('-') + index
  }, [connections, index])

  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      {children}
    </Web3ReactProvider>
  )
}
