import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { initializeConnector, Web3ReactHooks } from '@web3-react/core'
import { EIP1193 } from '@web3-react/eip1193'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import { Connector, Actions } from '@web3-react/types'
import { WalletConnect } from '@web3-react/walletconnect'
import { WalletConnectV2 } from './WalletConnectV2'
import { SupportedChainId } from 'constants/chains'
import Fortmatic from 'fortmatic'

import AREC_LOGO_URL from '../assets/svg/logo.svg'
import { RPC_URLS } from '../constants/networks'

export enum ConnectionType {
  INJECTED = 'INJECTED',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT = 'WALLET_CONNECT',
  WALLET_CONNECT_V2 = 'WALLET_CONNECT_V2',
  FORTMATIC = 'FORTMATIC',
  NETWORK = 'NETWORK',
  GNOSIS_SAFE = 'GNOSIS_SAFE'
}

export interface Connection {
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
}

export function getDefaultNetwork() {
  const userSetting = localStorage.getItem('redux_localstorage_simple_user')
  const walletNetwork = userSetting ? JSON.parse(userSetting).WalletNetwork : 'Matic'
  return walletNetwork === 'Matic'
    ? SupportedChainId.POLYGON
    : walletNetwork === 'MaticTest'
    ? SupportedChainId.POLYGON_MUMBAI
    : walletNetwork === 'MaticAmoy'
    ? SupportedChainId.POLYGON_AMOY
    : walletNetwork === 'Celo'
    ? SupportedChainId.CELO
    : walletNetwork === 'CeloTest'
    ? SupportedChainId.CELO_ALFAJORES
    : SupportedChainId.POLYGON
}

export function getNetworkParameter(chainId?: number) {
  return chainId === SupportedChainId.POLYGON_MUMBAI
    ? {
        chainId: SupportedChainId.POLYGON_MUMBAI,
        chainName: 'Polygon Mumbai',
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        nativeCurrency: { name: 'Polygon Matic Test', symbol: 'mMATIC', decimals: 18 },
        blockExplorerUrls: ['https://mumbai.polygonscan.com/']
      }
    : chainId === SupportedChainId.POLYGON_AMOY
    ? {
        chainId: SupportedChainId.POLYGON_AMOY,
        chainName: 'Polygon Amoy',
        rpcUrls: ['https://rpc-amoy.polygon.technology'],
        nativeCurrency: { name: 'Polygon Amoy Test', symbol: 'amoyMATIC', decimals: 18 },
        blockExplorerUrls: ['https://amoy.polygonscan.com/']
      }
    : chainId === SupportedChainId.CELO_ALFAJORES
    ? {
        chainId: SupportedChainId.CELO_ALFAJORES,
        chainName: 'Celo Alfajores',
        rpcUrls: ['https://alfajores.celoscan.io/'],
        nativeCurrency: { name: 'Celo Test Asset', symbol: 'Celo Test', decimals: 18 },
        blockExplorerUrls: ['https://alfajores.celoscan.io/']
      }
    : chainId === SupportedChainId.CELO
    ? {
        chainId: SupportedChainId.CELO,
        chainName: 'Celo Mainnet',
        rpcUrls: ['https://celoscan.io/'],
        nativeCurrency: { name: 'Celo Native Asset', symbol: 'Celo', decimals: 18 },
        blockExplorerUrls: ['https://celoscan.io/']
      }
    : {
        chainId: SupportedChainId.POLYGON,
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-rpc.com/'],
        nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
        blockExplorerUrls: ['https://polygonscan.com/']
      }
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`)
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions: Actions) => new Network({ actions, urlMap: RPC_URLS, defaultChainId: getDefaultNetwork() })
)
export const networkConnection: Connection = {
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK
}

const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>(
  (actions: Actions) => new MetaMask({ actions, onError })
)
export const injectedConnection: Connection = {
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED
}

const [web3GnosisSafe, web3GnosisSafeHooks] = initializeConnector<GnosisSafe>(
  (actions: Actions) => new GnosisSafe({ actions })
)
export const gnosisSafeConnection: Connection = {
  connector: web3GnosisSafe,
  hooks: web3GnosisSafeHooks,
  type: ConnectionType.GNOSIS_SAFE
}

const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnect>(
  (actions: Actions) =>
    new WalletConnect({
      actions,
      options: {
        rpc: RPC_URLS,
        qrcode: true
      },
      onError
    })
)

export const walletConnectConnection: Connection = {
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT
}

const [web3WalletConnectV2, web3WalletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
  (actions: Actions) => new WalletConnectV2({ actions, onError })
)

export const walletConnectV2Connection: Connection = {
  connector: web3WalletConnectV2,
  hooks: web3WalletConnectV2Hooks,
  type: ConnectionType.WALLET_CONNECT_V2
}

const [web3Fortmatic, web3FortmaticHooks] = initializeConnector<EIP1193>(
  (actions: Actions) =>
    new EIP1193({ actions, provider: new Fortmatic(process.env.REACT_APP_FORTMATIC_KEY).getProvider() })
)
export const fortmaticConnection: Connection = {
  connector: web3Fortmatic,
  hooks: web3FortmaticHooks,
  type: ConnectionType.FORTMATIC
}

const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions: Actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[SupportedChainId.POLYGON],
        appName: 'AREC',
        appLogoUrl: AREC_LOGO_URL,
        reloadOnDisconnect: false
      },
      onError
    })
)

export const coinbaseWalletConnection: Connection = {
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET
}
