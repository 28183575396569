import { useContext, useState, useCallback, useMemo, useEffect } from 'react'
import { CurrencyAmount, TokenAmount, Token, Fraction, JSBI, Currency } from '@arec/sdk'
import { MessageCircle as HelpCircle } from 'react-feather' // MessageCircle  Coffee
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel, { Container, CurrencySelectPanel } from 'components/CurrencyInputPanel'
import { RowBetween, RowFixed } from '../../components/Row'
import { MouseoverTooltip } from '../../components/Tooltip'
import { BottomGrouping, Wrapper } from '../../components/swap/styleds'
import PageHeader from '../../components/PageHeader'
import { StyledPageCard } from '../../components/earn/styled'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useArkreenRECTokenContract, useARECTokenAddress } from '../../hooks/useContract'
import { useToken } from '../../hooks/Tokens'
import { tryParseAmount } from '../../state/swap/hooks'
import { BigNumber } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import {
  useGetActionList,
  RECRequest,
  useGetARECInfo,
  useGetARECTotalLiquidized,
  useRatioFeeToSolidify,
  useGetReceiverFee
} from '../../state/issuance/hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import Loader from '../../components/Loader'
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from '../../state/application/hooks'
import { ErrorPromptModal, ButtonRow } from '../../components/ARecIssuance'
import { useMedia } from 'react-use'
import Card from '../../components/Card'
import { SeparatorBlack } from '../../components/SearchModal/styleds'

import TransactionConfirmationModal, { ConfirmationModalContentTitle } from 'components/TransactionConfirmationModal'
import AppBody from '../AppBody'
import QuestionHelper from '../../components/QuestionHelper'
import { OffsetSolidyButton, M } from '../../components/ARecIssuance'
import { getPowerAndString } from '../Overview'
import { TYPE } from '../../theme'
import { useFontSize } from 'hooks/useWindowSize'
import { useARTWithBalance } from 'pages/Offset'
import { AddressZero } from '@ethersproject/constants'

function RetirementHelpInfo() {
  return (
    <>
      <Text>
        This is to solidify ART tokens to claim AREC NFTs. Solidified ART tokens are burned forever, and the claimed
        AREC NFTs are sent to your wallet. You need to pay some fee proportional to the RE amount contained in the
        claimed AREC NFTs, normally in the rate of 10%.
      </Text>
      <Text>
        <M>1.</M> Connect your wallet on Polygon.
      </Text>
      <Text>
        <M>2.</M> Input how many ART tokens you want to pay for solidifying. Some information related to the claimed
        AREC NFTs will be shown. Please check these information.
      </Text>
      <Text>
        <M>3.</M> Click <M>Solidify ART to Claim AREC</M>.
      </Text>
      <Text>
        <M>4.</M> Check all the information displayed in the confirmation window, click the button
        <M>Confirm to Solidify ART</M> if no issues.
      </Text>
      <Text>
        <M>5.</M> Confirm and sign the transaction with your wallet.
      </Text>
    </>
  )
}

const HelpForSolidify1 = (
  <>
    While the ART tokens are solidified, they are actually burned, and some AREC NFTs containing close amount of
    renewable energy will be claimed to your wallet. Arkreen always try to match AREC NFTs as much as possible,
    unmatched ART tokens will keep untouched. You need to some fees for solidifying ART tokens.
  </>
)

export default function Solidify() {
  const { account, chainId } = useWeb3React()

  const theme = useContext(ThemeContext)
  const below540 = useMedia('(max-width: 540px)')

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const allARTTokensWithBalance = useARTWithBalance()

  const ARECTokenAddress = useARECTokenAddress()
  const nativeARECToken = useToken(ARECTokenAddress)

  const [currencyARECT, setCurrencyARECT] = useState<Token | undefined>(nativeARECToken ?? undefined)

  const balanceARECT = useTokenBalance(account ?? undefined, currencyARECT ?? undefined)

  const { AllLiquidizedARECCount } = useGetActionList(currencyARECT?.address ?? ARECTokenAddress)

  const [userInput, setUserInput] = useState<string>('')

  const amountInput: CurrencyAmount | undefined = useMemo(() => {
    if (userInput === '') return undefined
    return tryParseAmount(userInput, currencyARECT ?? undefined)
  }, [currencyARECT, userInput])

  const totalAREClLiquidized = useGetARECTotalLiquidized(currencyARECT?.address)
  const infoAREC = useGetARECInfo(3, currencyARECT?.address)
  const ratioFeeToSolidify = useRatioFeeToSolidify(currencyARECT?.address)
  const receiverFee = useGetReceiverFee(currencyARECT?.address)

  const [totalAREClLiquidizedString, unitAREClLiquidizedString] = totalAREClLiquidized
    ? getPowerAndString(totalAREClLiquidized)
    : ['', '']

  const [minARTToLiquidizeString, unitARTToLiquidizeString] = infoAREC
    ? getPowerAndString(infoAREC.amountAREC[0].amountREC)
    : ['', '']

  const [minARTToSolidify, minARTAmount] = useMemo(() => {
    let minARTToSolidify, minARTAmount, solidifyAllValue
    if (infoAREC && currencyARECT && ratioFeeToSolidify && receiverFee) {
      minARTToSolidify = infoAREC.amountAREC[0].amountREC as BigNumber

      if (ratioFeeToSolidify.isZero() || receiverFee === AddressZero) {
        solidifyAllValue = minARTToSolidify
      } else {
        solidifyAllValue = ratioFeeToSolidify
          .mul(minARTToSolidify)
          .add('9999')
          .div('10000')
          .add(minARTToSolidify)
      }
      minARTAmount = new TokenAmount(currencyARECT as Token, JSBI.BigInt(solidifyAllValue.toString()))
    }
    return [minARTToSolidify, minARTAmount]
  }, [infoAREC, currencyARECT, ratioFeeToSolidify, receiverFee])

  const amountInputString = useMemo(() => {
    if (!amountInput) return ''
    return amountInput.toSignificant(4)
  }, [amountInput])

  const contractARECT = useArkreenRECTokenContract(true, currencyARECT?.address)
  const atMaxAmountInput = Boolean(balanceARECT && amountInput?.equalTo(balanceARECT))
  const atMinAmountInput = Boolean(minARTToSolidify && minARTAmount && amountInput?.equalTo(minARTAmount))

  const isNativeART = ARECTokenAddress ? currencyARECT?.address === ARECTokenAddress : true

  useEffect(() => {
    if (currencyARECT || !nativeARECToken) return
    setCurrencyARECT(nativeARECToken)
  }, [currencyARECT, nativeARECToken])

  const handleMaxInput = useCallback(() => {
    balanceARECT && setUserInput(balanceARECT.toExact())
  }, [balanceARECT])

  const handleMinInput = useCallback(() => {
    if (!minARTToSolidify || !minARTAmount || !currencyARECT) return
    setUserInput(minARTAmount.toExact())
  }, [minARTToSolidify, currencyARECT, minARTAmount])

  const ifMoreInput = useMemo(() => {
    if (!amountInput || !balanceARECT) return false
    if (amountInput.greaterThan(balanceARECT)) return true
    return false
  }, [balanceARECT, amountInput])

  const ifLessInput = useMemo(() => {
    if (!amountInput || !minARTAmount) return false
    if (amountInput.lessThan(minARTAmount)) return true
    return false
  }, [minARTAmount, amountInput])

  const [solidifyValue, setSolidifyValue] = useState<BigNumber | undefined>()
  const [solidifiedAmount, setSolidifiedAmount] = useState<BigNumber | undefined>()
  const [numberAREC, setNumberAREC] = useState<BigNumber | undefined>()
  const [feeSolidify, setFeeSolidify] = useState<BigNumber | undefined>()
  const [errString, setErrString] = useState<string>('')

  useEffect(() => {
    async function trySolidify() {
      if (!contractARECT || !amountInput || amountInput?.equalTo('0')) return
      const amountInputValue = BigNumber.from(amountInput.raw.toString())

      try {
        const [solidifiedAmount, numberAREC, feeSolidify] = await contractARECT.callStatic['solidify'](amountInputValue)
        setErrString('')
        //        const solidifiedAmount = _solidifiedAmount as BigNumber
        const solidifyValue = solidifiedAmount.add(feeSolidify)
        setSolidifiedAmount(solidifiedAmount)
        setNumberAREC(numberAREC as BigNumber)
        setFeeSolidify(feeSolidify as BigNumber)
        setSolidifyValue(solidifyValue)
      } catch (error) {
        const errorMessage = (error as any).data.message as string
        if (errorMessage?.startsWith('execution reverted: ')) {
          setErrString(errorMessage.substring(20))
        } else {
          console.log('Error in Solidify:', error)
        }
        setSolidifyValue(undefined)
        setSolidifiedAmount(undefined)
        setNumberAREC(undefined)
        setFeeSolidify(undefined)
      }
    }

    if (!contractARECT || !amountInput || ifMoreInput || ifLessInput || amountInput?.equalTo('0')) return
    trySolidify()
  }, [contractARECT, amountInput, ifMoreInput, ifLessInput])

  const handleARTSelect = useCallback(
    (artToken: Currency) => {
      setCurrencyARECT(artToken as Token)
      setSolidifiedAmount(undefined)
    },
    [setCurrencyARECT]
  )

  const [solidifiedAmountString, feeSolidifyString, solidifyValueString] = useMemo(() => {
    if (!solidifiedAmount || solidifiedAmount.isZero() || !feeSolidify || !solidifyValue) return ['', '', '']

    const ARTTokenSymbol = currencyARECT?.getSymbol(chainId) ?? 'ART'
    const solidifiedAmountString =
      new Fraction(solidifiedAmount.toString(), JSBI.BigInt(1000_000_000)).toFixed(3) + ' ' + ARTTokenSymbol
    const feeSolidifyString =
      new Fraction(feeSolidify.toString(), JSBI.BigInt(1000_000_000)).toFixed(3) + ' ' + ARTTokenSymbol
    const solidifyValueString =
      new Fraction(solidifyValue.toString(), JSBI.BigInt(1000_000_000)).toFixed(3) + ' ' + ARTTokenSymbol

    return [solidifiedAmountString, feeSolidifyString, solidifyValueString]
  }, [solidifiedAmount, chainId, currencyARECT, feeSolidify, solidifyValue])

  const [{ showConfirm, txnToConfirm, attemptingTxn, errorMessage, txHash }, setARECTxnState] = useState<{
    showConfirm: boolean
    txnToConfirm: RECRequest | undefined
    attemptingTxn: boolean
    errorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    txnToConfirm: undefined,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })

  const addTransaction = useTransactionAdder()

  async function handleClearErrorMessage() {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm, errorMessage: undefined, txHash })
  }

  const handleConfirmDismiss = useCallback(() => {
    setARECTxnState({ attemptingTxn, txnToConfirm, showConfirm: false, errorMessage, txHash })
  }, [attemptingTxn, txnToConfirm, errorMessage, txHash])

  const [isWaitingWallet, setIsWaitingWallet] = useState<boolean>(false)

  async function handleSolidify() {
    if (!contractARECT || !amountInput || amountInput?.equalTo('0')) return

    setIsWaitingWallet(true)
    setARECTxnState({ attemptingTxn: true, txnToConfirm, showConfirm, errorMessage: undefined, txHash: undefined })
    await contractARECT.estimateGas['solidify'](solidifyValue)
      .then(async estimatedGasLimit => {
        await contractARECT
          .solidify(solidifyValue, { gasLimit: calculateGasMargin(estimatedGasLimit) })
          .then((response: TransactionResponse) => {
            setUserInput('')
            setIsWaitingWallet(false)
            addTransaction(response, {
              summary: `Solidify ART token: ${amountInputString} ART`
            })
            setARECTxnState({
              attemptingTxn: false,
              txnToConfirm,
              showConfirm,
              errorMessage: undefined,
              txHash: response.hash
            })
          })
          .catch((error: any) => {
            setIsWaitingWallet(false)
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error(`Solidify ART to Claim AREC: You denied transaction signature.`)
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Solidify ART to Claim AREC: ${error.message}`)
            }
          })
      })
      .catch((error: any) => {
        setIsWaitingWallet(false)
        console.log('Error of Solidify ART to Claim AREC:', error)
        const dataMsg = error?.data?.message
          ? ' Details: ' + error.data.message
          : error?.reason ?? error?.code ?? error?.message
        setARECTxnState({
          attemptingTxn: false,
          txnToConfirm,
          showConfirm,
          errorMessage: dataMsg,
          txHash: undefined
        })
      })
  }

  const { font14, font20 } = useFontSize()

  const attemptString = `You are solidifying: ${amountInputString} ART, to claim AREC NFTs containing close amount of
                        renewable energy.`

  function modalHeader() {
    return (
      <AutoColumn gap={'sm'} style={{ marginTop: '20px' }}>
        <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
          <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
            <RowBetween align="center" height="20px">
              <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                Total ART desired to solidify:
              </Text>
              <Text fontWeight={700} fontSize={font14} color={theme.primary1}>
                {amountInput?.toFixed(3)} {currencyARECT?.getSymbol(chainId)}
              </Text>
            </RowBetween>
            <SeparatorBlack style={{ margin: '0.2rem 0rem' }} />
            <RowBetween align="center" height="20px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  Amount of ART to be solidified:
                </Text>
              </RowFixed>
              <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                {solidifiedAmountString}
              </Text>
            </RowBetween>
            <RowBetween align="center" height="20px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  Number of AREC NFTs Received:
                </Text>
              </RowFixed>
              <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                {numberAREC?.toNumber()}
              </Text>
            </RowBetween>
            <RowBetween align="center" height="20px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  Fee to Solidify ART:
                </Text>
              </RowFixed>
              <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                {feeSolidifyString}
              </Text>
            </RowBetween>
            <RowBetween align="center" height="20px">
              <RowFixed>
                <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                  Total ART Amount to Pay:
                </Text>
              </RowFixed>
              <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                {solidifyValueString}
              </Text>
            </RowBetween>
          </AutoColumn>
        </Container>
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.Italic textAlign="left" style={{ width: '100%' }}>
            {`The amount of ART to be solidified is estimated. You will receive maximum AREC NFTs within the 
              ATR amount you specified, but it maybe different from the amount shown above. `}
          </TYPE.Italic>
        </AutoColumn>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <RowBetween>
        <ButtonError onClick={() => handleSolidify()} id="liquidize-button">
          <Text fontSize={font20} fontWeight={500}>
            Confirm to Solidify ART
          </Text>
        </ButtonError>
      </RowBetween>
    )
  }

  //  <AppBody style={{marginTop: below540 ?'30px': '60px'}} >

  return (
    <>
      <OffsetSolidyButton path={'Solidify'} />
      <AppBody>
        <StyledPageCard bgColor={'red'}>
          <PageHeader header={'ART Solidify'}>
            {chainId && <QuestionHelper bkgOff={true} text={'ART Solidify'} info={<RetirementHelpInfo />} />}
          </PageHeader>
          <Wrapper id="issuance-page">
            <ErrorPromptModal isOpen={!!errorMessage} errString={errorMessage} onDismiss={handleClearErrorMessage} />

            <TransactionConfirmationModal
              isOpen={showConfirm && !errorMessage}
              onDismiss={handleConfirmDismiss}
              attemptingTxn={attemptingTxn}
              hash={txHash ? txHash : ''}
              content={() => (
                <ConfirmationModalContentTitle
                  title={'You will solidify'}
                  onDismiss={handleConfirmDismiss}
                  topContent={modalHeader}
                  bottomContent={modalBottom}
                />
              )}
              pendingText={attemptString}
              pendingTitle={'Solidify ART to Claim AREC'}
              submittedTitle={'Solidify ART Submitted'}
            />

            <AutoColumn gap={'md'}>
              <Container style={{ boxShadow: 'inset 0px 0px 8px #00913A', margin: '0rem 0rem' }}>
                <AutoColumn gap="4px" style={{ padding: '0.75rem 1rem 0.75rem 1rem' }}>
                  <RowBetween align="center" height="20px">
                    <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                      {isNativeART ? `ART Token Amount:` : `ART Token Amount (${currencyARECT?.getSymbol(chainId)})`}
                    </Text>
                    {balanceARECT === undefined ? (
                      <Loader />
                    ) : (
                      <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {balanceARECT.toFixed(3)} {currencyARECT?.getSymbol(chainId)}
                      </Text>
                    )}
                  </RowBetween>

                  {AllLiquidizedARECCount !== undefined && (
                    <RowBetween align="center" height="20px">
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        Number of Liquidized AREC NFT:
                      </Text>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {AllLiquidizedARECCount}
                      </Text>
                    </RowBetween>
                  )}

                  {totalAREClLiquidized !== undefined && (
                    <RowBetween align="center" height="20px">
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        RE Amount of All Liquidized AREC:
                      </Text>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {totalAREClLiquidizedString} {unitAREClLiquidizedString}
                      </Text>
                    </RowBetween>
                  )}
                  {infoAREC !== undefined && (
                    <RowBetween align="center" height="20px">
                      <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                        Minimum Amount of ART to solidify:
                      </Text>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {minARTToLiquidizeString} {unitARTToLiquidizeString}
                      </Text>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Container>

              {allARTTokensWithBalance.length === 0 ? (
                <CurrencyInputPanel
                  label={'ART Amount to Solidy:'}
                  value={userInput}
                  showMaxButton={!atMaxAmountInput}
                  showMinButton={!atMinAmountInput}
                  currency={currencyARECT}
                  onUserInput={setUserInput}
                  onMax={handleMaxInput}
                  onMin={handleMinInput}
                  disableCurrencySelect={true}
                  id="solidify-currency-input"
                  customBalanceText="Balance: "
                />
              ) : (
                <CurrencySelectPanel
                  label={'ART Amount to Solidy:'}
                  field={'OUTPUT'}
                  value={userInput}
                  showMaxButton={!atMaxAmountInput}
                  showMinButton={!atMinAmountInput}
                  onMax={handleMaxInput}
                  onMin={handleMinInput}
                  currency={currencyARECT}
                  onUserInput={setUserInput}
                  onCurrencySelect={handleARTSelect}
                  currencyOptions={allARTTokensWithBalance}
                  id="solidify-Select_ART-output"
                />
              )}

              {solidifiedAmountString && (
                <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                  <AutoColumn gap="4px">
                    <RowBetween align="center">
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Amount of ART to be solidified:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>
                              This is the estimated ART amount to be solidified to claim AREC NFTs. The amount of RE
                              contained in the AREC NFTS you will receive is exactly equal to the amount of ART
                              solidified. All solidified ART tokens are burned forever.
                            </>
                          }
                        />
                      </RowFixed>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {solidifiedAmountString}
                      </Text>
                    </RowBetween>
                    <RowBetween align="center">
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Number of AREC NFTs Received:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>
                              This is the number of AREC NTFs you will receive by solidifying some ART tokens. Arkreen
                              will match the liquidized AREC NFTs as much as possible to meet your specified ART amount.
                              As AREC NFT can only be claimed as a whole, it may happen that only part of the amount you
                              have specified to solidify can be matched. All the AREC NFTs you will receive will be in
                              normal state instead of liquidized state.
                            </>
                          }
                        />
                      </RowFixed>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {numberAREC?.toNumber()}
                      </Text>
                    </RowBetween>
                    <RowBetween align="center">
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Fee to Solidify ART:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>
                              This is the fee to solidify ART to claim AREC NFTs. This fee is proportional to the RE
                              amount contained in the claimed AREC NFTs, normally in the rate of 10%, which may be
                              subject to change based on Arkreen governance rules.
                            </>
                          }
                        />
                      </RowFixed>
                      <Text fontWeight={700} fontSize={font14} color={theme.text2}>
                        {feeSolidifyString}
                      </Text>
                    </RowBetween>
                    <RowBetween align="center">
                      <RowFixed>
                        <Text fontWeight={500} fontSize={font14} color={theme.text2}>
                          Total ART Amount to Pay:
                        </Text>
                        <QuestionHelper
                          bkgOff={true}
                          small={'s'}
                          info={
                            <>
                              This is the total amount of ART tokens you need to pay to claim the AREC NFTs, which
                              includes the solidifying fee and the ART tokens burned to claimed the AREC.
                            </>
                          }
                        />
                      </RowFixed>
                      <Text fontWeight={700} fontSize={font14} color={theme.primaryText1}>
                        {solidifyValueString}
                      </Text>
                    </RowBetween>
                  </AutoColumn>
                </Card>
              )}
            </AutoColumn>
            <BottomGrouping>
              {!account ? (
                <ButtonLight onClick={toggleWalletModal}> Connect Wallet </ButtonLight>
              ) : userInput === '' || !amountInput ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Input Solidify Amount
                  </Text>
                </ButtonError>
              ) : balanceARECT === undefined || balanceARECT.equalTo('0') ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    No ART Token to Solidify
                  </Text>
                </ButtonError>
              ) : ifMoreInput ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Solidify More Than Balance
                  </Text>
                </ButtonError>
              ) : ifLessInput ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Solidify Amount Not Enough
                  </Text>
                </ButtonError>
              ) : setNumberAREC === undefined || solidifiedAmount === undefined ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    Solidify Error
                  </Text>
                </ButtonError>
              ) : errString !== '' ? (
                <ButtonError disabled={true} error={false}>
                  <Text fontSize={font20} fontWeight={500}>
                    {errString}
                  </Text>
                </ButtonError>
              ) : (
                <RowBetween marginTop="10px">
                  <ButtonError
                    disabled={isWaitingWallet}
                    onClick={() => {
                      setARECTxnState({
                        attemptingTxn: false,
                        txnToConfirm,
                        showConfirm: true,
                        errorMessage: undefined,
                        txHash: undefined
                      })
                    }}
                  >
                    <ButtonRow>
                      <div />
                      <Text fontSize={font20} fontWeight={500}>
                        Solidify ART to Claim AREC
                      </Text>
                      {below540 ? (
                        <div />
                      ) : (
                        <MouseoverTooltip info={HelpForSolidify1}>
                          <HelpCircle size="20" color={theme.text5} style={{ marginLeft: '8px' }} />
                        </MouseoverTooltip>
                      )}
                    </ButtonRow>
                  </ButtonError>
                </RowBetween>
              )}
            </BottomGrouping>
          </Wrapper>
        </StyledPageCard>
      </AppBody>
    </>
  )
}
