import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { RowFixed } from '../Row'
import Logo from '../../assets/svg/arkreen_b.png'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

export const Option = styled.div<{ activeText?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.text1};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }

  > svg {
    margin-right: 8px;
  }
`

//export default function Title({ history }: RouteComponentProps) {
export default function Title() {
  const history = useHistory()
  //  const below1080 = useMedia('(max-width: 1080px)')

  //  const theme = useContext(ThemeContext)

  //  {!below1080 && (
  //    <img width={'84px'} style={{ marginLeft: '8px', marginTop: '0px' }} src={Wordmark} alt="logo" />
  //  )}

  //color: ${({ theme }) => theme.text2};

  return (
    <TitleWrapper>
      <RowFixed>
        <UniIcon id="link" onClick={() => history.push('/')}>
          <img width={'130px'} src={Logo} alt="logo" style={{ marginLeft: '20px', marginTop: '10px' }} />
        </UniIcon>
      </RowFixed>
    </TitleWrapper>
  )
}

//{!below1080 && (
//  <TYPE.LargeHeader color={theme.text1} style={{ fontSize: 28, marginLeft: '5px', marginTop: '0px', fontFamily: 'Georgia' }} >
//      Arkreen
//  </TYPE.LargeHeader>
//)}
